import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  FieldStatisticsForFiltering,
  HistogramItemBehavior,
  MinAndMaxForLegend,
  NFertilizationLogfileStatsData,
  ParticularStatisticItem
}                                                               from './n-fertilization-logfile-stats.types';
import {InfoRowViewObject}                                      from '../../views/info-row-view.component';
import {IChartSet}                                              from '../../../../stores/statistic/statistic.store';
import {IBarLineAreaChartData}                                  from '../../../../ap-interface';
import {BehaviorSubject, Subscription}                          from 'rxjs';
import {Create}                                                 from 'ts-tooling';
import {ILegendValue}                                           from '../../../../stores/map/map.legend.store';
import {NFertilizationLegend}                                   from '../../../../ap-map/utils/n-fertilization-legend';
import {
  DottedChart
}                                                               from '../ap-dotted-histogram-chart/ap-dotted-histogram-chart.types';
import {
  ApTranslationService
}                                                               from '../../../../ap-utils/service/ap-translation.service';
import {
  GetRoundNumericService
}                                                               from '../../../../ap-utils/service/get-round-numeric.service';
import {
  DiagramForHistogramStatisticsService,
  DiagramTableData
}                                                               from '../../services/diagram-for-histogram-statistics.service';
import {
  AgriportConstantsService
}                                                               from '../../../../services/common/agriport-constants.service';
import {
  AgriportConstantsEnum
}                                                               from '../../../../ap-interface/enums/ap-agriport-constants.enum';
import {
  NFertilizationStore
}                                                               from '../../../../stores/n-fertilization/n-fertilization.store';
import {ImportStore}                                            from '../../../../stores/import/import.store';

@Component({
  selector: 'ap-n-fertilization-logfile-stats',
  templateUrl: 'n-fertilization-logfile-stats.component.html',
  styleUrls: ['n-fertilization-logfile-stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApNFertilizationLogfileStatsComponent implements OnInit, OnDestroy {
  @Input() public data: NFertilizationLogfileStatsData[];
  @Input() public title: string;
  @Input() public emptyMessage = '';

  public nUptakeBars$: BehaviorSubject<IChartSet<IBarLineAreaChartData>[]> = new BehaviorSubject<IChartSet<IBarLineAreaChartData>[]>([]);
  public nApplicationBars$: BehaviorSubject<IChartSet<IBarLineAreaChartData>[]> = new BehaviorSubject<IChartSet<IBarLineAreaChartData>[]>([]);
  public histogramData$: BehaviorSubject<DottedChart> = new BehaviorSubject<DottedChart>(undefined);
  public infoItems$: BehaviorSubject<InfoRowViewObject[]> = new BehaviorSubject<InfoRowViewObject[]>([]);

  private _subscriptions: Subscription[] = [];

  constructor(private importStore: ImportStore,
              private nFertilizationStore: NFertilizationStore,
              private translationService: ApTranslationService,
              private roundNumericService: GetRoundNumericService,
              private agriportConstantsService: AgriportConstantsService,
              private diagramForHistogramStatisticsService: DiagramForHistogramStatisticsService) {
  }

  public ngOnInit(): void {
    if (this.data.length <= 0) {
      return;
    }
    if (!this.title) {
      this.title = 'Logfile__Statistic__Title';
    }
    this.nFertilizationStore.loadSensorRasterValues(this.data.map(item => item.PlanBookId));
    this.nUptakeBars$.next(this._generateNUptakeData());
    this.nApplicationBars$.next(this._generateNApplicationData());
    this.infoItems$.next(this._generateInfoItems());
    this._subscriptions.push(this.nFertilizationStore.Listen(x => x.sensorRasterValues).subscribe(data => {
      this.histogramData$.next(this._generateHistogramData(data));
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions?.forEach(x => x?.unsubscribe());
  }

  private _generateInfoItems(): InfoRowViewObject[] {
    const logfiles = this.data?.map(x => x.Logfiles).flat() ?? [];
    if (!logfiles || logfiles.length <= 0) {
      return [];
    }
    const infoRowViewObjects: InfoRowViewObject[] = [];
    logfiles.forEach(logfile => {
      if (!logfile?.DataSet) {
        return;
      }
      const fileName = logfile.DataSet.DataSetName;
      const headerValuesObject = JSON.parse(logfile.DataSet.HeaderValuesString);
      const headerValuesObjectKeys = Object.keys(headerValuesObject);
      if (!fileName && headerValuesObjectKeys.length <= 0) {
        return;
      }
      // Introduce Logfile name of file in table
      const fileNameRow = infoRowViewObjects.find(x => x.Head === 'Logfile');
      const actionToDownloadFile = () => this.importStore.downloadCsv(logfile.DataSet.MongoFileId);
      if (fileNameRow) {
        fileNameRow.Data.push({ Text: fileName, Link: actionToDownloadFile});
      } else {
        infoRowViewObjects.push({
          Head: 'Logfile',
          Data: [{ Text: fileName, Link: actionToDownloadFile}]
        });
      }
      if (headerValuesObject) {
        Object.keys(headerValuesObject).forEach(key => {
          const infoRowViewObject = infoRowViewObjects.find(x => x.Head === key);
          if (infoRowViewObject) {
            infoRowViewObject.Data.push(headerValuesObject[key]);
          } else {
            infoRowViewObjects.push({
              Head: key,
              Data: [{ Text: headerValuesObject[key] }]
            });
          }
        });
      }
    });
    return infoRowViewObjects;
  }

  private _generateHistogramData(data: number[][]): DottedChart {
    const chart: DottedChart = {
      ChartTitle: '\u200B',
      AxisY: {
        Title: 'N__ChartTitleY'
      },
      AxisX: {
        Title: 'N__ChartTitleX'
      },
      Data: [],
      Styles: {
        ShowTooltip: true
      }
    };
    const xValues = data[0];
    const yValues = data[1];
    if (!xValues || !yValues) {
      return chart;
    }
    const xTranslate = this.translationService.translate('N__Uptake');
    const yTranslate = this.translationService.translate('N__Application');
    for (let i = 0; i < Math.min(xValues.length, yValues.length); i++) {
      chart.Data.push({
        X: xValues[i],
        Y: yValues[i],
        TooltipText: `${yTranslate}: ${this.roundNumericService.roundAsNumber(yValues[i])}<br>` +
          `${xTranslate}: ${this.roundNumericService.roundAsNumber(xValues[i])}`
      });
    }
    return chart;
  }

  private _generateNUptakeData(): IChartSet<IBarLineAreaChartData>[] {
    const statistics = this._getStatistics(this.data, 0);
    return this._generateHistogram('uptake', statistics, 'N__ChartTitleX');
  }

  private _generateNApplicationData(): IChartSet<IBarLineAreaChartData>[] {
    const statistics = this._getStatistics(this.data, 1);
    return this._generateHistogram('application', statistics, 'N__ChartTitleY');
  }

  private _getStatistics(data: NFertilizationLogfileStatsData[], index: number): ParticularStatisticItem[] {
    if (!data || data.length <= 0) {
      return [];
    }
    return data.filter(x => x?.Area && x?.RasterStatistics[index])
      .filter(x => x.RasterStatistics[index].FieldStatistic && x.RasterStatistics[index].FieldStatistic.length > 0)
      .map(x => ({Area: x.Area, Statistic: x.RasterStatistics[index]}))
      .filter(x => x);
  }

  private _generateHistogram(kind: 'uptake' | 'application',
                             items: ParticularStatisticItem[],
                             axesYTitle: string): IChartSet<IBarLineAreaChartData>[] {
    if (!items || items.length <= 0) {
      return [];
    }
    const totalArea = items.reduce((total, current) => total + Create(current?.Area, 0), 0);
    const totalCells = items.reduce((sum, current) => sum + Create(current?.Statistic?.Cells, 0), 0);
    const minAndMaxForLegend = this._getMinAndMaxForLegend(items);
    const min = minAndMaxForLegend.reduce((lowest, current) => current.Min < lowest ? current.Min : lowest, minAndMaxForLegend[0]?.Min ?? 0);
    const max = minAndMaxForLegend.reduce((highest, current) => current.Max > highest ? current.Max : highest, 0);
    const legendValues = NFertilizationLegend.getDynamicColorLegend(kind, min, max, 5) as ILegendValue[] ?? [];
    const diagramStatistics = items.map(x => ({Histogram: x?.Statistic?.FieldStatistic ?? []}));
    const diagramTableData: DiagramTableData = {
      AxesTitleX: 'Global_ha_Unit',
      AxesTitleY: axesYTitle,
      DiagramTitle: '\u200B',
      RoundAreaNoteToFarmSettings: true,
      TotalArea: totalArea,
      TotalCells: totalCells,
      Categories: legendValues,
      Statistics: diagramStatistics
    };
    return this.diagramForHistogramStatisticsService.generateDiagram(diagramTableData);
  }

  private _getMinAndMaxForLegend(items: ParticularStatisticItem[]): MinAndMaxForLegend[] {
    return items?.map(item => {
      if (!item?.Statistic || item.Statistic.FieldStatistic.length <= 0) {
        return undefined;
      }
      const copiedFieldStatistic: FieldStatisticsForFiltering[] = item.Statistic.FieldStatistic.map(x => {
        return {
          Key: x.Key,
          Value: x.Value,
          ItemBehavior: HistogramItemBehavior.Include // Indicates is item should be included for defining Min and Max values
        };
      });
      let sortedStatistics = copiedFieldStatistic.sort((a, b) => a.Key - b.Key);
      // Filter ordered (from lowest to highest by Key) items from start of an array
      this._filterFieldStatisticsValues(sortedStatistics, item);
      sortedStatistics = sortedStatistics.reverse();
      // Filter reversed items from start of an array (from the end of an array before reverse)
      this._filterFieldStatisticsValues(sortedStatistics, item);
      sortedStatistics = sortedStatistics.filter(x => x.ItemBehavior === HistogramItemBehavior.Include);
      if (sortedStatistics.length <= 0) {
        return undefined;
      }
      const minItem = sortedStatistics.reduce((lowest, current) =>
          current.Key < lowest.Key ? current : lowest,
        sortedStatistics[0]
      );
      const maxItem = sortedStatistics.reduce((highest, current) =>
          current.Key > highest.Key ? current : highest,
        sortedStatistics[0]
      );
      return {
        Min: minItem.Key,
        Max: maxItem.Key
      };
    })?.filter(x => x) ?? [];
  }

  private _filterFieldStatisticsValues(fieldStatistics: FieldStatisticsForFiltering[], item: ParticularStatisticItem): void {
    const minItem = item.Statistic.FieldStatistic.reduce((lowest, current) => current.Key < lowest.Key ? current : lowest, item.Statistic.FieldStatistic[0]);
    const maxItem = item.Statistic.FieldStatistic.reduce((highest, current) => current.Key > highest.Key ? current : highest, item.Statistic.FieldStatistic[0]);
    const multiplier = +(this.agriportConstantsService.GetConstant(AgriportConstantsEnum.HistogrammExcludeLimit) ?? 0.01);
    const multiplierCellValue = multiplier * item.Statistic.Cells;
    const highestValue = Math.max(minItem.Value, maxItem.Value);
    // Value that indicates is item should be HistogramItemBehavior.Include or HistogramItemBehavior.Exclude
    const valueToCompare = multiplierCellValue >= highestValue ? multiplierCellValue : highestValue;
    for (let i = 0; i <= fieldStatistics.length - 1; i++) {
      if (i === 0) {
        if (fieldStatistics[i].Value >= valueToCompare) {
          break;
        }
        fieldStatistics[i].ItemBehavior = HistogramItemBehavior.Exclude;
        continue;
      }
      if (fieldStatistics[i].Value < valueToCompare) {
        fieldStatistics[i].Value += fieldStatistics[i - 1].Value;
      }
      if (fieldStatistics[i].Value >= valueToCompare) {
        fieldStatistics[i - 1].ItemBehavior = HistogramItemBehavior.Include;
        break;
      }
      fieldStatistics[i].ItemBehavior = HistogramItemBehavior.Exclude;
    }
  }
}
