/**
 * holds all Application Urls
 */
export enum ApMenuUrls {
  DASHBOARD = '/dashboard',
  ADMINISTRATION = '/administration',
  ADMINISTRATION_TRANSLATIONS = '/administration/translations',
  ADMINISTRATION_KRIGING = '/administration/kriging',
  ADMINISTRATION_LICENCES = '/administration/licences',
  ADMINISTRATION_ACCESS_PERMISSIONS = '/administration/access-permissions',
  BASE_DATA = '/basedata',
  BASE_DATA_SETTINGS = '/basedata/settings',
  BASE_DATA_USERS = '/basedata/users',
  BASE_DATA_USERS_EDIT = 'basedata/users/edit',
  BASE_DATA_DOCU_RESOURCES = '/basedata/docu-ressources',
  BASE_DATA_CROP_TYPES = '/basedata/croptypes',
  BASE_DATA_CROP_GROUPS = '/basedata/cropgroups',
  BASE_DATA_VARIETIES = '/basedata/varieties',
  BASE_DATA_FERTILIZERS = '/basedata/fertilizers',
  BASE_DATA_PLANTPROTECTION = '/basedata/plant-protections',
  BASE_DATA_FARMS = '/basedata/farms',
  BASE_DATA_SUB_FARMS = '/basedata/subfarms',
  BASE_DATA_WORKTYPES = '/basedata/worktypes',
  FIELD_MANAGEMENT = '/fieldmanagement',
  FIELD_MANAGEMENT_OVERVIEW = '/fieldmanagement/overview',
  FIELD_MANAGEMENT_TAKE_OVER_FIELDS = '/fieldmanagement/takeover-fields',
  FIELD_MANAGEMENT_CONVERSION = '/fieldmanagement/conversion',
  FIELD_MANAGEMENT_CROP_ROTATION = '/fieldmanagement/crop-rotation',
  FIELD_MANAGEMENT_SOIL_SAMPLE_FIELDS = '/fieldmanagement/soil-sample-fields',
  FIELD_MANAGEMENT_SATELLITE = '/fieldmanagement/satellite',
  FIELD_MANAGEMENT_SOIL_SCANNER = '/fieldmanagement/soil-scanner',
  FIELD_MANAGEMENT_ELEVATION = '/fieldmanagement/elevation',
  FIELD_WORK = '/fieldwork',
  FIELD_WORK_LIVE_VIEW = '/fieldwork/live-view',
  FIELD_WORK_OVERVIEW = '/fieldwork/overview',
  FIELD_WORK_PLANNING = 'fieldwork/planning',
  FIELD_WORK_MEASURES = '/fieldwork/measures',
  FIELD_WORK_BOOKING = '/fieldwork/booking',
  FIELD_WORK_ACTION_LOOKUP = '/fieldwork/action-lookup-overview',
  EVALUATION_PERFORMANCE = '/evaluation/performance-evaluation',
  EVALUATION_FIELD_DIARY = '/evaluation/field-diary-evaluation',
  FUNGI_DETECT = '/fungidetect',
  FUNGI_DETECT_OVERVIEW = '/fungidetect/overview',
  IMPORT = '/import',
  IMPORT_OVERVIEW = '/import/overview',
  IMPORT_UPLOAD = '/import/upload',
  IMPORT_FORMATS = '/import/formats',
  LOGIN = '/login',
  LOGIN_PASSWORD_RESET = '/login/request-password-reset',
  LOGIN_PASSWORD_RECOVER = '/login/password-recovery',
  CONTACT = 'login/contact',
  IMPRINT = 'login/imprint',
  AGREEMENT = 'login/agreement',
  LEGAL = 'login/legal',
  INVITATIONS = '/login/invitations',
  N_FERTILIZATION = '/n-fertilization',
  N_FERTILIZATION_OVERVIEW = '/n-fertilization/overview',
  N_FERTILIZATION_PLANNING = '/n-fertilization/planning',
  N_FERTILIZATION_LOG_FILES = '/n-fertilization/logfiles',
  N_FERTILIZATION_BOOKING = '/n-fertilization/booking',
  N_FERTILIZATION_N_NEED = '/n-fertilization/n-need',
  NUTRIENTS = '/nutrients',
  NUTRIENTS_OVERVIEW = '/nutrients/overview',
  NUTRIENTS_PLANNING = '/nutrients/planning',
  NUTRIENTS_LOG_FILES = '/nutrients/logfiles',
  NUTRIENTS_BOOKING = '/nutrients/booking',
  NUTRIENTS_DISTRIBUTION = '/nutrients/nutrients-distribution',
  NUTRIENTS_P_NEED = '/nutrients/p-need',
  NUTRIENTS_SOIL_SAMPLE_PLANNING_OVERVIEW = '/nutrients/soil-sample-planning',
  NUTRIENTS_SOIL_SAMPLE_FIELDS = '/nutrients/soil-sample-fields',
  SETTINGS_USER_PROFILE = '/settings/user-profile',
  PLANT_PROTECTION = '/plant-protection',
  PLANT_PROTECTION_OVERVIEW = '/plant-protection/overview',
  PLANT_PROTECTION_PLANNING = '/plant-protection/planning',
  PLANT_PROTECTION_LOG_FILES = '/plant-protection/logfiles',
  PLANT_PROTECTION_BOOKING = '/plant-protection/booking',
  TASK_MANAGEMENT = '/task-management',
  TASK_MANAGEMENT_OVERVIEW = '/task-management/overview',
}
