import {Injectable}                                                      from '@angular/core';
import {Store}                                                           from '../index';
import {OperationModesLoad, OperationModesLoadSuccess} from 'invoker-transport';
import {IStateStore}                                                     from '../../ap-interface';
import IOperationMode = Data.Common.IOperationMode;
import {
  ApSignalrService
}                                                                        from '../../ap-core/services/ap-signalr.service';

interface IOperationModesStore extends IStateStore<IOperationMode> {
}

export enum OperationModesEnum {
  ApplicationMap = 'AgriConnect__With_AppMap',
  TargetValue = 'OpMode_TargetRateN',
  NFertilization = 'Global__NFertilization',
  RapeFertilization = 'Tasks__Title_AbsN',
  Bonitur = 'Worktype_Category_Bonitur',
  Constant = 'Global__RateConst',
  Pp_Absolut = 'Task_Mgmt_PS_Absolut',
  Pp_Target = 'Task_Mgmt_PS_Target'
}

@Injectable({providedIn: 'root'})
export class OperationModesStore extends Store<IOperationModesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });
    backend.registerObservable(OperationModesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get OperationModes(): IOperationMode[] {
    return this.Listen(x => x.data).getValue();
  }

  public loadOperationModes(): void {
    this.DispatchBackend(new OperationModesLoad([]));
  }
}
