import {Injectable} from '@angular/core';
import {
  CropTypeStore
}                   from '../base-data/crop.types.store';
import {
  CropGroupStore
}                   from '../base-data/crop.groups.store';
import {
  TranslationStore
}                   from '../translation/translation.store';
import {FieldStore} from '../farm/field.store';
import {
  IMainSecondCropChartData,
  IMainSecondCropChartDataValue
}                   from '../../ap-interface/interfaces/charts/ap-main-second-crop-chart-data.interface';
import {Create}     from 'ts-tooling';
import {
  ApGetCropService
}                   from './ap-get-crop.service';
import {
  FieldCropRotationPriority,
  FieldsCropRotationStatsData,
  IFieldsCropRotationPerYear
}                   from '../../statistics/components/charts/fields-crop-rotation-stats/fields-crop-rotation-stats.types';
import ICropGroups = Data.BaseData.ICropGroups;
import IField = Data.FieldManagement.IField;
import ICropTypes = Data.BaseData.ICropTypes;
import IFieldCropRotation = Data.FieldManagement.IFieldCropRotation;

export interface ISecondCropStatistic {
  prio: number;
  name: string;
  data: {
    category: string,
    color: string,
    value: number,
  }[];
}

@Injectable({providedIn: 'root'})
export class ApGetCropStatisticService {
  constructor(private cropTypeStore: CropTypeStore,
              private cropGroupStore: CropGroupStore,
              private translationService: TranslationStore,
              private fieldStore: FieldStore,
              private cropService: ApGetCropService) {
  }

  public gatherFieldCropsChartData(fields: IField[]): IMainSecondCropChartData[] {
    const statisticData: IMainSecondCropChartData[] = [];
    const mainCropData: IMainSecondCropChartDataValue[] = [];
    const secondCropData: IMainSecondCropChartDataValue[] = [];
    for (const field of fields) {
      const geom = this.fieldStore.getCurrentFieldGeom(field);
      const fieldCrop = this.cropService.getFieldCrop(field);
      const mainCropType = this.cropTypeStore.getDefaultCroptype(fieldCrop?.CroptypeId);
      const secondCropType = this.cropTypeStore.getDefaultCroptype(fieldCrop?.CroptypeSecondId);
      this._updateCropDataCollection(mainCropData, mainCropType, geom.AreaHa);
      this._updateCropDataCollection(secondCropData, secondCropType, geom.AreaHa);
    }
    statisticData.push({
      prio: 0,
      name: this.translationService.FindTranslationForSelectedLanguage('SecondCrop'),
      data: secondCropData
    });
    statisticData.push({
      prio: 1,
      name: this.translationService.FindTranslationForSelectedLanguage('MainCrop'),
      data: mainCropData
    });
    return statisticData;
  }

  public gatherFieldCropRotationData(fieldCropRotations: IFieldCropRotation[], startYear: number, endYear: number): FieldsCropRotationStatsData[] {
    const mainCropData: IFieldsCropRotationPerYear = {};
    const secondCropData: IFieldsCropRotationPerYear = {};
    const statsData: FieldsCropRotationStatsData[] = [];
    for (const fieldCropRotation of fieldCropRotations) {
      for (let year = startYear; year <= endYear; year++) {
        const index = year - startYear;
        const currentYield = fieldCropRotation?.Yields[index];
        const mainCrop = this.cropTypeStore.getDefaultCroptype(currentYield?.MainCrop);
        const secondCrop = this.cropTypeStore.getDefaultCroptype(currentYield?.SecondCrop);
        this._updateCropDataObjectWithCropTypeInfo(mainCropData, mainCrop, year, fieldCropRotation.AreaHa);
        this._updateCropDataObjectWithCropTypeInfo(secondCropData, secondCrop, year, fieldCropRotation.AreaHa);
      }
    }
    statsData.push({
      CropPriority: FieldCropRotationPriority.MainCrop,
      CropTypesPerYear: mainCropData
    });
    statsData.push({
      CropPriority: FieldCropRotationPriority.SecondCrop,
      CropTypesPerYear: secondCropData
    });
    return statsData;
  }

  public getForMainCrop(): IMainSecondCropChartData[] {
    const groupArea = this._groupCropAreas();
    const statisticData = [];
    const dataMainCrop = [];
    for (const key of Object.keys(groupArea)) {
      const cropgroup = this.cropGroupStore.getCropGroups().Find((cg: ICropGroups) => cg.Key === key);
      if (cropgroup && cropgroup.Colordefault) {
        let color = cropgroup.Colordefault;
        if (cropgroup.FarmColor) {
          color = cropgroup.FarmColor;
        }
        dataMainCrop.push({
          category: this.translationService.FindTranslationForSelectedLanguage(key),
          color,
          value: groupArea[key],
        } as IMainSecondCropChartDataValue);
      }
    }
    statisticData.push({
      prio: 0,
      name: this.translationService.FindTranslationForSelectedLanguage('MainCrop'),
      data: dataMainCrop
    } as IMainSecondCropChartData);
    return statisticData;
  }

  public getForSecondCrop(): ISecondCropStatistic[] {
    const groupArea = this._groupCropAreas(true);
    const statisticDataSecondCrop = [];
    const dataSecondCrop = [];
    for (const key of Object.keys(groupArea)) {
      const cropgroup = this.cropGroupStore.getCropGroups().Find((cg: ICropGroups) => cg.Key === key);
      if (cropgroup && cropgroup.Colordefault) {
        let color = cropgroup.Colordefault;
        if (cropgroup.FarmColor) {
          color = cropgroup.FarmColor;
        }
        dataSecondCrop.push({
          category: this.translationService.FindTranslationForSelectedLanguage(key),
          color,
          value: groupArea[key],
        });
      }
    }
    statisticDataSecondCrop.push({
      prio: 1,
      name: this.translationService.FindTranslationForSelectedLanguage('SecondCrop'),
      data: dataSecondCrop
    });
    return statisticDataSecondCrop;
  }

  private _updateCropDataCollection(cropData: IMainSecondCropChartDataValue[], cropType: ICropTypes, areaHa: number): void {
    const cropTypeName = cropType?.Description ?? this.translationService.FindTranslationForSelectedLanguage('Global__CropGroup_Nothing');
    const cropTypeColor = cropType?.Color ?? '#ffffff';
    const cropTypeArea = Create(areaHa, 0);
    const cropItemIndex = cropData.findIndex(x => x.category === cropTypeName);
    if (cropItemIndex >= 0) {
      cropData[cropItemIndex].value += cropTypeArea;
    } else {
      cropData.push({
        category: cropTypeName,
        color: cropTypeColor,
        value: cropTypeArea,
        explode: false
      });
    }
  }

  private _updateCropDataObjectWithCropTypeInfo(cropData: IFieldsCropRotationPerYear | undefined,
                                                cropType: ICropTypes, year: number, areaHa: number): void {
    const cropTypeName = cropType?.Description ?? this.translationService.FindTranslationForSelectedLanguage('Global__CropGroup_Nothing');
    const cropTypeColor = cropType?.Color ?? '#ffffff';
    const cropTypeArea = Create(areaHa, 0);
    if (!cropData[year]) {
      cropData[year] = [];
    }
    const itemIndex = cropData[year].findIndex(x => x.CropTypeName === cropTypeName);
    if (itemIndex >= 0) {
      cropData[year][itemIndex].AreaHa += areaHa;
    } else {
      cropData[year].push({
        CropTypeName: cropTypeName,
        Color: cropTypeColor,
        AreaHa: cropTypeArea
      });
    }
  }

  private _groupCropAreas(second = false): { [key: string]: number } {
    const groupArea = {};
    const fields = this.fieldStore.Fields;
    const yearCrops = this.fieldStore.YearFieldCrops;
    for (const f of fields) {
      const geom = this.fieldStore.getCurrentFieldGeom(f);
      const fieldCrop = yearCrops[f.Id.toString()];
      if (!fieldCrop) {
        if (!groupArea['Global__CropGroup_Nothing']) {
          groupArea['Global__CropGroup_Nothing'] = 0;
        }
        groupArea['Global__CropGroup_Nothing'] += Create(geom?.AreaHa, 0);
        continue;
      }
      const groupName = this.cropTypeStore.getDefaultCroptype(second ? fieldCrop.CroptypeSecondId : fieldCrop.CroptypeId);
      const name = groupName && groupName.Groupname ? groupName.Groupname : 'Global__CropGroup_Nothing';
      if (!groupArea.hasOwnProperty(name)) {
        groupArea[name] = 0;
      }
      groupArea[name] += Create(geom?.AreaHa, 0);
    }
    return groupArea;
  }
}
