import {AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription}            from 'rxjs';
import {map, tap}                                                                from 'rxjs/operators';
import {ValidatorFn}                                                             from '@angular/forms';
import {
  ApDynComponentComponent
}                                                                                from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApDynGridColumnConfigBase
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerConfigBase
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {FieldStore}                                                              from '../../stores/farm/field.store';
import {
  FieldNutrientDistributionStore
}                                                                                from '../../stores/nutrients/field-nutrient-distributions.store';
import {
  MapViewStore
}                                                                                from '../../stores/layout/mapview.store';
import {
  ApDynformsConfigFieldset
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  CropRotationStore
}                                                                                from '../../stores/farm/crop.rotation.store';
import {RbStore}                                                                 from '../../stores/nutrients/rb.store';
import {
  ApNutrientWizardStep
}                                                                                from '../../ap-interface/enums/ap-nutrient-wizard-setup.enums';
import {
  ApDynformsValidator
}                                                                                from '../../ap-dynforms/ap-dynforms-validator';
import {MapViewMode}                                                             from '../../ap-interface';
import {
  NutrientPlanningEntryService
}                                                                                from './ap-nutrient-planning-configs/nutrient-planning-entry.service';
import {
  NutrientPlanningEntrySelectionStepService
}                                                                                from './ap-nutrient-planning-configs/nutrient-planning-entry-selection-step.service';
import {
  NutrientPlanningEntryDetailsStepService
}                                                                                from './ap-nutrient-planning-configs/nutrient-planning-entry-details-step.service';
import {
  NutrientPlanningEntryOverviewStepService
}                                                                                from './ap-nutrient-planning-configs/nutrient-planning-entry-overview-step.service';
import {FormStore}                                                               from '../../stores/layout/form.store';
import {UnitsStore}                                                              from '../../stores/common/units.store';
import {
  CampaignYearStore
}                                                                                from '../../stores/login/campaignyear.store';
import {
  ElementsStore
}                                                                                from '../../stores/common/elements.store';
import {
  OperationModesStore
}                                                                                from '../../stores/common/operation-modes.store';
import {
  PlanningEntryComponentData
}                                                                                from './ap-nutrient-planning-configs/nutrient-planning-types';
import {
  BaseFertilizationCommonService
}                                                                                from '../../nutrient-management/service/base-fertilization-common.service';
import IField = Data.FieldManagement.IField;
import IFieldGeom = Data.FieldManagement.IFieldGeom;

@Component({
  selector: 'ap-nutrient-planning',
  template: `
    <ap-dyncomponent [caption]="caption$ | async"
                     [columns]="columns$ | async"
                     [fieldSets]="fieldSets$ | async"
                     [items]="items$ | async"
                     [loading$]="loading$"
                     [headerIcon]="'ap-icon-base-fertilization'"
                     [canSearch]="true"
                     [pager]="pager"
                     [canCreate]="false"
                     [formValidators]="formValidators">
      <div class="ap-form-actions" dynforms.action>
        <div apResponsive
             [sizeMd]="'25-100'"
             [style.text-align]="'left'">
          <button kendoButton
                  [id]="'button_prev'"
                  [type]="'button'"
                  [class]="'k-button k-primary button-important'"
                  (click)="onBackDetails.emit()">
            <ap-responsive-text [key]="'Global_Back_With_Arrow'"></ap-responsive-text>
          </button>
        </div>
        <div apResponsive
             [sizeMd]="'50-100'"
             [style.text-align]="'center'">
          <ap-breadcrumbs-steper [columns]="breadCrumbsColumns"
                                 [selected]="1">
          </ap-breadcrumbs-steper>
        </div>
        <div apResponsive
             [sizeMd]="'25-100'"
             [style.text-align]="'right'">
          <button kendoButton
                  [id]="'button_calculate'"
                  [disabled]="!dynComponent.valid"
                  [type]="'button'"
                  [class]="'k-button k-primary button-important'"
                  (click)="onNextDetails.emit()">
            <ap-responsive-text [key]="'Calculate'"></ap-responsive-text>
          </button>
        </div>
      </div>
    </ap-dyncomponent>`,
  providers: [
    NutrientPlanningEntryService,
    NutrientPlanningEntrySelectionStepService,
    NutrientPlanningEntryDetailsStepService,
    NutrientPlanningEntryOverviewStepService
  ]
})
export class ApNutrientPlanningComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) public dynComponent: ApDynComponentComponent;
  public loading$ = of(true);
  public columns$: Observable<ApDynGridColumnConfigBase[]> = of([]);
  public items$: Observable<any[]> = of([]);
  public fieldSets$ = new BehaviorSubject<ApDynformsConfigFieldset[]>([]);
  public caption$ = new BehaviorSubject<string>('Global__Create_Planning');
  public pager: ApDynGridPagerConfigBase;
  public data: PlanningEntryComponentData;
  public breadCrumbsColumns = ['Global__Selection', 'Global__Details', 'Admin_Pages__Overview'];
  public onBackDetails = new EventEmitter();
  public onNextDetails = new EventEmitter();
  public formValidators: ApDynformsValidator<ValidatorFn>[] = [];

  private _subscriptions: Array<Subscription> = [];

  constructor(private rbStore: RbStore,
              private formStore: FormStore,
              private unitStore: UnitsStore,
              private fieldStore: FieldStore,
              private mapViewStore: MapViewStore,
              private elementStore: ElementsStore,
              private campaignYearStore: CampaignYearStore,
              private cropRotationStore: CropRotationStore,
              private operationModesStore: OperationModesStore,
              private nutrientDistributionStore: FieldNutrientDistributionStore,
              private fieldNutrientDistributionStore: FieldNutrientDistributionStore,
              private baseFertilizationCommonService: BaseFertilizationCommonService,
              private planningEntryService: NutrientPlanningEntryService,
              private planningEntrySelectionStepService: NutrientPlanningEntrySelectionStepService,
              private planningEntryDetailsStepService: NutrientPlanningEntryDetailsStepService,
              private planningEntryOverviewStepService: NutrientPlanningEntryOverviewStepService) {
  }

  public ngOnInit(): void {
    this.planningEntryService.setComponentData(this.data);
    if (!this.planningEntryService.isDataObjectValid()) {
      this._closeForm();
      return;
    }
    this.baseFertilizationCommonService.setMainComponentSelectionAvailability(false);
    this.loading$ = combineLatest([
      this.nutrientDistributionStore.Loading$,
      this.rbStore.Listen(s => s.loading),
      this.unitStore.Listen(x => x.loading),
      this.fieldStore.Listen(s => s.loading),
      this.elementStore.Listen(x => x.loading),
      this.campaignYearStore.Listen(s => s.loading),
      this.operationModesStore.Listen(s => s.loading),
      this.cropRotationStore.Listen(s => s.historyLoading),
      this.cropRotationStore.Listen(s => s.cropRotationAttributesLoading),
    ]).pipe(
      map((loadings) => loadings.some(l => l)),
      tap(result => {
        if (!result && this.planningEntryService.isNewMode === undefined) {
          this.planningEntryService.initData();
          this.planningEntrySelectionStepService.initData();
          this.planningEntryDetailsStepService.initData();
          this.planningEntryOverviewStepService.initData();
        }
      })
    );
    if (this.mapViewStore.getMapViewMode() !== MapViewMode.NORMAL) {
      this.mapViewStore.showMapView();
    }
    this.planningEntryService.clearMapLayerAndLegend();
    this.caption$.next(this.planningEntryService.isNewMode ? 'Global__Create_Planning' : 'Global__Planning');
  }

  public ngAfterContentInit(): void {
    this.planningEntrySelectionStepService.setSubscriptions(this.dynComponent);
    this.planningEntryDetailsStepService.setSubscriptions(this.dynComponent);
    this.planningEntryOverviewStepService.setSubscriptions(this.dynComponent);
    this._setSubscriptions();
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(d => d.unsubscribe());

  }

  // #region "private Methods"

  private _setSubscriptions(): void {
    this._subscriptions.push(this.planningEntryService.closeForm.subscribe(() => {
      this._closeForm();
    }));
    this._subscriptions.push(this.fieldStore.Listen(s => s.data)
      .subscribe(fields => setTimeout(() => this._listenFieldStore(fields), 0))
    );
    this._subscriptions.push(this.onBackDetails.subscribe((): void => {
      this.planningEntryService.activeWizardStep.next(ApNutrientWizardStep.Selection);
    }));
    this._subscriptions.push(this.onNextDetails.subscribe((): void => {
      this.planningEntryService.activeWizardStep.next(ApNutrientWizardStep.Overview);
    }));
    this._subscriptions.push(this.planningEntryService.activeWizardStep.subscribe(step => {
      switch (step) {
        case ApNutrientWizardStep.Selection:
          this._onSelectionStep();
          break;
        case ApNutrientWizardStep.Details:
          this._onDetailsStep();
          break;
        case ApNutrientWizardStep.Overview:
          this._onOverviewStep();
          break;
      }
    }));
    this._subscriptions.push(this.planningEntryDetailsStepService.fieldSetsUpdate$.subscribe(fields => {
      this.fieldSets$.next(fields);
    }));
  }

  private _onSelectionStep(): void {
    this.fieldSets$.next([]);
    this.pager = this.planningEntrySelectionStepService.getPager(this.breadCrumbsColumns);
    this.columns$ = this.planningEntrySelectionStepService.getColumns();
    this.items$ = this.planningEntrySelectionStepService.getItems();
  }

  private _onDetailsStep(): void {
    this.columns$ = of([]);
    this.formValidators = this.planningEntryDetailsStepService.getFormValidators();
    this.fieldSets$.next(this.planningEntryDetailsStepService.getDetailFieldsetControls());
    this.planningEntryDetailsStepService.afterFieldsetInit();
  }

  private _onOverviewStep(): void {
    this.fieldSets$.next([]);
    this.pager = this.planningEntryOverviewStepService.getPager();
    this.columns$ = this.planningEntryOverviewStepService.getColumns();
    this.items$ = this.planningEntryOverviewStepService.getItems();
  }

  private _listenFieldStore(fields: IField[]): void {
    if (fields == null) {
      return;
    }
    if (fields && fields.length > 0) {
      const currentFieldGeomIds: string[] = this._readFieldGeometriesOfCurrentLoadedFields(fields);
      this.fieldNutrientDistributionStore.loadFieldNutrientDistributions(currentFieldGeomIds);
    }
    const tmp: any[] = [];
    for (const field of fields) {
      if (!field.FieldGeoms || !Array.isArray(field.FieldGeoms) || field.FieldGeoms.length < 1) {
        continue;
      }
      for (const geom of field.FieldGeoms.filter((g: IFieldGeom) => g?.Id != null)) {
        tmp.push(geom.Id);
      }
    }
    this.rbStore.loadRb(tmp);
  }

  private _readFieldGeometriesOfCurrentLoadedFields(fields: IField[]): string[] {
    const tmp: any[] = [];
    for (const field of fields) {
      if (!field.FieldGeoms || typeof field.FieldGeoms !== typeof [] || field.FieldGeoms.length < 1) {
        // skip Fields without Geometry
        continue;
      }
      for (const geom of field.FieldGeoms) {
        if (tmp.Contains(geom.Id)) {
          continue;
        }
        tmp.push(geom.Id);
      }
    }
    return tmp;
  }

  private _closeForm(): void {
    // Moved the following lines of code from ngDestroy to closeForm
    // Reason: We don't know when angular calls ngDestroy (with a delay and async)
    // The following lines of code might be executed while another component is already
    // initialized completely and destroys its map selection/legend etc.
    // (e.g. BF/plans tried to refresh the map after this form closed but the map was cleared again
    // because these methods were called afterward from ngDestroy)
    // That's why we call the cleanUp methods right before closing the form.
    this.baseFertilizationCommonService.setMainComponentSelectionAvailability(true);
    this.planningEntrySelectionStepService.clearData();
    this.planningEntryDetailsStepService.clearData();
    this.planningEntryOverviewStepService.clearData();
    this.planningEntryService.clearData();
    this.dynComponent?.dynGrid?.gridPaging?.setSelectedKeys([]);
    setTimeout(() => {
      this.formStore.closeForm();
    }, 1);
  }

  //#endregion "private Methods"
}
