import {Injectable}                from '@angular/core';
import {Store}                     from '../index';
import {FieldStore}                from '../farm/field.store';
import {ApGetCropService}          from '../services/ap-get-crop.service';
import {NuptakeCalculationService} from '../../services/common/nuptake-calculation.service';
import {NUptakeFactorsStore} from '../common/nuptake-factors.store';
import {combineLatest}       from 'rxjs';
import {MapStore}                  from '../map/map.store';
import {NdiStore}                  from './ndi.store';
import INdiStatistic = Data.NDI.INdiStatistic;
import IField = Data.FieldManagement.IField;
import INUptakeFactors = Data.Common.INUptakeFactors;
import {MapFactoryStyler}            from '../../map-factory/style';
import {NdiService} from '../../field-management/services/ndi.service';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

/**
 * This store aggregates data from other stores and services to build
 * the dynamic legend for the current farm. Whenever farm, fields, n-index changes
 * the legend values are therefor recalculated
 */
@Injectable({providedIn: 'root'})
export class NUptakeStore extends Store<void> {
  // n-uptake statistics to provide dynamic legend colors
  private _nUptakeMinMax = {Min: 0, Max: 0};

  constructor(public backend: ApSignalrService,
              private fieldStore: FieldStore,
              private cropService: ApGetCropService,
              private ndiStore: NdiStore,
              private mapStore: MapStore,
              private ndiService: NdiService,
              private nUptakeCalculationService: NuptakeCalculationService,
              private nUptakeFactorsStore: NUptakeFactorsStore) {
    super(backend);
  }

  public listen(): void {
    combineLatest([
      this.ndiStore.Listen(s => s.data),
      this.fieldStore.Listen(s => s.data),
      this.nUptakeFactorsStore.Listen(s => s.data)
    ]).subscribe(([nIndexData, fields, nUptakeFactors]) => {
      this.updateNUptakeMinMaxLegend(nIndexData, fields, nUptakeFactors);
    });

    this.mapStore.Layers.OnLayerChange.subscribe(value => {
      if (this.mapStore.Layers.NUptakeLayer?.Visibility) {
        this.updateNUptakeLegend();
      }
    });
  }

  /**
   * Updates the n-uptake min-max range and updates the n-uptake legend if it is visible
   * If not visible it will be updated by switching between n-index and n-uptake columns
   * @private
   */
  private updateNUptakeMinMaxLegend(nIndexData: INdiStatistic[], fields: IField[], nUptakeFactors: INUptakeFactors[]): void {
    if (!nIndexData || nIndexData?.length === 0 ||
      !fields || fields?.length === 0 ||
      !nUptakeFactors || nUptakeFactors?.length === 0 ) {
      this._nUptakeMinMax = {
        Min: 0,
        Max: 10,
      };
      this.mapStore.Legends.onNUptakeChanged.emit(this._nUptakeMinMax);
      return;
    }

    let totalMin = Number.POSITIVE_INFINITY;
    let totalMax = 0;
    for (const field of fields) {
      let fieldCrop = this.cropService.getFieldCrop(field);
      const mainCrop = this.cropService.getCropType(fieldCrop, true);
      fieldCrop = mainCrop ? fieldCrop : null;
      const nUptakeFactor = this.nUptakeFactorsStore.getNUptakeFactorByFieldCropAndCropType(fieldCrop, mainCrop);
      const ndiStat = nIndexData?.Find(n => n.FieldGeomId === field.DefaultGeom?.Id);
      if (!this.ndiService.isValidNIndexStatistic(ndiStat)) {
        continue;
      }

      const min = this.nUptakeCalculationService.calculateNUptakeValue(ndiStat?.Min, nUptakeFactor);
      const max = this.nUptakeCalculationService.calculateNUptakeValue(ndiStat?.Max, nUptakeFactor);
      if (min != null && min < totalMin) {
        totalMin = min;
      }
      if (max != null && max > totalMax) {
        totalMax = max;
      }
    }

    if (!isFinite(totalMin)) {
      totalMin = 0;
    }
    if (!isFinite(totalMax) || totalMax === 0) {
      totalMax = 10;
    }
    this._nUptakeMinMax = {
      Min: totalMin,
      Max: totalMax,
    };
    this.updateNUptakeLegend();
  }

  /**
   * Updates the n-uptake legend based on the calculated min/max values
   * @private
   */
  private updateNUptakeLegend(): void {
    this.mapStore.Legends.onNUptakeChanged.emit(this._nUptakeMinMax);
  }
}
