import {IAction, IApInvokerArguments} from '../../interfaces';
import INFertilizationDetail = Data.NFertilization.INFertilizationDetail;
import INFertilizationNeedValue = Data.NFertilization.INFertilizationNeedValue;
import INFertilizationSummary = Data.TaskManagement.INFertilizationSummary;
import ISensorFieldData = Data.Sensor.ISensorFieldData;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;

export enum NFertilizationActions {
  N_FERTILIZATION_LOAD = '[n_fertilization] load',
  N_FERTILIZATION_LOAD_SUCCESS = '[n_fertilization] load success',
  N_FERTILIZATION_LOAD_FAIL = '[n_fertilization] load fail',
  N_FERTILIZATION_DETAILS_LOAD = '[n_fertilization] details load',
  N_FERTILIZATION_DETAILS_LOAD_SUCCESS = '[n_fertilization] details load success',
  N_FERTILIZATION_NEED_VALUE_GET = '[n_fertilization] need value get',
  N_FERTILIZATION_NEED_VALUE_GET_SUCCESS = '[n_fertilization] need value get success',
  N_FERTILIZATION_NEED_VALUE_SET = '[n_fertilization] need value set',
  N_FERTILIZATION_BOOKINGS_LOAD = '[n_fertilization plan books] load',
  N_FERTILIZATION_BOOKINGS_LOAD_SUCCESS = '[n_fertilization plan books] load success',
  N_FERTILIZATION_BOOKINGS_SAVE = '[n_fertilization plan books] save',
  N_FERTILIZATION_BOOKINGS_UPDATE = '[n_fertilization plan books] update',
  N_FERTILIZATION_SUMMARY_DELETE = '[n_fertilization summary] delete',
  N_FERTILIZATION_PLANBOOKS_DELETE = '[n_fertilization plan books] delete',
  N_FERTILIZATION_LOGFILES_LOAD = '[n_fertilization logfile] load',
  N_FERTILIZATION_LOGFILES_LOAD_SUCCESS = '[n_fertilization logfile] load success',
  N_FERTILIZATION_LOGFILES_SAVE = '[n_fertilization logfile] save',
  N_FERTILIZATION_LOGFILES_SAVE_SUCCESS = '[n_fertilization logfile] success',
  N_FERTILIZATION_LOGFILES_UPDATE = '[n_fertilization logfile] update',
  N_FERTILIZATION_LOGFILES_REMAP = '[n_fertilization logfile] remap',
  N_FERTILIZATION_LOGFILES_REMAP_COMPLETE = '[n_fertilization logfile] remap complete',
  N_FERTILIZATION_BOOK_PLAN_BOOK = '[n_fertilization plan book] book',
  N_FERTILIZATION_BOOK_PLAN_BOOK_SUCCESS = '[n_fertilization plan book] book success',
  N_FERTILIZATION_UN_BOOK_PLAN_BOOK = '[n_fertilization plan book] unbook',
  N_PLANNING_START = '[n_planning] start',
  N_PLANNING_FAIL = '[n_planning] fail',
  N_PLANNING_SUCCESS = '[n_planning] success',
  N_FERTILIZATION_CROP_EC_SN_LOAD = '[n_fertilization crop ec sn] load',
  N_FERTILIZATION_CROP_EC_SN_LOAD_SUCCESS = '[n_fertilization crop ec sn] load success',
  N_FERTILIZATION_CROP_EC_N_LOAD = '[n_fertilization crop ec n] load',
  N_FERTILIZATION_CROP_EC_N_LOAD_SUCCESS = '[n_fertilization crop ec n] load success',
  N_PLANNING_RASTER_CREATE_JOB = '[n_planning_raster] create job',
  N_PLANNING_RASTER_CREATE_JOB_FAIL = '[n_planning_raster] create job fail',
  N_PLANNING_RASTER_CREATE_JOB_SUCCESS = '[n_planning_raster] create job success',
  N_PLANNING_RASTER_STATISTIC_REFRESH = '[n_planning_raster] raster statistic refresh',
  N_PLANNING_RASTER_STATISTIC_FAIL = '[n_planning_raster] raster statistic fail',
  N_SENSOR_FIELD_DATA_LOAD = '[n_sensor] field data load',
  N_SENSOR_FIELD_DATA_LOAD_SUCCESS = '[n_sensor] field data load success',
  N_FERTILIZATION_BOOKINGS_UPDATE_SUCCESS = '[n_fertilization plan books] update success',
  N_FERTILIZATION_LOGFILES_UPDATE_SUCCESS = '[n_fertilization logfile] update success',
  N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD = '[n_fertilization n_restrictions] report download',
  N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD_SUCCESS = '[n_fertilization n_restrictions] report download success',
  N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD_FAIL = '[n_fertilization n_restrictions] report download fail',
  N_SENSOR_RASTER_VALUES_LOAD = '[n_sensor] raster values load',
  N_SENSOR_RASTER_VALUES_LOAD_SUCCESS = '[n_sensor] raster values load success',
  N_SENSOR_RASTER_VALUES_LOAD_FAIL = '[n_sensor] raster values load fail',
}

/**
 * load data from backend
 */
export class NFertilizationLoad implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load data from backend success
 */
export class NFertilizationLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class NFertilizationDetailsLoad implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_DETAILS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationDetailsLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_DETAILS_LOAD_SUCCESS;

  constructor(public payload: INFertilizationDetail[]) {
  }
}

export class NFertilizationNeedValueGet implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_NEED_VALUE_GET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationNeedValueGetSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_NEED_VALUE_GET_SUCCESS;

  constructor(public payload: INFertilizationNeedValue[]) {
  }
}

export class NFertilizationNeedValueSet implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_NEED_VALUE_SET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookingsLoad implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOKINGS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookingsLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOKINGS_LOAD_SUCCESS;

  constructor(public payload: INFertilizationSummary[]) {
  }
}

export class NFertilizationBookingsSave implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOKINGS_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookingsUpdate implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOKINGS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookingsUpdateSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOKINGS_UPDATE_SUCCESS;

  constructor(public payload: INFertilizationSummary[]) {
  }
}

export class NFertilizationSummaryDelete implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_SUMMARY_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationPlanBooksDelete implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_PLANBOOKS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesLoad implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesSave implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesSaveSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_SAVE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesUpdate implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesUpdateSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_UPDATE_SUCCESS;

  constructor(public payload: IDatasetFieldMap[]) {
  }
}

export class NFertilizationLogfilesRemap implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_REMAP;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationLogfilesRemapComplete implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_LOGFILES_REMAP_COMPLETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookPlanBook implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOK_PLAN_BOOK;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationBookPlanBookSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_BOOK_PLAN_BOOK_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationUnBookPlanBook implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_UN_BOOK_PLAN_BOOK;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningStart implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_START;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningFail implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_FAIL;

  constructor(public payload: string) {
  }
}

export class NPlanningSuccess implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NPlanningCropEcSn implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_CROP_EC_SN_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningCropEcSnSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_CROP_EC_SN_LOAD_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NPlanningCropEcN implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_CROP_EC_N_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningCropEcNSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_CROP_EC_N_LOAD_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NPlanningRasterCreateJob implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_RASTER_CREATE_JOB;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningRasterCreateJobFail implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_RASTER_CREATE_JOB_FAIL;

  constructor(public payload: string) {
  }
}

export class NPlanningRasterCreateJobSuccess implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_RASTER_CREATE_JOB_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NPlanningRasterStatisticRefresh implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_RASTER_STATISTIC_REFRESH;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NPlanningRasterStatisticFail implements IAction {
  readonly type = NFertilizationActions.N_PLANNING_RASTER_STATISTIC_FAIL;

  constructor(public payload: string) {
  }
}


export class NSensorFieldDataLoad implements IAction {
  readonly type = NFertilizationActions.N_SENSOR_FIELD_DATA_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NSensorFieldDataLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_SENSOR_FIELD_DATA_LOAD_SUCCESS;

  constructor(public payload: ISensorFieldData[]) {
  }
}

export class NFertilizationNRestrictionReportDownload implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationNRestrictionReportDownloadSuccess implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NFertilizationNRestrictionReportDownloadFail implements IAction {
  readonly type = NFertilizationActions.N_FERTILIZATION_N_RESTRICTIONS_REPORT_DOWNLOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NSensorRasterValuesLoad implements IAction {
  readonly type = NFertilizationActions.N_SENSOR_RASTER_VALUES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NSensorRasterValuesLoadSuccess implements IAction {
  readonly type = NFertilizationActions.N_SENSOR_RASTER_VALUES_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NSensorRasterValuesLoadFail implements IAction {
  readonly type = NFertilizationActions.N_SENSOR_RASTER_VALUES_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
