import OlMap                           from 'ol/Map';
import Feature                         from 'ol/Feature';
import {ApMapViews}                    from './layers/ap-map.views';
import {ApMapControlStream}            from './layers/ap-map-control.stream';
import {BehaviorSubject, Subscription} from 'rxjs';
import {IApMachinePopup}               from './interfaces/ap-machine-popup.interface';
import {IApDynamicPointDataPopup}      from './interfaces/ap-dynamic-point-data-popup.interface';
import {IApLengend}                    from './interfaces/ap-sensor-point-legend';
import {IApMapColorLegend}             from '../ap-interface';
import {retry}                         from 'async';
import {GetRoundNumericPipe}           from '../ap-utils';
import 'jsts/org/locationtech/jts/monkey.js';
import {CropTypeStore}                 from '../stores/base-data/crop.types.store';
import {DomSanitizer}                  from '@angular/platform-browser';
import {UserSettingsStore}             from '../stores/settings/usersettings.store';
import {SettingsStore}                 from '../stores/base-data/settings.store';
import {FieldStore}                    from '../stores/farm/field.store';
import {ActionStore}                   from '../stores/docu/action.store';
import {RouterStore}                   from '../stores/router/router.store';
import {TranslationStore}              from '../stores/translation/translation.store';
import {MapStore}                      from '../stores/map/map.store';
import View                            from 'ol/View';
import {MAP_PROJECTION}                from './layers/ap-map.settings';
import {fromLonLat}                    from 'ol/proj';
import {ApMapContextMenuComponent}     from '../map/components/ap-map-context-menu.component';
import Geometry                        from 'ol/geom/Geometry';
import {environment}                   from '../../environments/environment';
import {containsExtent}                from 'ol/extent';
import BaseLayer                       from 'ol/layer/Base';
import {LayerFactory}                  from '../map-factory/layer.factory';
import {MapFactoryLayer}               from './layers/map-factory-layer';
import {ApPolygonEditLayer}            from './layers/ap-polygon-edit.layer';
import {ApBingLayer, BingImagery}      from './layers/ap-bing.layer';
import {APP_CONFIGURATION}             from '../ap-core/config';
import {filter}                        from 'rxjs/operators';
import {StringFactory}                 from 'ts-tooling';
import {CropGroupStore}                from '../stores/base-data/crop.groups.store';
import {GeometryChecker}               from './geometry.checker';
import VectorSource                    from 'ol/source/Vector';
import IField = Data.FieldManagement.IField;
import ILocationModel = Data.DocuContext.Location.ILocationModel;
import ISoilSampleField = Data.Nutrients.ISoilSampleField;
import VectorTileLayer                 from 'ol/layer/VectorTile';
import {GeoJSON}                       from 'ol/format';
import {FullScreen}                    from 'ol/control';
import {ApMapTooltipComponent}         from '../map/components/ap-map-tooltip.component';
import {MapBrowserEvent}               from 'ol';
import {Pixel}                         from 'ol/pixel';
import {LayerNames}                    from './layers/layer-names';
import {EventEmitter}                  from '@angular/core';

if (!environment.Production) {
  if (!window['mapFunctions']) {
    window['mapFunctions'] = {};
  }
  setTimeout(() => {
    window['mapFunctions'].goTo = ApMapInstance.GoTo;
    window['mapFunctions'].getMap = () => ApMapInstance.Map;
    window['mapFunctions'].getPolygonEditLayer = () => ApMapInstance.PolygonEditLayer;
    window['mapFunctions'].getFieldDistributionLayer = () => ApMapInstance.FieldDistributionLayer;
  }, 1);
}

/**
 * instance and Methods of the Openlayers Map
 */
export class ApMapInstance {
  /**
   * the Openlayers Map Instance
   */
  static mapRef: OlMap;
  static isDebugModeEnabled = false;
  static mustUpdateView = false;
  static domSD: DomSanitizer;
  static contextMenu: ApMapContextMenuComponent;
  static tooltip: ApMapTooltipComponent;
  static userSettingsStore: UserSettingsStore;
  static mapStore: MapStore;
  static mapHTMLElement: HTMLElement;
  static fieldStore: FieldStore;
  static actionStore: ActionStore;
  static routerStore: RouterStore;
  static mapScrollDown: Subscription;
  static mapScrollUp: Subscription;
  static sensorPointLegend: IApLengend;
  static machinePopup: IApMachinePopup;
  static sensorPointPopup: IApDynamicPointDataPopup;
  static colorLegend: IApMapColorLegend;

  static tooltipTimeout: ReturnType<typeof setTimeout>;
  static settingsStore: SettingsStore;
  static croptypeStore: CropTypeStore;
  static cropGroupsStore: CropGroupStore;
  static translationService: TranslationStore;
  static roundNumericPipe: GetRoundNumericPipe;
  static FeaturesAtMousePosition: { [key: string]: BehaviorSubject<Feature<Geometry>[]> } = {};
  static MouseCoordinate = new BehaviorSubject<number[]>([0, 0]);
  static onFieldClicked = new EventEmitter<IField>();
  static hasAgriconRole: boolean;
  private static SuspendZoomToSelection = false;

  static get PolygonEditLayer(): ApPolygonEditLayer {
    return this.mapStore.Layers.PolygonEditLayer;
  }

  static get FieldDistributionLayer(): MapFactoryLayer {
    return this.mapStore.Layers.DistributionLayer;
  }

  static get Map(): OlMap {
    return ApMapInstance.mapRef;
  }

  static clear(): void {
    if (ApMapInstance.mapRef) {
      ApMapInstance.mapRef.setTarget(null);
      ApMapInstance.mapRef = null;
    }
    ApMapInstance.mapStore.isMap = false;
  }

  /**
   * create a new Map Instance for a HTML Element or a Id String of an HTML Element
   */
  static register(ref: string | HTMLElement): void {
    if (ApMapInstance.mapRef) {
      setTimeout(() => ApMapInstance._setMap(ref), 201);
      return;
    }
    ApMapInstance._initMap(ref);
  }

  static IsFeatureInViewFull(feature: Feature): boolean {
    const view = ApMapInstance.mapRef.getView();
    if (!view) {
      return false;
    }
    const viewExtent = view.calculateExtent();
    const featureExtent = feature.getGeometry().getExtent();
    return containsExtent(viewExtent, featureExtent);
  }

  static GoTo(longitude: number, latitude: number, zoom: number): void {
    ApMapInstance.mapRef.setView(new View({
      center: fromLonLat([longitude, latitude]),
      zoom,
      projection: MAP_PROJECTION
    }));
  }

  /**
   * Registers the context menu component.
   */
  static registerContextMenu(ref: ApMapContextMenuComponent): void {
    this.contextMenu = ref;
  }

  /**
   * Registers the context menu component.
   */
  static registerTooltip(ref: ApMapTooltipComponent): void {
    this.tooltip = ref;
  }

  /**
   * Registers the ap-map html element.
   */
  static registerMapHTMLElement(ref: HTMLElement): void {
    this.mapHTMLElement = ref;
  }

  /**
   * Registers the machine popup component for later use in layers.
   */
  static registerMachinePopup(ref: IApMachinePopup): void {
    this.machinePopup = ref;
  }

  /**
   * Registers the sensorpoint popup component for later use in layers.
   */
  static registerSensorPointPopup(ref: IApDynamicPointDataPopup): void {
    this.sensorPointPopup = ref;
  }

  /**
   * Registers the sensorpoint legend component to show on map on classify the layer
   */
  static registerSensorLegend(ref: IApLengend): void {
    this.sensorPointLegend = ref;
  }

  /**
   * Registers the color legend component to show on map
   */
  static registerColorLegend(ref: IApMapColorLegend): void {
    this.colorLegend = ref;
  }

  /**
   * update the sizes of the map and triggers a render for optimal display
   */
  static updateView(): void {
    if (ApMapInstance.mapRef) {
      ApMapInstance._updateView();
    } else {
      ApMapInstance.mustUpdateView = true;
      retry({
        times: 5,
        interval: 100,
      }, () => {
        if (ApMapInstance.mapRef) {
          ApMapInstance._updateView();
        }
      }, (err) => {
        if (err) {
          console.warn('update map view fails after 5 retries');
        }
        ApMapInstance.mustUpdateView = false;
      });
    }
  }

  /**
   * set a new Zoom Level of the View
   */
  static changeZoom(level: number): void {
    ApMapInstance.mapRef.getView().setZoom(level);
    this.mapStore.SetLastView({
      center: ApMapInstance.mapRef.getView().getCenter() as [number, number],
      zoom: ApMapInstance.mapRef.getView().getZoom()
    });
  }

  /**
   * set a new Center of the View
   */
  static changeCenter(point: [number, number]): void {
    ApMapInstance.mapRef.getView().setCenter(point);
    this.mapStore.SetLastView({
      center: ApMapInstance.mapRef.getView().getCenter() as [number, number],
      zoom: ApMapInstance.mapRef.getView().getZoom()
    });
  }

  /**
   * refresh the Layers in the Map after change in Store
   */
  static refreshLayers(layers: { name: string, layer: BaseLayer }[]): void {
    for (const l of layers) {
      ApMapInstance.changeLayer(l);
    }
  }

  /**
   * add a new Layer to the Map
   */
  static changeLayer(layer: { name: string, layer: BaseLayer }): void {
    if (!layer) {
      return;
    }

    const found = ApMapInstance.getLayer(layer.name);
    if (found) {
      ApMapInstance.mapRef.removeLayer(found);
    }
    ApMapInstance.mapRef.addLayer(layer.layer);
  }

  static getLayer(name: string): BaseLayer {
    return ApMapInstance.mapRef.getLayers().getArray()
      .Find(_ => _?.get('name') === name);
  }

  static updateUrl(layer: { name: string, address: string, url: string }): boolean {
    if (!layer) {
      return false;
    }

    const found = ApMapInstance.getLayer(layer.name);
    if (found) {
      ApMapInstance.mapRef.removeLayer(found);
    }
    const l = LayerFactory.createMapFactoryLayer(layer.name, layer.address, layer.url, null, ApMapInstance.mapStore);
    ApMapInstance.mapRef.addLayer(l.layer);
    return true;
  }

  /**
   * select the given Fields in Map
   */
  static selectField(fields: IField[]): void {
    if (!ApMapInstance.mapStore.Layers.FieldsLayer) {
      return;
    }
    ApMapInstance.mapStore.Layers.FieldsLayer.clearSelection();
    const withGeom = fields.FindAll(f => !!this.fieldStore.getCurrentFieldGeom(f));
    if (withGeom.length > 0) {
      for (const f of withGeom) {
        ApMapInstance.mapStore.Layers.FieldsLayer.selectField(this.fieldStore.getCurrentFieldGeom(f)?.Id.toString());
      }
      // In case user selected a field by clicking in the map the zoom-to-selection should be skipped
      if (!ApMapInstance.SuspendZoomToSelection) {
        ApMapInstance.mapStore.Layers.FieldsLayer.fitSelection();
      }
    }
  }

  /**
   * select the given locations in Map
   */
  static selectLocation(locations: ILocationModel[]): void {
    if (!ApMapInstance.mapStore.Layers.LocationsLayer) {
      return;
    }
    ApMapInstance.mapStore.Layers.LocationsLayer.clearSelection();
    for (const f of locations) {
      ApMapInstance.mapStore.Layers.LocationsLayer.selectLocation(f.Id.toString());
    }
    if (locations.length !== 0) {
      ApMapInstance.mapStore.Layers.LocationsLayer.fitSelection(150);
    }
  }

  /**
   * init a new Map in an HTML Element
   */
  private static _initMap(ref: string | HTMLElement): void {
    const bingHybrid = new ApBingLayer(this.mapStore, BingImagery.HYBRID);
    const bingStreet = new ApBingLayer(this.mapStore, BingImagery.GRAYSCALE_ROAD);
    const bingSatellite = new ApBingLayer(this.mapStore, BingImagery.SATELLITE);
    bingStreet.Visibility = false;
    bingSatellite.Visibility = false;

    ApMapInstance.mapRef = new OlMap({
      target: ref,
      layers: [
        bingHybrid.layer,
        bingStreet.layer,
        bingSatellite.layer,
        ApMapInstance.mapStore.Layers.WMTSLayer.layer,
        ApMapInstance.mapStore.Layers.XYZLayer.layer,
        ApMapInstance.mapStore.Layers.SensorPoints.layer,
        ApMapInstance.mapStore.Layers.NRaster.layer,
        ApMapInstance.mapStore.Layers.PpRaster.layer,
        ApMapInstance.mapStore.Layers.FieldsLayer.layer,
        ApMapInstance.mapStore.Layers.FieldsCropLayer.layer,
        ApMapInstance.mapStore.Layers.FieldDescriptionLayer.layer,
        ApMapInstance.mapStore.Layers.SampleFieldLayer.layer,
        ApMapInstance.mapStore.Layers.SampleFieldDescriptionLayer.layer,
        ApMapInstance.mapStore.Layers.LocationsLayer.layer,
        ApMapInstance.mapStore.Layers.LocationsLayer.pingLayer,
        ApMapInstance.mapStore.Layers.UserLocationLayer.layer,
        ApMapInstance.mapStore.Layers.FungiDetectLayer.layer,
        ApMapInstance.mapStore.Layers.ContactLayer.layer,
        ApMapInstance.mapStore.Layers.PolygonEditLayer.layer,
        ApMapInstance.mapStore.Layers.PolygonEditViewLayer.layer,
        ApMapInstance.mapStore.Layers.TaskManagementLayer.layer,
        ApMapInstance.mapStore.Layers.TaskManagementNutrientLayer.layer,
        ApMapInstance.mapStore.Layers.NeedLayer.layer,
        ApMapInstance.mapStore.Layers.SoilGroupLayer.layer,
        ApMapInstance.mapStore.Layers.SoilSampleLayer.layer,
        ApMapInstance.mapStore.Layers.DistributionLayer.layer,
        ApMapInstance.mapStore.Layers.SoilSampleDistributionLayer.layer,
        ApMapInstance.mapStore.Layers.NutrientPlanningLayer.layer,
        ApMapInstance.mapStore.Layers.TrackLayer.layer,
        ApMapInstance.mapStore.Layers.TrackLayer.featureLayer,
        ApMapInstance.mapStore.Layers.NdiLayer.layer,
        ApMapInstance.mapStore.Layers.NApplicationMapLayer.layer,
        ApMapInstance.mapStore.Layers.PpApplicationMapLayer.layer,
        ApMapInstance.mapStore.Layers.NUptakeLayer.layer
      ],
      view: ApMapViews.olView,
      controls: [new FullScreen({
        target: 'ap-map-fullscreen-container',
        className: 'ap-map-fullscreen',
        label: '',
        labelActive: ''
      })]
    });

    // the Order are Important and defined at https://confluence.agricon.de/display/APV49/Kartenelement
    ApMapInstance.mapStore.Layers.AllLayers$.next([
      ApMapInstance.mapStore.Layers.FieldsLayer,
      ApMapInstance.mapStore.Layers.FieldDescriptionLayer,
      ApMapInstance.mapStore.Layers.FieldsCropLayer,
      ApMapInstance.mapStore.Layers.SampleFieldLayer,
      ApMapInstance.mapStore.Layers.SoilGroupLayer,
      ApMapInstance.mapStore.Layers.SoilSampleLayer,
      ApMapInstance.mapStore.Layers.NdiLayer,
      ApMapInstance.mapStore.Layers.NUptakeLayer,
      ApMapInstance.mapStore.Layers.DistributionLayer,
      ApMapInstance.mapStore.Layers.SoilSampleDistributionLayer,
      ApMapInstance.mapStore.Layers.NeedLayer,
      ApMapInstance.mapStore.Layers.NutrientPlanningLayer,
      ApMapInstance.mapStore.Layers.SensorPoints,
      ApMapInstance.mapStore.Layers.NRaster,
      ApMapInstance.mapStore.Layers.PpRaster,
      ApMapInstance.mapStore.Layers.LocationsLayer,
      ApMapInstance.mapStore.Layers.UserLocationLayer,
      ApMapInstance.mapStore.Layers.TrackLayer,
      ApMapInstance.mapStore.Layers.TaskManagementLayer,
      ApMapInstance.mapStore.Layers.TaskManagementNutrientLayer,
      ApMapInstance.mapStore.Layers.FungiDetectLayer,
      ApMapInstance.mapStore.Layers.NApplicationMapLayer,
      ApMapInstance.mapStore.Layers.PpApplicationMapLayer,
    ]);

    ApMapInstance._registerControls();
    ApMapInstance.updateView();

    ApMapInstance.mapStore.Layers.BackgroundMap$.subscribe(v => {
      switch (v) {
        case BingImagery.GRAYSCALE_ROAD:
          bingHybrid.Visibility = false;
          bingSatellite.Visibility = false;
          bingStreet.Visibility = true;
          break;
        case BingImagery.SATELLITE:
          bingHybrid.Visibility = false;
          bingSatellite.Visibility = true;
          bingStreet.Visibility = false;
          break;
        default:
          bingHybrid.Visibility = true;
          bingSatellite.Visibility = false;
          bingStreet.Visibility = false;
      }
      this.mapRef.getLayers();
      setTimeout(() => this.setLastView(), 0);
    });

    setTimeout(() => {
      ApMapInstance.mapStore.Layers.initBaseMaps();
      ApMapInstance.mapStore.mapInitialized.emit();
      ApMapInstance.mapStore.isMap = true;
    }, 1);

    ApMapInstance.mapStore.Layers.DistributionUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.DistributionLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.SoilSampleDistributionUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.SoilSampleDistributionLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.NutrientPlanningUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NutrientPlanningLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.NRasterUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NRaster.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.PpRasterUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.PpRaster.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.SensorPointsUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.SensorPoints.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.SoilSampleUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.SoilSampleLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.SoilGroupUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.SoilGroupLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.NeedUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NeedLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.NdiUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NdiLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.NUptakeUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NUptakeLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });

    ApMapInstance.mapStore.Layers.TrackUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.TrackLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
        ApMapInstance.mapStore.Layers.TrackLayer.loadFeatures(APP_CONFIGURATION.MapFactory.Address,
          url.Replace('map_slice', 'map_slice_marker').Replace('pro_slice', 'pro_slice_marker'),
          _ => _.type === 'Point').then();
      });

    ApMapInstance.mapStore.Layers.NPlanningUrl$
      .pipe(filter(url => !StringFactory.IsNullOrEmpty(url)))
      .subscribe(url => {
        ApMapInstance.mapStore.Layers.NApplicationMapLayer.update(`${APP_CONFIGURATION.MapFactory.Address}${url}`);
      });
  }

  /**
   * set the Map into an new HTML Element
   */
  private static _setMap(ref: string | HTMLElement): void {
    ApMapInstance.mapRef.setTarget(ref);
    this.setLastView();
    ApMapInstance._registerControls();
    ApMapInstance.updateView();
  }

  /**
   * Tries to set the last view of the map
   * @private
   */
  private static setLastView(): void {
    if (this.mapStore.LastView) {
      ApMapInstance.mapRef?.getView().setCenter(this.mapStore.LastView.center);
      ApMapInstance.mapRef?.getView().setZoom(this.mapStore.LastView.zoom);
    }
  }

  /**
   * register Map Controls after init or set the Map into HTML DOM
   */
  private static _registerControls(): void {
    ApMapInstance._initLayerSubscription();
    // register to the Map Events
    ApMapInstance.mapRef.on('moveend', ApMapInstance._saveLastView);
    ApMapInstance.mapScrollDown = ApMapControlStream.listenScrollDown$.subscribe(() => ApMapInstance._saveLastView);
    ApMapInstance.mapScrollUp = ApMapControlStream.listenScrollUp$.subscribe(() => ApMapInstance._saveLastView);
    ApMapInstance.mapRef.on('singleclick', ApMapInstance._singleClick);
    ApMapInstance.mapRef.on('contextmenu', ApMapInstance._contextMenu);
    ApMapInstance.mapRef.on('pointermove', ApMapInstance._pointerMove);
    ApMapInstance.mapRef.on('singleclick', (e) => {
      ApMapInstance.MouseCoordinate.next(ApMapInstance.mapRef.getCoordinateFromPixel(e.pixel));
      const layers = ApMapInstance.mapRef.getLayers().getArray();
      e.map.forEachLayerAtPixel(e.pixel, l => {
        for (const layer of layers) {
          const name = layer.get('name');
          if (!name || name !== l.get('name')) {
            continue;
          }

          if (!ApMapInstance.FeaturesAtMousePosition[name]) {
            ApMapInstance.FeaturesAtMousePosition[name] = new BehaviorSubject<Feature<Geometry>[]>([]);
          } else {
            const layerSource = l.getSource() as VectorSource;
            if (!layerSource || typeof layerSource.getFeatures !== 'function') {
              return;
            }
            ApMapInstance.FeaturesAtMousePosition[name].next(GeometryChecker.IsPointInPolygon(layerSource.getFeatures(), e.map.getCoordinateFromPixel(e.pixel)));
          }
        }
      });
    });
  }

  private static _initLayerSubscription(): void {
    const layers = ApMapInstance.mapRef.getLayers().getArray();
    for (const layer of layers) {
      const name = layer.get('name');
      if (!ApMapInstance.FeaturesAtMousePosition[name]) {
        ApMapInstance.FeaturesAtMousePosition[name] = new BehaviorSubject<Feature<Geometry>[]>([]);
      }
    }
  }

  private static _saveLastView(): void {
    if (ApMapInstance.isDebugModeEnabled) {
      try {
        for (const layer of ApMapInstance.mapRef.getLayers().getArray()) {
          if (!(layer instanceof VectorTileLayer) || !layer?.getVisible()) {
            continue;
          }
          // While debugMode is enabled the map is rendered with full qualified feature instances
          // Those full features allows us to convert them to GeoJson
          // Without debugMode the maps consists of so called RenderFeatures which is
          // a lightweight feature implementation to increase performance
          const currentMapFeatures = layer.getSource()?.getFeaturesInExtent(
            ApMapInstance.mapRef.getView().calculateExtent());
          const geoJsonFeatureCollection = new GeoJSON().writeFeaturesObject(
            currentMapFeatures as Feature[],
            {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            });
          console.log(`Layer: '${layer?.getProperties()?.name}':`);
          console.log('http://geojson.io/#data=data:application/json,' + encodeURIComponent(JSON.stringify(geoJsonFeatureCollection)));
        }
      } catch (e) {
        console.warn(`Error while generating map geoJson link in debugMode: ${e?.message} : ${e.stack}`);
      }
    }
    ApMapInstance.mapStore.SetLastView({
      center: ApMapInstance.mapRef.getView().getCenter() as [number, number],
      zoom: ApMapInstance.mapRef.getView().getZoom()
    });
  }

  /**
   * update Openlayers Map Sizes and trigger a render
   */
  private static _updateView(): void {
    try {
      if (ApMapInstance.mapRef) {
        ApMapInstance.mapRef.renderSync();
        ApMapInstance.mapRef.updateSize();
      }
    }catch (ex)
    {
      // in rare cases we got the following error:
      // >Cannot read properties of null (reading 'usedTiles')<
      // It could be reproduced by slowing down the browser (performance tab)
      // and clicking really fast between tabs (map/stat) and other modules.
      // The callstack pointed to this part of our code.
      // It seems a timing issue between disposing the map (when leaving the tab)
      // and clicking in the map or in the grid to focus a field
      // => this is not critical, and therefore we change it to 'Warning' in
      // order not to block our client with the client-error overlay
      console.warn('Updating view failed because map is already disposed');
    }
  }

  private static _singleClick(event: MapBrowserEvent): void {
    ApMapInstance.contextMenu.hide();

    // Do no allow field-selection if fields layer is not available/visible and if at least one of the edit layers is active.
    if (!ApMapInstance.mapStore?.Layers?.FieldsLayer?.layer || !ApMapInstance.mapStore?.Layers?.FieldsLayer?.Visibility ||
      (ApMapInstance.mapStore?.Layers?.PolygonEditViewLayer?.Visibility &&
        ApMapInstance.mapStore?.Layers?.PolygonEditViewLayer?.Features?.length > 0) ||
      (ApMapInstance.mapStore?.Layers?.PolygonEditLayer?.Visibility &&
        ApMapInstance.mapStore?.Layers?.PolygonEditLayer?.Features?.length > 0)) {
      return;
    }

    // Trigger selection of fields clicked by the user
    try {
      ApMapInstance.SuspendZoomToSelection = true;
      const clickedFields = GeometryChecker.IsPointInPolygon(
        ApMapInstance.mapStore?.Layers?.FieldsLayer?.layer?.getSource()?.getFeatures(),
        ApMapInstance.mapRef?.getCoordinateFromPixel(event?.pixel));
      for (const clickedField of clickedFields) {
        ApMapInstance.onFieldClicked?.emit(ApMapInstance.fieldStore?.getFieldByFieldGeomId(clickedField?.getId()));
      }
    }catch (error) {
      console.warn(`Error while singleClick event (select fields from map): ${error}`);
    } finally {
      setTimeout(() => {
        // we need a delay here because some components debounce their selectionChange event
        ApMapInstance.SuspendZoomToSelection = false;
        }, 250);
    }
  }

  static hideTooltip(): void {
    clearTimeout(ApMapInstance.tooltipTimeout);
    ApMapInstance.tooltip?.show(undefined);
  }

  public static displayTooltipAt(featurePosition: Pixel, tooltipStyle?: string): void {
    // if the timeout/debounce for showing the tooltip started
    // already but the mouse moved again => clear the timeout and
    // avoid showing the tooltip
    if (ApMapInstance.tooltipTimeout) {
      clearTimeout(ApMapInstance.tooltipTimeout);
      ApMapInstance.tooltipTimeout = undefined;
      ApMapInstance.tooltip?.show(undefined);
    }

    ApMapInstance.tooltipTimeout = setTimeout(() => {
      try {
        const tooltipContent: string[] = [];
        const processedLayers: string[] = [];
        ApMapInstance.mapRef.forEachFeatureAtPixel(featurePosition, (feature, featureLayer) => {
            const mapFactoryLayer = ApMapInstance.mapStore.Layers.AllLayers.FirstOrDefault(l => (l as MapFactoryLayer)?.layer === featureLayer && (l as MapFactoryLayer)?.name !== LayerNames.USER_LOCATION) as MapFactoryLayer;
            if (!mapFactoryLayer) {
              ApMapInstance.tooltip.show('');
              return;
            }

            const featureProperties = feature?.getProperties();
            // we need to check if this exact layer has been processed already.
            // in rare cases the 'forEachFeatureAtPixel method returns duplicates of features
            if (processedLayers.Any(l => l === featureProperties?.layer)) {
              ApMapInstance.tooltip.show('');
              return;
            }
            processedLayers.push(featureProperties?.layer);

            if (ApMapInstance.isDebugModeEnabled) {
              tooltipContent.push(featureProperties?.data);
            } else if (typeof mapFactoryLayer.generateTooltip === 'function'){
              const tooltipForFeature = mapFactoryLayer.generateTooltip(featureProperties);
              if (tooltipForFeature?.length > 0) {
                tooltipContent.push(tooltipForFeature);
              }
            }
            if (ApMapInstance.isDebugModeEnabled) {
              console.log(`%cFeatureAtPixel: ${featureProperties?.data ?? ''}`, 'color: lightblue; background: rgba(79, 79, 79, 0.5);');
            }
          }
          , {
            hitTolerance: 5,
            layerFilter: (l) => {
              return l?.getVisible() === true;
            }
          });
        clearTimeout(ApMapInstance.tooltipTimeout);
        ApMapInstance.tooltip.show(tooltipContent.Join('\n'), tooltipStyle);
      } catch (e) {
        console.warn(`Error while creating map feature tooltip: ${e?.message} : ${e.stack}`);
      }
    }, 500);
  }

  /**
   * Handle mouseMove event to track if the mouse stands still and
   * a tooltip can be shown in the map
   * @param event
   * @private
   */
  private static _pointerMove(event: MapBrowserEvent): void {
    if (!event || event.dragging) {
      return;
    }

    ApMapInstance.displayTooltipAt(event.pixel);
  }

  private static _contextMenu(event): void {
    event.preventDefault();
    const features: { layer: string, id: string | number }[] = [];
    const coord = ApMapInstance.mapRef.getCoordinateFromPixel(event.pixel);
    for (const layer of ApMapInstance.mapStore.Layers.AllLayers$.getValue()) {
      if (typeof layer['forFeaturesAtCoordinate'] !== 'function') {
        continue;
      }
      if (!layer.Visibility) {
        continue;
      }

      layer['forFeaturesAtCoordinate'](coord, (f, l) => {
        features.push({layer: l.get('name'), id: f.getId()});
      });
    }
    ApMapInstance.contextMenu.setContextMenu(features);
    ApMapInstance.contextMenu.show({
      left: event.pixel[0] + (ApMapInstance.mapHTMLElement.offsetParent as HTMLElement)?.offsetLeft,
      top: event.pixel[1] + (ApMapInstance.mapHTMLElement.offsetParent as HTMLElement)?.offsetTop
    });
  }

  static selectSampleField(sampleFields: ISoilSampleField[]): void {
    if (!ApMapInstance.mapStore.Layers.SampleFieldLayer) {
      return;
    }
    ApMapInstance.mapStore.Layers.SampleFieldLayer.clearSelection();
    const withGeoms = sampleFields.FindAll(sf => !!sf.Geom);
    if (!withGeoms.Any()) {
      return;
    }
    for (const withGeom of withGeoms) {
      ApMapInstance.mapStore.Layers.SampleFieldLayer.selectField(withGeom.Id);
    }
    ApMapInstance.mapStore.Layers.SampleFieldLayer.fitSelection();
  }
}
