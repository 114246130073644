import {Injectable}          from '@angular/core';
import {CampaignYearRange}   from '../../types/common-types';
import {ApDateService}       from '../../ap-core/services/ap-date-service';
import {DateRange, DateTime} from 'ts-tooling';

@Injectable({providedIn: 'root'})
export class CampaignYearService {

  constructor(private dateService: ApDateService) {
  }

  public isCampaignYearIntersects(from: Date, to: Date, campaignYear: CampaignYearRange): boolean {
    const zoneName = DateTime.LocalZoneName();
    const campaignYearStart = DateTime.FromISOString(campaignYear.StartYear.toISOString(), zoneName);
    const campaignYearEnd = DateTime.FromISOString(campaignYear.EndYear.toISOString(), zoneName);
    const isoFrom = DateTime.FromISOString(from.toISOString(), zoneName);
    const isoTo = DateTime.FromISOString(to.toISOString(), zoneName);
    const range = new DateRange(isoFrom, isoTo);
    const yearRange = new DateRange(campaignYearStart, campaignYearEnd);
    return yearRange.Overlaps(range);
  }

  public getCampaignYearRange(year: number, endYear?: number): CampaignYearRange {
    const startYear = year - 1;
    const yearEnd = endYear ? endYear : year;
    return this.createCampaignYearRange(startYear, yearEnd);
  }

  public getCampaignYearByDate(date: Date): CampaignYearRange {
    if (!date) {
      const utcDate = this.dateService.getUtc();
      const farmDate = this.dateService.toFarmDateFromUtcGetJsDate(utcDate);
      return this.getCampaignYearByDate(farmDate);
    }
    const month = date.getMonth();
    const year = date.getFullYear();
    let yearStart: number;
    let yearEnd: number;
    if (month >= this._getCampaignYearStart(year).getMonth()) {
      yearStart = year;
      yearEnd = year + 1;
    } else {
      yearStart = year - 1;
      yearEnd = year;
    }
    return this.createCampaignYearRange(yearStart, yearEnd);
  }

  private createCampaignYearRange(yearStart: number, yearEnd: number): CampaignYearRange {
    const startCampaignYearDate = this._getCampaignYearStart(yearStart);
    const endCampaignYearDate = this._getCampaignYearEnd(yearEnd);
    return {
      CampaignYear: yearEnd,
      StartYear: startCampaignYearDate,
      EndYear: endCampaignYearDate
    };
  }

  private _getCampaignYearStart(year: number): Date {
    // month August
    return new Date(year, 7, 1, 0, 0, 0);
  }

  private _getCampaignYearEnd(year: number): Date {
    // month July
    return new Date(year, 6, 31, 23, 59, 59, 999);
  }
}
