import {Injectable}    from '@angular/core';
import {LanguageStore} from '../../stores/translation/language.store';
import {SettingsStore} from '../../stores/base-data/settings.store';
import ILanguage = Data.Language.ILanguage;

@Injectable({providedIn: 'root'})
export class GetRoundNumericService {
  private _lang: ILanguage = null;

  constructor(private languageStore: LanguageStore,
              private settingsStore: SettingsStore) {
    this.languageStore.SelectedLanguage$.subscribe(d => this._lang = d);
  }

  public roundValue(value: any): string {
   return this.round(value);
  }

  /**
   * round the Value to the Number of Digits
   */
  public round(value: any, digits?: number, notReplace: boolean = false): string {
    if (digits === undefined) {
      digits = this.settingsStore.FirstSetting ? this.settingsStore.FirstSetting.DigitsAfterDecimalPoint : 2;
    }
    if (digits < 0) {
      digits = value.toString().Split('.')[1]?.length || 0;
    }

    let optionalUnit = '';
    // if value contains space => it might be number with unit. Round number and attach unit back.
    if (value?.toString().includes(' ')) {
      const valueAsString = value.toString();
      optionalUnit = valueAsString.substring(valueAsString.indexOf(' '), valueAsString.length);
    }

    if (typeof value === typeof 0) {
      return this.fixValue(value, digits, notReplace) + optionalUnit;
    }

    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value;
    }
    return this.fixValue(parsedValue, digits, notReplace) + optionalUnit;
  }

  /**
   * round the Value to the Number of Digits
   * not secure the number of Digits are reached
   *
   * For Example:
   *
   * when have 4 digits 1.1000 get as 1.1
   */
  public roundAsNumber(value: any, digits?: number): number {
    const res = parseFloat(this.round(value, digits, true));
    if (isNaN(res)) {
      return 0.0;
    }
    return res;
  }

  /*
   * converts the number to a string with usage of settings decimal separator
   */
  public stringify(value: number, digits?: number): string {
    return this.fixValue(value, digits);
  }

  private fixValue(value: number, digits?: number, notReplace = false): string {
    if (digits === undefined) {
      digits = this.settingsStore.FirstSetting ? this.settingsStore.FirstSetting.DigitsAfterDecimalPoint : 2;
    }
    if (!this._lang) {
      console.warn('try to round value without Language Info! maybe invalid decimal separator used');
      return value.toFixed(digits);
    }
    let tmp = value.toFixed(digits);
    if (notReplace) {
      return tmp;
    }
    tmp = tmp.Replace('.', this._lang.DecimalSeperator);
    return tmp;
  }
}
