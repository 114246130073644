import * as _                        from 'lodash';
import {LayerGroupNames, LayerNames} from './layer-names';
import {ApBaseVectorLayer}           from './ap-base-vector.layer';
import OlFeature                     from 'ol/Feature';
import Feature                       from 'ol/Feature';
import {ApMapControlStream}          from './ap-map-control.stream';
import {ApMapInstance}               from '../ap-map.instance';
import {ApMenuUrls}                  from '../../ap-interface/enums/ap-menu-urls.enum';
import {ApEditStyles}                from './ap-edit-styles';
import {Keys}                        from '../interactions';
import MapBrowserEvent               from 'ol/MapBrowserEvent';
import {MapStore}                    from '../../stores/map/map.store';
import {defaultStyle}                from './ap-fields.style';

/**
 * the Fields Layer represent the Fields of a Farm
 */
export class ApPolygonEditLayer extends ApBaseVectorLayer {
  /**
   * the Projection of the Fields Layer Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';

  /**
   * create a new Layer of Fields from a List of Fields
   */
  constructor(mapStore: MapStore, data?: OlFeature[], name = LayerNames.EDIT) {
    super(name, LayerGroupNames.BASIC, mapStore);
    if (data) {
      this.replaceSource(data);
    }
    ApMapControlStream.listenClick$.subscribe(event => this._selectFeatures(event));
  }

  selectPolygon(id: string): void {
    this.selectFeature(id, ApEditStyles.selectedStyle);
  }

  /**
   * replace the Data Source of the Layer
   */
  replaceSource(data: OlFeature[]): void {
    const features = data.map(function (feat): Feature {
      feat.set(Keys.LAYER_NAME, LayerNames.EDIT);
      return feat;
    });
    this.readFeatures(features, defaultStyle);
  }

  /**
   * replace the Data Source of the Layer
   */
  replaceSourceDisabled(data: OlFeature[]): void {
    const features = data.map(function (feat): Feature {
      feat.set(Keys.LAYER_NAME, LayerNames.FIELDS);
      return feat;
    });
    this.readFeatures(features, ApEditStyles.selectedStyle);
  }

  clear(): void {
    super.clear();
  }

  /**
   * select Fields in Map and Grid
   */
  private _selectFeatures(event: MapBrowserEvent): void {
    if (ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW) {
      return;
    }
    const myFeatures = event.map.getFeaturesAtPixel(event.pixel);
    const layerFeature = _.filter(myFeatures, f => f.layerName === LayerNames.EDIT)[0];
    if (layerFeature === undefined) {
      return;
    }
    this.selectFeature(layerFeature.id_, ApEditStyles.selectedStyle);
  }

  /**
   * Set layer visibility
   */
  public set Visibility(value: boolean) {
    this.mapStore.Layers.FieldDescriptionLayer.Visibility = value || this.mapStore.Layers.FieldsLayer.Visibility;
    super.Visibility = value;
  }

  /**
   * Gets layer visibility
   */
  public get Visibility(): boolean {
    return super.Visibility;
  }
}
