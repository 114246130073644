import Style                                        from 'ol/style/Style';
import Stroke                                       from 'ol/style/Stroke';
import Fill                                         from 'ol/style/Fill';
import Text                                         from 'ol/style/Text';
import CircleStyle                                  from 'ol/style/Circle';
import {ILabelDefinition, IStyle, IStyleDefinition} from './interfaces';
import {NumberFactory}                              from 'ts-tooling';
import * as ax                                      from 'axios';
import {MapFactoryAuthenticator}                    from './authentication';
import {DEVICE_PIXEL_RATIO}                         from 'ol/has';
import {ApStyleData}                                from './ap-style-data';
import {Subject}                                    from 'rxjs';
import {MapStore}                                   from '../stores/map/map.store';
import {ILegend, ILegendValue}                      from '../stores/map/map.legend.store';

export class MapFactoryStyler {
  static get Instance(): MapFactoryStyler {
    return mapFactoryStyler();
  }

  public customStyle: Subject<ApStyleData> = new Subject<ApStyleData>();
  styles: IStyle[] = [];

  constructor(private address: string,
              private mapStore: MapStore) {
  }

  async reloadServerStyles(): Promise<void> {
    try {
      const res = await ax.default({
        method: 'GET',
        url: `${this.address}/styles`,
        transformResponse: this.transformResponse,
        headers: {
          Authorization: await MapFactoryAuthenticator.getHash(),
        }
      });
      if (res.status !== 200) {
        throw Error('error on load styles from ' + this.address + '/styles');
      }
      this.styles = res.data;
    } catch (e) {
      console.warn(`error on request map factory styles: ${e.message}`);
    }
  }

  styleFunction(feat, reso): any {
    const props = feat.getProperties();
    const data = JSON.parse(props.data);
    const selectedStyle = this.styles.Find(i => i.name === data.style);
    if (!selectedStyle) {
      return;
    }
    this.setLegendSubjects(selectedStyle, data);
    const style = selectedStyle.type === 'basic' ?
        this.getBasicStyle(selectedStyle, data, reso) :
        this.getDynamicStyle(selectedStyle, data, reso);

    this.setText(style, selectedStyle.label, data, reso);

    const styleData: ApStyleData = {style, map: data.type};
    if (this.customStyle) {
      this.customStyle.next(styleData);
    }
    return styleData.style;
  }

  private transformResponse(data: string): any[] {
    try {
      const str = data.toString();
      const tmp = str.Trim('"');
      return JSON.parse(atob(tmp));
    } catch (e) {
      console.warn(`error on transform map factory style response ${e.message}`);
      return [];
    }
  }

  private setText(style: Style, label: ILabelDefinition, props: any, reso: number): void {
    const labelText = style.getText();
    if (!labelText) {
      return;
    }
    let text = '';
    const hasMinResolution = label.minResolution > -1;
    const hasMaxResolution = label.maxResolution > -1;
    const isInResolution = ((hasMinResolution && reso >= label.minResolution) || !hasMinResolution) &&
      ((hasMaxResolution && reso <= label.maxResolution) || !hasMaxResolution);
    if (isInResolution) {
      text = (props[label.field] || '').toString();
      if (label.decimalPlaces >= 0) {
        text = this.roundLabel(text, label.decimalPlaces);
      }
    }
    labelText.setText(text);
  }

  private roundLabel(value: string, decimalPlaces: number): string {
    const v = parseFloat(value);
    if (isNaN(v)) {
      return value;
    }
    return v.toFixed(decimalPlaces);
  }

  private createLabel(label: ILabelDefinition, props: any, reso: number): Text {
    const opts = {
      font: label.font || undefined,
      textAlign: label.textAlign || undefined, // 'start' 'left' 'right' 'center' 'end'
      textBaseline: label.textBaseline || undefined, // 'alphabetic' 'bottom' 'hanging' 'ideographic' 'middle' 'top'
      placement: label.placement || undefined, // 'line' or 'point'
      scale: label.scale || 1,
      overflow: label.overflow === true,
      rotateWithView: label.rotateWithView === true,
      text: '',
      offsetX: label.offsetX,
      offsetY: label.offsetY,
      rotation: label.rotation,
      fill: new Fill(label.style.fill),
      stroke: new Stroke(label.style.stroke),
    };
    return new Text(opts);
  }

  private createStyle(style: IStyleDefinition, label: ILabelDefinition, props: any, reso: number): Style {
    switch (style.geometryType) {
      case 'Point':
        return new Style({
          image: new CircleStyle({
            radius: style.radius,
            stroke: style.stroke.width > 0 ? new Stroke({
              color: style.stroke.color,
              width: style.stroke.width,
            }) : new Stroke({
              color: this.getFill(style).getColor(),
              width: 1,
            }),
            fill: new Fill({
              color: style.fill.color,
            })
          }),
          text: label && label.field ? this.createLabel(label, props, reso) : undefined,
        });
      case 'Line':
        return new Style({
          stroke: style.stroke.width > 0 ? new Stroke({
            color: style.stroke.color,
            width: style.stroke.width,
          }) : null,
          text: label && label.field ? this.createLabel(label, props, reso) : undefined,
        });
      case 'Polygon':
        return new Style({
          stroke: style.stroke.width > 0 ? new Stroke({
            color: style.stroke.color,
            width: style.stroke.width,
          }) : new Stroke({
            color: this.getFill(style).getColor(),
            width: 1,
          }),
          fill: this.getFill(style),
          text: label && label.field ? this.createLabel(label, props, reso) : undefined,
        });
      default:
        throw Error('invalid style type ' + style.geometryType);
    }
  }

  private getBasicStyle(style: IStyle, props: any, reso: number): Style {
    return this.createStyle(style.defaultStyle, style.label, props, reso);
  }

  private parsePropValue(style: IStyle, props: any): [boolean, boolean, string | number] {
    let propValue = props[style.dataField];
    const isString = typeof propValue === typeof '';
    if (isString) {
      propValue = props[style.dataField];
    } else {
      propValue = parseFloat(propValue);
      if (isNaN(propValue)) {
        propValue = props[style.dataField];
      }
    }
    if (!propValue) {
      return [false, false, null];
    }
    return [true, isString, propValue];
  }

  private parseNumberFromKey(key: string): [boolean, number, string] {
    if (!key) {
      return [false, 0, ''];
    }
    const n = parseFloat(key);
    const isPure = !key.Contains(' ');
    if (isPure) {
      return [true, n, ''];
    }
    const tmp = key.Split(' ');
    if (tmp.length < 2) {
      return [false, n, ''];
    }
    const v = parseFloat(tmp[0]);
    if (isNaN(v)) {
      return [false, n, ''];
    }
    return [true, v, tmp[1]];
  }

  private matchKey(key: string, keybefore: string, keyafter: string, value: string | number, isString: boolean): boolean {
    const keyInfo = this.parseNumberFromKey(key);
    const keyBeforeInfo = this.parseNumberFromKey(keybefore);
    const keyAfterInfo = this.parseNumberFromKey(keyafter);
    switch (keyInfo[2]) {
      case '<':
        return NumberFactory.NewDouble(value) < keyInfo[1];
      case '<=':
        return NumberFactory.NewDouble(value) <= keyInfo[1];
      case '>':
        return NumberFactory.NewDouble(value) > keyInfo[1];
      case '>=':
        return NumberFactory.NewDouble(value) >= keyInfo[1];
    }

    if (isString) {
      return key === value;
    }
    if (!keyInfo[0]) {
      return false;
    }
    if (!keyInfo[2] && !keyBeforeInfo[0] && !keyAfterInfo[0]) {
      return keyInfo[1] === NumberFactory.NewDouble(value);
    }
    if (!keyInfo[2] && keyBeforeInfo[0]) {
      return NumberFactory.NewDouble(value) > keyBeforeInfo[1] && NumberFactory.NewDouble(value) <= keyAfterInfo[1];
    }
    if (!keyInfo[2] && keyAfterInfo[0]) {
      return NumberFactory.NewDouble(value) <= keyAfterInfo[1] && NumberFactory.NewDouble(value) > keyBeforeInfo[1];
    }
    return false;
  }

  private getDynamicStyle(style: IStyle, props: any, reso: number): Style {
    const keys = Object.keys(style.legend).Sort();
    if (style.min && style.max) {
      const value = parseFloat(props[style.dataField]);
      let idx;
      let legend: ILegend;
      if (['st_ir_contours'].Contains(style.name)) {
        legend = this.mapStore.Legends.NeedLegend;
      } else if (['st_planning'].Contains(style.name)) {
        legend = this.mapStore.Legends?.NutrientPlanningLegend?.getValuesList()?.length > 0 ?
          this.mapStore.Legends?.NutrientPlanningLegend :
          this.mapStore.Legends.TaskMgmtLegend;
      } else if (['st_n_planning'].Contains(style.name)) {
        legend = this.mapStore.Legends?.NPlanningLegend?.getValuesList()?.length > 0 ?
          this.mapStore.Legends?.NPlanningLegend :
          this.mapStore.Legends.TaskMgmtLegend;
      } else if (['st_nuptake'].Contains(style.name)) {
        legend = this.mapStore.Legends.NUptakeLegend;
      } else if (['st_nd_uptake', 'st_nd_application', 'st_nd_gbi'].Contains(style.name)) {
        legend = this.mapStore.Legends.nLogInterpolationLegend;
      } else if (['st_n_sensor_log_points'].Contains(style.name)) {
        legend = this.mapStore.Legends.LogLegend;
      } else if (['st_pp_application', 'st_pp_uptake', 'st_pp_gbi'].Contains(style.name)) {
        legend = this.mapStore.Legends.PpLogInterpolationLegend;
      }

      const valueList = legend?.getValuesList();

      // If we have an assigned legend for the current map view we
      // are not recalculating the contour's class index.
      // Instead, we get the class index from already existing calculation
      // in legend. (assign contour value to legend class index)
      if (valueList?.length > 0) {
        idx = this.getValueLegendIndex(value, legend.getValuesList());
      } else {
        idx = this.getClass(value, props[style.max], keys.length - 1);
      }

      if (idx === undefined || idx === null || isNaN(idx)) {
        idx = 0;
      }
      return this.createStyle(idx === -1 ? style.defaultStyle : style.legend[idx], style.label || null,
        props, reso);
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const keybefore = keys[i - 1] || null;
      const keyafter = keys[i + 1] || null;
      const value = style.legend[key];
      const propInfo = this.parsePropValue(style, props);
      if (!propInfo[0]) {
        return this.createStyle(style.defaultStyle, style.label, props, reso);
      }
      if (this.matchKey(key, keybefore, keyafter, propInfo[2], propInfo[1])) {
        return this.createStyle(value, style.label, props, reso);
      }
    }
    return this.createStyle(style.defaultStyle, style.label, props, reso);
  }

  private getCustomStyle(): Fill {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const fill = new Fill();
    const size = 512 * DEVICE_PIXEL_RATIO;

    canvas.width = size;
    canvas.height = size;

    const color1 = 'rgba(150,0,0,0.35)';
    const color2 = 'transparent';
    const offset = 150;

    const numberOfStripes = 48;
    for (let i = 0; i < numberOfStripes * 2; i++) {
      const thickness = size / numberOfStripes;
      context.beginPath();
      switch (i % 4) {
        case 0:
          context.strokeStyle = color1;
          break;
        default:
          context.strokeStyle = color2;
      }
      context.lineWidth = thickness;
      context.lineCap = 'round';

      const startX = ((i + 1) * thickness + thickness / 2 - size) - offset;
      const startY = 0 - offset;
      const endX = ((1 + i) * thickness + thickness / 2) + offset;
      const endY = size + offset;
      context.moveTo(startX, startY);
      context.lineTo(endX, endY);
      context.stroke();
    }

    fill.setColor(context.createPattern(canvas, 'repeat'));
    return fill;
  }

  private getFill(style: IStyleDefinition): Fill {
    switch (style.fill.color) {
      case 'hatching':
        return this.getCustomStyle();
    }
    return new Fill({
      color: style.fill.color,
    });
  }

  private getClass(pValue: number, pMax: number, classes: number, pMin: number = 0): number {
    if (pMax <= 0 || pValue <= 0 || pMin < 0 || pMin > pMax) {
      return 0;
    }
    if (pValue > pMax) {
      return classes;
    }
    if (pValue < pMin) {
      return 0;
    }
    return Math.ceil((pValue - pMin) / ((pMax - pMin) / classes));
  }

  /**
   * Finds index of matching legend class/element
   * @param pValue the current contour's value
   * @param legendValues all legend classes/values
   * @private
   */
  private getValueLegendIndex(pValue: number, legendValues: ILegendValue[]): number {
    if (pValue < 0.01) {
      return -1;
    }
    for (let legendClassIndex = legendValues.length - 1; legendClassIndex >= 0; legendClassIndex--) {
      if (pValue >= legendValues[legendClassIndex].value) {
        return legendClassIndex;
      }
    }
    return legendValues.length - 1;
  }

  private setLegendSubjects(selectedStyle: IStyle, data: any): void {
    if (['st_pp_application', 'st_pp_uptake', 'st_pp_gbi'].Contains(selectedStyle.name)) {
      this.mapStore.Legends.PpLogInterpolationLegend.subjects[0].next(data[selectedStyle['min']]);
      this.mapStore.Legends.PpLogInterpolationLegend.subjects[1].next(data[selectedStyle['max']]);
    } else if (['st_nd_application', 'st_nd_uptake', 'st_nd_gbi'].Contains(selectedStyle.name)) {
      this.mapStore.Legends.nLogInterpolationLegend.subjects[0].next(data[selectedStyle['min']]);
      this.mapStore.Legends.nLogInterpolationLegend.subjects[1].next(data[selectedStyle['max']]);
    }
  }
}

let instance: MapFactoryStyler = null;

export function getMapFactoryStyler(address: string, mapStore: MapStore): MapFactoryStyler {
  if (!instance) {
    instance = new MapFactoryStyler(address, mapStore);
  }
  return instance;
}

export function mapFactoryStyler(): MapFactoryStyler {
  return instance;
}

export function calculateCurrentNeedSteps(step: number, classes: number, max: number): number {
  return step * Math.ceil(max / (step * classes));
}
