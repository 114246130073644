import {MapStore} from './map.store';
import {
  GeometryEditorTool,
  GeometryEditorToolGroup
}                 from '../../map/components/edit/ap-edit-tooltype';
import {
  ApCustomTypes,
  FieldIntegrateSuccess,
  GeometryEditorInformStateChange,
  GeometryEditorSendUpdate,
  GeometryEditorUpdate,
  NetTypes
} from 'invoker-transport';
import Feature    from 'ol/Feature';
import {
  ApEditStyles
}                 from '../../ap-map/layers/ap-edit-styles';
import {
  Guid,
  SafeBehaviorSubject
}                 from 'ts-tooling';
import Geometry   from 'ol/geom/Geometry';
import {
  ApVectorSelection,
  LayerSyncStrategy,
  MAP_PROJECTION
} from '../../ap-map';
import {
  EventEmitter
}                 from '@angular/core';
import {
  GeoJSONConverter
}                 from '../../ap-map/utils/geojson.converter';
import {
  FieldStore
}                 from '../farm/field.store';
import {
  delay
}                 from 'rxjs/operators';
import IGeometryUpdate = Data.GeometryEditor.IGeometryUpdate;
import IField = Data.FieldManagement.IField;
import GeometryUpdateType = Data.GeometryEditor.GeometryUpdateType;
import IGuid = System.IGuid;
import {
  GeometryEditorMerge,
  GeometryEditorMergeDone,
  GeometryEditorSplit, GeometryEditorSplitDone
} from '../../../../projects/invoker-transport/src/lib/actions/map';
import IFieldGeom = Data.FieldManagement.IFieldGeom;
import IGeometry = NetTopologySuite.Geometries.IGeometry;
import Polygon from 'ol/geom/Polygon';
import {GeoJSON} from 'ol/format';
import {Coordinate} from 'ol/coordinate';
import OlVectorSource from 'ol/source/Vector';
import IGeoJsonPolygon = Data.Geometry.IGeoJsonPolygon;

export interface IGeometryEditorState {
  activeTool: GeometryEditorTool;
  activeToolGroup: GeometryEditorToolGroup;
  deleteButtonVisible: boolean;
  showEditbar: boolean;
  editFeature: Feature;
  deleteGeometry: IField[];
  currentArea: number;
  selectedFeature: Feature;
  featureLocked: boolean;
}

export class MapEditStore {

  constructor(private mapStore: MapStore) {
    this.listenBackendGeometryMerge();
  }

  public get ActiveToolGroup(): GeometryEditorToolGroup {
    return this.ActiveToolGroup$.getValue();
  }

  public get ActiveToolGroup$(): SafeBehaviorSubject<GeometryEditorToolGroup> {
    return this.mapStore.Listen(s => s.editor.activeToolGroup);
  }

  public get CurrentArea$(): SafeBehaviorSubject<number> {
    return this.mapStore.Listen(s => s.editor.currentArea);
  }

  public get DeleteButtonVisible$(): SafeBehaviorSubject<boolean> {
    return this.mapStore.Listen(s => s.editor.deleteButtonVisible);
  }

  public get DeleteGeometry$(): SafeBehaviorSubject<IField[]> {
    return this.mapStore.Listen(s => s.editor.deleteGeometry);
  }

  public get EditBarVisibility$(): SafeBehaviorSubject<boolean> {
    return this.mapStore.Listen(s => s.editor.showEditbar);
  }

  public get EditedFeature$(): SafeBehaviorSubject<Feature<Geometry>> {
    return this.mapStore.Listen(s => s.editor.editFeature);
  }

  public get FeatureLocked(): boolean {
    return this.FeatureLocked$.getValue();
  }

  public get FeatureLocked$(): SafeBehaviorSubject<boolean> {
    return this.mapStore.Listen(s => s.editor.featureLocked);
  }

  public get SelectedFeature$(): SafeBehaviorSubject<Feature<Geometry>> {
    return this.mapStore.Listen(s => s.editor.selectedFeature);
  }

  public get ActiveTool(): GeometryEditorTool {
    return this.ActiveTool$.getValue();
  }

  public get ActiveTool$(): SafeBehaviorSubject<GeometryEditorTool> {
    return this.mapStore.Listen(s => s.editor.activeTool);
  }

  public static DefaultState: IGeometryEditorState = {
    deleteButtonVisible: false,
    activeTool: GeometryEditorTool.NONE,
    activeToolGroup: GeometryEditorToolGroup.NONE,
    showEditbar: false,
    currentArea: 0,
    deleteGeometry: [],
    editFeature: null,
    selectedFeature: null,
    featureLocked: false,
  };

  public PolygonEditLayerChange$ = new EventEmitter();
  private gridSelectedFields: any[] = [];

  public onDrawNew = new EventEmitter(true);
  public onSplit = new EventEmitter(true);
  public onSplitEnd = new EventEmitter(true);
  public onModify = new EventEmitter(true);
  public onDrawNewEnd = new EventEmitter(true);
  public onModifyEnd = new EventEmitter(true);
  public onDrawNewHole = new EventEmitter(true);
  public onDrawNewHoleEnd = new EventEmitter(true);
  public onDeleteHole = new EventEmitter(true);
  public onDeleteHoleEnd = new EventEmitter(true);
  public onCloseEditor = new EventEmitter(true);
  public onDeleteSketchGeometry = new EventEmitter(true);

  private fieldsVisibleBefore = false;
  private fieldCropsVisibleBefore = false;

  public CancelEditFeature(featId: Guid): void {
    this.mapStore.Layers.PolygonEditLayer.removeApFeature(featId.toString());
  }

  public startListening(): void {
    this.listenActiveToolChange();
    this.listenBackendGeometryUpdate();
  }

  /**
   * set the Visibility of the Map Delete Button to false
   */
  public HideDeleteButton(): void {
    this.mapStore.Mutate(s => s.editor.deleteButtonVisible, () => false);
  }

  public InsertInPolygonEdit(features: Feature[]): void {
    this.mapStore.Layers.PolygonEditLayer.SyncFeatures(features, LayerSyncStrategy.RESTRAINED);
    this.PolygonEditLayerChange$.emit();
  }

  public LockFeature(state: boolean): void {
    this.mapStore.Mutate(s => s.editor.featureLocked, () => state);
  }

  public RemoveFromPolygonEditLayer(features: Feature[]): void {
    this.mapStore.Layers.PolygonEditLayer.SyncFeatures(features, LayerSyncStrategy.DELETE);
    this.PolygonEditLayerChange$.emit();
  }

  public SendGeometryUpdate(update: IGeometryUpdate): void {
    this.mapStore.DispatchBackend(new GeometryEditorSendUpdate([
      {Name: 'update', Type: ApCustomTypes.Data_GeometryEditor_GeometryUpdate, Value: update}
    ]));
  }

  public SetActiveTool(ttype: GeometryEditorTool): void {
    this.mapStore.Mutate(s => s.editor.activeTool, () => ttype);
  }

  public SetDeleteGeometry(fields: IField[]): void {
    this.mapStore.Mutate(s => s.editor.deleteGeometry, () => fields);
  }

  public SetEditBarVisibility(value: boolean): void {
    this.mapStore.Mutate(s => s.editor.showEditbar, () => value);
  }

  public SetEditFeature(feat: Feature): void {
    const tag = feat.get('tag');
    if (tag === 'modify') {
      this.mapStore.Layers.PolygonEditLayer
        .selectFeature(feat.getId().toString(), ApEditStyles.selectedStyle);
    }
  }

  public SetFeatureArea(area: number): void {
    this.mapStore.Mutate(s => s.editor.currentArea, () => area);
  }

  /**
   * set the Visibility of the Map Delete Button to true
   */
  public ShowDeleteButton(): void {
    this.mapStore.Mutate(s => s.editor.deleteButtonVisible, () => true);
  }

  public ShowEditbar(value: boolean): void {
    this.mapStore.Mutate(s => s.editor.showEditbar, () => value);
  }

  private listenBackendGeometryUpdate(): void {
    this.mapStore.backend.registerObservable(GeometryEditorUpdate).subscribe(msg => {
      const update = msg.Data as IGeometryUpdate;
      switch (update.Type) {
        case GeometryUpdateType.INSERT:
          const features = GeoJSONConverter.GeometryStringToFeature([update.Geom], MAP_PROJECTION);
          this.InsertInPolygonEdit(features);
          break;
        case GeometryUpdateType.DELETE:
          break;
        case GeometryUpdateType.UPDATE:
          break;
      }
    });
  }

  private listenBackendGeometryMerge(): void {
    this.mapStore.backend.registerObservable(GeometryEditorMergeDone).subscribe(msg => {
      this.mapStore.Mutate(s => s.mergeFieldResult, () => msg.Data);
    });
  }

  public ClearMergeFieldResult(): void {
    this.mapStore.Mutate(s => s.mergeFieldResult, () => null);
  }

  public PrepareNewField(gridSelectedFields: IGuid[], fieldStore: FieldStore): void {
    this.prepareEditLayers(fieldStore);
    this.mapStore.Mutate(s => s.editor.activeToolGroup, () => GeometryEditorToolGroup.NEW);
    this.openEditBar(GeometryEditorTool.DRAW_NEW);
  }

  public PrepareModifyField(gridSelectedFields: IGuid[], fieldStore: FieldStore, prepareLayers = true): void {
    fieldStore.fieldsToIgnoreInSnap = [];
    if (prepareLayers) {
      this.prepareEditLayers(fieldStore);
    }
    this.selectEditField(fieldStore);
    this.mapStore.Mutate(s => s.editor.activeToolGroup, () => GeometryEditorToolGroup.MODIFY);
    this.openEditBar(GeometryEditorTool.MODIFY);
  }

  public PrepareSplitField(gridSelectedFieldGeomId: IGuid, fieldStore: FieldStore): void {
    fieldStore.fieldsToIgnoreInSnap = [];

    this.prepareEditLineLayers(gridSelectedFieldGeomId);
    // this.selectEditField(fieldStore);
    this.mapStore.Mutate(s => s.editor.activeToolGroup, () => GeometryEditorToolGroup.NEW);
    this.openEditBar(GeometryEditorTool.SPLIT);
  }

  public UpdateSplitData(data: any[]): void {
    this.mapStore.Mutate(s => s.splitFieldResult, () => data);
  }

  public PrepareModifyMergedField(selectedFields: IGuid[], geometryCoordinates: Coordinate[][], fieldStore: FieldStore): void {
    this.prepareEditLayers(fieldStore, geometryCoordinates, selectedFields);

    this.selectEditField(fieldStore);
    this.mapStore.Mutate(s => s.editor.activeToolGroup, () => GeometryEditorToolGroup.MODIFY);
    this.openEditBar(GeometryEditorTool.MODIFY);
  }

  public PrepareDrawNewHole(): void {
    this.openEditBar(GeometryEditorTool.DRAW_HOLE);
  }

  public PrepareDeleteHole(): void {
    this.openEditBar(GeometryEditorTool.DELETE_HOLE);
  }

  public CloseEditor(fieldStore: FieldStore): void {
    this.removeEditLayers(fieldStore);
    this.cancelActions();
    this.mapStore.Mutate(s => s.editor.activeToolGroup, () => GeometryEditorToolGroup.NONE);
    this.closeEditBar();
  }

  public MergeFieldGeometries(geom1: IGuid, geom2: IGuid): void {
    this.mapStore.DispatchBackend(new GeometryEditorMerge([
      {Name: 'geomIds', Type: NetTypes.GUID + '[]', Value: [geom1, geom2]}
    ]));
  }

  public SplitFieldGeometry(geomId: IGuid, splitLine: object, onDone: any): void {
    this.mapStore.DispatchBackend(new GeometryEditorSplit([
      {Name: 'geomId', Type: NetTypes.GUID, Value: geomId},
      {Name: 'splitLine', Type: ApCustomTypes.Data_Geometry_GeoJsonLine, Value: splitLine}
    ])).watchStream({
      action: GeometryEditorSplitDone,
      todo: payload => {
        if (payload.Error) {
          console.warn(payload.Error);
          return;
        }

        onDone(payload.Data);
      },
    });
  }

  private prepareEditLayers(fieldStore: FieldStore, replaceWith: Coordinate[][] = null, replaceId: IGuid[] = null): void {
    this.mapStore.Layers.PolygonEditLayer.clear();
    this.mapStore.Layers.PolygonEditViewLayer.clear();
    if (Array.isArray(this.mapStore.Layers.FieldsLayer?.Source?.getFeatures())) {
      const editFields: Feature[] = [];
      const otherFields: Feature[] = [];
      for (const feat of this.mapStore.Layers.FieldsLayer.Source.getFeatures()) {
        const id = feat.getId();
        if (replaceId != null && replaceId[0] === id) {
          this.mapStore.Layers.FieldsLayer.Source.removeFeature(feat);
          feat.setGeometry(new Polygon(replaceWith));
          this.mapStore.Layers.FieldsLayer.Source.addFeature(feat);
        }

        if (replaceId != null && replaceId[1] === id) {
          this.mapStore.Layers.FieldsLayer.Source.removeFeature(feat);
        } else if (fieldStore.EditFields.Contains(id)) {
          editFields.push(feat);
        } else {
          otherFields.push(feat);
        }
      }

      this.mapStore.Layers.PolygonEditLayer.replaceSource(editFields);
      this.mapStore.Layers.PolygonEditViewLayer.replaceSource(otherFields);
    }

    this.fieldsVisibleBefore = this.mapStore.Layers.FieldsLayer.Visibility === true;
    this.fieldCropsVisibleBefore = this.mapStore.Layers.FieldsCropLayer.Visibility === true;
    this.mapStore.Layers.FieldsLayer.Visibility = false;
    this.mapStore.Layers.FieldsCropLayer.Visibility = false;
    this.mapStore.Layers.PolygonEditLayer.Visibility = true;
    this.mapStore.Layers.PolygonEditViewLayer.Visibility = true;
  }

  private prepareEditLineLayers(fieldId: IGuid): void {
    this.mapStore.Layers.PolygonEditViewLayer.clearSelection();
    this.mapStore.Layers.PolygonEditViewLayer.clear();
    this.mapStore.Layers.PolygonEditLayer.clearSelection();
    this.mapStore.Layers.PolygonEditLayer.clear();

    if (Array.isArray(this.mapStore.Layers.FieldsLayer?.Source?.getFeatures())) {
      for (const feat of this.mapStore.Layers.FieldsLayer.Source.getFeatures()) {
        if (feat.getId() === fieldId) {
          this.mapStore.Layers.PolygonEditViewLayer.replaceSource([feat]);
          this.mapStore.Layers.PolygonEditViewLayer.selectPolygon(feat.getId().toString());
          break;
        }
      }
    }

    this.fieldsVisibleBefore = this.mapStore.Layers.FieldsLayer.Visibility === true;
    this.fieldCropsVisibleBefore = this.mapStore.Layers.FieldsCropLayer.Visibility === true;
    this.mapStore.Layers.FieldsLayer.Visibility = false;
    this.mapStore.Layers.FieldsCropLayer.Visibility = false;
    this.mapStore.Layers.PolygonEditViewLayer.Visibility = true;
    this.mapStore.Layers.PolygonEditViewLayer.fitSelection(150, () =>  {
       this.mapStore.Layers.FieldDescriptionLayer.clear();
       this.mapStore.Layers.FieldDescriptionLayer.SyncFeatures(this.mapStore.Layers.PolygonEditViewLayer.innerSource.getFeatures(), LayerSyncStrategy.FORCE);
    });
    this.mapStore.Layers.PolygonEditViewLayer.clearSelection();
  }

  private removeEditLayers(fieldStore: FieldStore): void {
    this.mapStore.Layers.PolygonEditLayer.Visibility = false;
    this.mapStore.Layers.PolygonEditViewLayer.Visibility = false;
    this.mapStore.Layers.FieldsLayer.Visibility = this.fieldsVisibleBefore;
    this.mapStore.Layers.FieldsCropLayer.Visibility = this.fieldCropsVisibleBefore;
    this.mapStore.Layers.PolygonEditLayer.clear();
    this.mapStore.Layers.PolygonEditViewLayer.clear();
    this.mapStore.Layers.FieldsLayer.replaceSource(fieldStore.Fields, fieldStore);
  }

  private selectEditField(fieldStore: FieldStore): void {
    for (const id of fieldStore.EditFields) {
      this.mapStore.Layers.PolygonEditLayer.selectPolygon(id.toString());
    }
    this.mapStore.Layers.PolygonEditLayer.fitSelection(150);
  }

  private openEditBar(tool: GeometryEditorTool, hideBar = false): void {
    this.mapStore.DispatchBackend(new GeometryEditorInformStateChange([
      {Name: 'state', Type: NetTypes.BOOL, Value: true},
    ]));
    this.mapStore.Mutate(s => s.editor.showEditbar, () => hideBar);
    this.mapStore.Mutate(s => s.editor.activeTool, () => tool);
  }

  private closeEditBar(): void {
    this.mapStore.DispatchBackend(new GeometryEditorInformStateChange([
      {Name: 'state', Type: NetTypes.BOOL, Value: false},
    ]));
    this.mapStore.Mutate(s => s.editor.showEditbar, () => false);
    this.mapStore.Mutate(s => s.editor.activeTool, () => GeometryEditorTool.NONE);
    this.onCloseEditor.emit();
  }

  private listenActiveToolChange(): void {
    this.mapStore.Editor.ActiveTool$.pipe(delay(1)).subscribe(tool => {
      switch (tool) {
        case GeometryEditorTool.DRAW_NEW:
          this.onDrawNew.emit();
          break;
        case GeometryEditorTool.SPLIT:
          this.onSplit.emit();
          break;
        case GeometryEditorTool.MODIFY:
          this.onModify.emit();
          break;
        case GeometryEditorTool.DRAW_HOLE:
          this.onDrawNewHole.emit();
          break;
        case GeometryEditorTool.DELETE_HOLE:
          this.onDeleteHole.emit();
          break;
      }
    });
  }

  private cancelActions(): void {
    switch (this.mapStore.Editor.ActiveTool) {
      case GeometryEditorTool.DRAW_NEW:
        this.onDrawNewEnd.emit();
        break;
      case GeometryEditorTool.SPLIT:
        this.onSplitEnd.emit();
        break;
      case GeometryEditorTool.MODIFY:
        this.onModifyEnd.emit();
        break;
      case GeometryEditorTool.DRAW_HOLE:
        this.onDrawNewHoleEnd.emit();
        break;
      case GeometryEditorTool.DELETE_HOLE:
        this.onDeleteHoleEnd.emit();
        break;
    }
  }

  DeleteSketchGeometry(): void {
    this.onDeleteSketchGeometry.emit();
  }
}
