import {delay, filter, map}                                       from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {
  EventEmitter,
  Injectable
}                                                                 from '@angular/core';
import {
  ApDynGridPagerWizardBreadcrumbsStepper,
  ApDynGridPagerWizardButton,
  ApDynGridPagerWizardConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynComponentComponent
}                                                                 from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnHideConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  CampaignYearStore
}                                                                 from '../../../stores/login/campaignyear.store';
import {
  CropRotationStore
}                                                                 from '../../../stores/farm/crop.rotation.store';
import {
  CropTypeStore
}                                                                 from '../../../stores/base-data/crop.types.store';
import {
  ApDynGridStatusColumnConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridGroupColumnConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {
  ApElementOxydTypeEnum,
  MapViewMode
}                                                                 from '../../../ap-interface';
import {
  MapViewStore
}                                                                 from '../../../stores/layout/mapview.store';
import {
  JobsStore
}                                                                 from '../../../stores/administration/jobs.store';
import {
  AreaUnit,
  AreaUnitConverterPipe,
  GetRoundNumericPipe
}                                                                 from '../../../ap-utils';
import {
  ApTranslationService
}                                                                 from '../../../ap-utils/service/ap-translation.service';
import {
  SoilSampleDateStore
}                                                                 from '../../../stores/nutrients/soilsampledate.store';
import {
  FieldStore
}                                                                 from '../../../stores/farm/field.store';
import {
  RbStore
}                                                                 from '../../../stores/nutrients/rb.store';
import {
  ApDateService
}                                                                 from '../../../ap-core/services/ap-date-service';
import {
  ApFieldFormatService
}                                                                 from '../../../ap-utils/service/ap-field-format.service';
import {
  ApNutrientService
}                                                                 from '../../../ap-utils/service/ap-nutrient.service';
import {
  ApElementType
}                                                                 from '../../../ap-interface/enums/ap-elements-type.enum';
import {
  GetElementService
}                                                                 from '../../../ap-utils/service/ap-get-element.service';
import {
  NutrientPlanningEntryService
}                                                                 from './nutrient-planning-entry.service';
import {
  INutrientPlanningEntryStepsConfig,
  Limits
}                                                                 from './nutrient-planning-types';
import {
  ApNutrientWizardStep
}                                                                 from '../../../ap-interface/enums/ap-nutrient-wizard-setup.enums';
import {
  NotifyStore
}                                                                 from '../../../stores/dialog/notify.store';
import {
  GetRoundNumericService
}                                                                 from '../../../ap-utils/service/get-round-numeric.service';
import {
  SettingsStore
}                                                                 from '../../../stores/base-data/settings.store';
import {
  FieldNutrientDistributionStore
}                                                                 from '../../../stores/nutrients/field-nutrient-distributions.store';
import ISettings = Data.BaseData.ISettings;
import IApValidationResult = Data.Api.Validation.IApValidationResult;

@Injectable()
export class NutrientPlanningEntrySelectionStepService implements INutrientPlanningEntryStepsConfig {
  private _dynComponent: ApDynComponentComponent;
  private _settings: ISettings;
  private _onCancelSelection = new EventEmitter();
  private _onNextSelection = new EventEmitter();
  private _year$ = new BehaviorSubject<number>(undefined);
  private _previousYear = new EventEmitter<void>();
  private _nextYear = new EventEmitter<void>();
  private _cropRotationLimits$ = new BehaviorSubject<Limits>(undefined);
  private _subscriptions: Subscription[] = [];
  private _statusCss = [
    'ap-status-red',
    'ap-status-yellow',
    'ap-status-green',
    'ap-status-grey',
  ];

  constructor(private rbStore: RbStore,
              private jobsStore: JobsStore,
              private fieldStore: FieldStore,
              private notifyStore: NotifyStore,
              private dateService: ApDateService,
              private mapViewStore: MapViewStore,
              private settingsStore: SettingsStore,
              private cropTypeStore: CropTypeStore,
              private campaignYearStore: CampaignYearStore,
              private cropRotationStore: CropRotationStore,
              private soilSampleDateStore: SoilSampleDateStore,
              private fieldNutrientDistributionStore: FieldNutrientDistributionStore,
              private elementService: GetElementService,
              private nutrientService: ApNutrientService,
              private fieldFormatService: ApFieldFormatService,
              private translationService: ApTranslationService,
              private roundNumericService: GetRoundNumericService,
              private planningEntryService: NutrientPlanningEntryService,
              private areaUnitPipe: AreaUnitConverterPipe,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  public initData(): void {
    this._settings = this.settingsStore.FirstSetting;
  }

  public setSubscriptions(dynComponent: ApDynComponentComponent): void {
    this._dynComponent = dynComponent;
    this._subscriptions.push(this._dynComponent.gridInitialized.subscribe(() => {
      if (this.planningEntryService.activeWizardStep.getValue() === ApNutrientWizardStep.Selection) {
        const fieldsId = this.planningEntryService.getSelectedFields.map(x => x.Id.toString());
        this._dynComponent.dynGrid.gridPaging.setSelectedKeys(fieldsId);
      }
    }));
    this._subscriptions.push(this._dynComponent.SelectedItems$.subscribe(d => {
      this._onSelectionChange(d);
    }));
    this._subscriptions.push(this.planningEntryService.detectSelectedFieldsChanges.subscribe(fields => {
      if (this._dynComponent.dynGrid?.gridPaging) {
        const fieldsId = fields.map(x => x.Id.toString());
        this._dynComponent.dynGrid.gridPaging.setSelectedKeys(fieldsId);
      }
    }));
    this._subscriptions.push(combineLatest([
      this.cropRotationStore.Listen(s => s.history).pipe(
        map((history) => Object.values(history)),
        filter((crops) => crops.length !== 0),
        map((crops) => crops.map((crop) => Object.keys(crop).filter((key) => crop[key] !== null).map((key) => +key))),
        map((years) => years.reduce((a, b) => a.concat(b)))),
      this.campaignYearStore.Listen(s => s.selectedYear)
    ]).pipe(
      map(([dates, campaignYear]) => ({
          min: Math.min(...dates, campaignYear.Year),
          max: Math.max(...dates, campaignYear.Year)
        })
      )
    ).subscribe(l => this._cropRotationLimits$.next({Min: l.min, Max: l.max})));
    this._subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe((campaignYear) => {
      if (campaignYear) {
        this._year$.next(campaignYear.Year);
      }
    }));
    this._subscriptions.push(this._previousYear.subscribe(() => {
      this._year$.next(this._year$.value - 1);
    }));
    this._subscriptions.push(this._nextYear.subscribe(() => {
      this._year$.next(this._year$.value + 1);
    }));
    this._subscriptions.push(this._onNextSelection.subscribe(() => {
      if (this.planningEntryService.summary.User_Operation_Mode_Const_Only
        || this.planningEntryService.summary.User_Operation_Mode_Some_Const) {
        let warningText = `${this.translationService.translate('Global__Only_Constant_Planning_Possible')}
        (${this.translationService.translate('Nutrients_Ips_Pages__StatusRed')})`;
        if (this.planningEntryService.summary.User_Operation_Mode_Some_Const
          && !this.planningEntryService.summary.User_Operation_Mode_Const_Only) {
          const itemsForConst = this._dynComponent?.getSelectedItems()?.filter((item: any) => item.Status !== 1);
          warningText = `${itemsForConst?.length}/${this._dynComponent?.getSelectedItems()?.length}
          ${this.translationService.translate('Base__Fields')}: ${warningText}`;
        }
        this.notifyStore.addMessage({
          DisplayTimeout: 5,
          Level: Data.Api.Validation.ApValidationLevel.Warning,
          ErrorKey: warningText
        } as IApValidationResult);
      }
      this.planningEntryService.activeWizardStep.next(ApNutrientWizardStep.Details);
    }));
    this._subscriptions.push(this._onCancelSelection.subscribe(() => {
      this.planningEntryService.closeForm.emit();
    }));
  }

  public clearData(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
    this._year$.next(undefined);
  }

  public getPager(breadCrumbsColumns: string[]): ApDynGridPagerWizardConfig {
    return new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardButton('Global__Cancel', this._onCancelSelection, {id: 'button_cancel'}),
      new ApDynGridPagerWizardBreadcrumbsStepper(breadCrumbsColumns, 0),
      new ApDynGridPagerWizardButton('Global_Next_With_Arrow', this._onNextSelection, {
        id: 'button_next',
        active: this._dynComponent.SelectedItems$.pipe(
          map((items) => items.length !== 0 && !items.some(x => x.JobInProgress)),
          delay(0)
        )
      })
    );
  }

  public getColumns(): Observable<ApDynGridColumnConfigBase[]> {
    return combineLatest([
      this._cropRotationLimits$,
      this.cropRotationStore.GetHistory$,
      this.cropTypeStore.GetCropTypesNames$,
      this.settingsStore.FirstSetting$
    ]).pipe(
      filter(([limits, cropRotation, ct, settings]) => !!limits && Object.keys(cropRotation).length !== 0 && !!ct && !!settings),
      map(([limits, cropRotation, ct, settings]) => ({limits, cropRotation, ct, settings})),
      map((p) => {
        return [
          new ApDynGridStatusColumnConfig({
            field: 'Status',
            class: 'StatusClass',
            description: 'StatusDesc',
            showHeaderTitle: false,
            filterable: true,
            pending: this.jobsStore.BlockedFieldIdsBuWsvCeIrRbFieldRaster$,
            pendingTooltip: this.jobsStore.BlockedFieldIdsBuWsvCeIrRbFieldRasterTooltip$
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'FieldNumber',
            title: 'Global__NumberAbbr',
            filterable: true,
            width: 60,
            headerFilterable: true
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Field.FieldName',
            title: 'Global__FieldName',
            filterable: true,
            width: 180,
            headerFilterable: true,
            cssClass: 'left',
            sortIndex: 0,
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Area',
            title: 'Global__Area_Unit',
            filterable: true,
            filterType: FilterType.NUMBER,
            width: 60,
            cssClass: 'right',
            headerFilterable: true,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [p.settings.DigitsAfterDecimalPoint]
            }]
          }),
          new ApDynGridGroupColumnConfig({
            headerStyle: {
              'text-align': 'center'
            },
            style: {
              border: '1px solid #2c3237'
            },
            title: 'Nutrients__Average_RemainingNeed_2_kg_ha',
            groupColumns: [
              new ApDynGridPropertyColumnConfig({
                title: !p.settings.IsOxidFarm ? 'Global__NutrientP' : 'Global__NutrientP2O5',
                field: 'Remain2.P',
                width: 60,
                filterType: FilterType.NUMBER,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [0]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: !p.settings.IsOxidFarm ? 'Global__NutrientK' : 'Global__NutrientK2O',
                field: 'Remain2.K',
                width: 60,
                filterType: FilterType.NUMBER,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [0]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: !p.settings.IsOxidFarm ? 'Global__NutrientMg' : 'Global__NutrientMgO',
                field: 'Remain2.Mg',
                width: 60,
                filterType: FilterType.NUMBER,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [0]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: 'Nutrients__CaO',
                field: 'Remain2.CaO',
                width: 60,
                filterType: FilterType.NUMBER,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [0]
                }]
              })
            ]
          }),
          new ApDynGridGroupColumnConfig({
            headerButtons: {
              nextClicked: this._nextYear,
              nextDisabled: this._year$.pipe(map((y) => y >= p.limits.Max)),
              previousClicked: this._previousYear,
              previousDisabled: this._year$.pipe(map((y) => y <= p.limits.Min)),
            },
            title: 'Global__Crop',
            groupColumns: this._getCropRotationConfigs(p.limits),
            width: 100,
            hide: new ApDynGridColumnHideConfig({
              widthHidePriority: 1
            })
          })
        ];
      })
    );
  }

  public getItems(): Observable<any> {
    return combineLatest([
      this.soilSampleDateStore.Listen(s => s.dates),
      this.fieldNutrientDistributionStore.SampleRegions$,
      this.cropRotationStore.Listen(s => s.history),
      this.fieldStore.Listen(s => s.data),
      this.rbStore.Listen(s => s.rb),
      this.jobsStore.BlockedFieldIdsBuWsvCeIrRbFieldRaster$,
      this.cropRotationStore.Listen(s => s.cropRotationAttributes),
    ]).pipe(
      filter(([, , cropRotationHistory, fields, rb, _]) => fields && fields.length !== 0 && Object.keys(cropRotationHistory).length !== 0 && !!rb),
      map(([soilSampleDates, sampleRegions, cropRotationHistory, fields, rb, jobFieldGeomIds, cropRotationAttributes]) => fields.map((field) => {
        const fieldGeom = this.fieldStore.getCurrentFieldGeom(field);
        const rbResult = rb.FirstOrDefault(x => x.FieldGeomId === fieldGeom?.Id);
        const areaNumber = fieldGeom && fieldGeom.AdminArea > 0 ? fieldGeom.AdminArea : '-';
        const areaRound = this._areaRound(areaNumber);
        const cropRotationAttribute = cropRotationAttributes.find(x => x?.FieldGeomId === fieldGeom?.Id);
        const sampleDateObject = Object.keys(soilSampleDates).length > 0 ? soilSampleDates[field.Id as string] : undefined;
        const status = this.nutrientService.getGDStatusByCrAttribute(sampleDateObject, sampleRegions, cropRotationAttribute);
        const remainDemand = this.planningEntryService.getRemainDemands(rbResult);
        return {
          Id: field.Id,
          Status: status[0],
          StatusClass: this._statusCss[status[0] + 1],
          StatusDesc: status[1],
          SourceItem: field || {},
          FieldNumber: this.fieldFormatService.formatFieldNumber(field),
          Field: field,
          FieldGeomId: fieldGeom?.Id,
          Area: areaRound,
          NewestSampleDate: !!sampleDateObject?.Date ? this.dateService.getDateMidnight(sampleDateObject.Date) : undefined,
          Crop: cropRotationHistory[field.Id as string],
          ColumnLoading: rb.length === 0 && jobFieldGeomIds.Contains(field.Id.toString()),
          JobInProgress: jobFieldGeomIds.Contains(field.Id.toString()),
          RbStatistic: rbResult,
          Remain2: {
            P: this.elementService.CalculateElementOxidValueByGivenElementValue(
              remainDemand.find(x => x.ElementType === ApElementType.P).Amount,
              ApElementOxydTypeEnum.P
            ),
            K: this.elementService.CalculateElementOxidValueByGivenElementValue(
              remainDemand.find(x => x.ElementType === ApElementType.K).Amount,
              ApElementOxydTypeEnum.K
            ),
            Mg: this.elementService.CalculateElementOxidValueByGivenElementValue(
              remainDemand.find(x => x.ElementType === ApElementType.Mg).Amount,
              ApElementOxydTypeEnum.Mg
            ),
            CaO: remainDemand.find(x => x.ElementType === ApElementType.CaO).Amount,
          },
        };
      }))
    );
  }

  //#region "private Methods"

  private _onSelectionChange(currentSelected: any[]): void {
    const currentStep = this.planningEntryService.activeWizardStep.getValue();
    if (currentStep === ApNutrientWizardStep.Selection) {
      if (!currentSelected || currentSelected.length <= 0) {
        this.fieldStore.changeSelectedField([]);
      } else {
        this.planningEntryService.summary.Selected_Fields = currentSelected;
        this.planningEntryService.summary.User_Operation_Mode_Some_Const =
          currentSelected.Any(x => this._checkStatusToBeApplicableForConst(x.Status));
        this.planningEntryService.summary.User_Operation_Mode_Const_Only =
          currentSelected.TrueForAll(x => this._checkStatusToBeApplicableForConst(x.Status));
        this.fieldStore.changeSelectedField(currentSelected.map(e => e.Id.toString()));
        if (this.planningEntryService.isNewMode) {
          this.planningEntryService.rememberSelectedFields(currentSelected.map(x => x.SourceItem), false);
        }
      }
      this.planningEntryService.clearMapLayerAndLegend();
    }
  }

  private _getCropRotationConfigs(limits: Limits): any[] {
    const columns = [];
    for (let iYear = limits.Min - 1; iYear <= limits.Max + 3; ++iYear) {
      columns.push(new ApDynGridPropertyColumnConfig({
        title: `${iYear}`,
        field: `Crop.${iYear}.MainCropName`,
        filterable: true,
        headerFilterable: iYear === this.campaignYearStore.getSelectedCampaignYear().Year,
        width: 100,
        hide: new ApDynGridColumnHideConfig({
          mapHide: false,
          selfHide: combineLatest([this._year$, this.mapViewStore.Listen(s => s.mode)]).pipe(
            map(([year, mode]) => {
              if (year === iYear) {
                return false;
              }
              return !(mode === MapViewMode.HIDE && iYear > year - 2 && iYear < year + 4);
            }),
          )
        }),
        tooltip: ((dataItem) => {
          const yesNo = (b: boolean) => this.translationService.translate(b ? 'LabelYes' : 'LabelNo');
          const unit = this.translationService.translate('Global__UnitDtPerHa');
          if (!dataItem || !dataItem['Crop'] || !dataItem['Crop'][iYear]) {
            return '';
          }
          const c = dataItem['Crop'][iYear]?.MainCropName;
          let s = '-';
          let sYieldUnit = '-';
          if (dataItem['Crop'][iYear].SecondCrop !== 0) {
            s = dataItem['Crop'][iYear].SecondCropName;
            sYieldUnit = dataItem['Crop'][iYear].SecondCropYield + ' ' + unit;
          }
          const mainCrop = `${this.translationService.translate('Nutrients_Pages_Popups__MainCrop')}: ${c}`;
          const targetMain = `${this.translationService.translate('Global__Target')}: ${dataItem['Crop'][iYear].MainCropYield} ${unit}`;
          const straw = `${this.translationService.translate('Nutrients_Pages__StrawRemoved')}: ${yesNo(dataItem['Crop'][iYear].MainCropStraw)}`;
          const interTillage = `${this.translationService.translate('Nutrients_Pages_Popups__Intertillage')}: ${s}`;
          const targetInter = `${this.translationService.translate('Global__Target')}: ${sYieldUnit}`;
          const br = '<br>';
          return `${mainCrop}${br}${targetMain}${br}${straw}${br}${interTillage}${br}${targetInter}`;
        })
      }));
    }
    return columns;
  }

  private _areaRound(areaNumber: number | '-'): number | string {
    let areaRound: number | string;
    if (areaNumber !== '-') {
      const area = this.areaUnitPipe.transform(areaNumber, [AreaUnit.SQUARE_METER, AreaUnit.HEKTAR]);
      areaRound = this.roundNumericService.roundAsNumber(area, this._settings?.DigitsAfterDecimalPoint ?? 0);
    } else {
      areaRound = '-';
    }
    return areaRound;
  }

  private _checkStatusToBeApplicableForConst(status: number): boolean {
    return status === -1 || status === 0 || status === 2;
  }

  //#endregion "private Methods"
}
