import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {
  EventEmitter,
  Injectable
}                                                                 from '@angular/core';
import {
  filter,
  map
}                                                                 from 'rxjs/operators';
import {
  FormGroup,
  ValidatorFn,
  Validators
}                                                                 from '@angular/forms';
import {
  ObjectFactory
}                                                                 from 'ts-tooling';
import {
  ApDynGridPagerWizardBreadcrumbsStepper,
  ApDynGridPagerWizardButton,
  ApDynGridPagerWizardConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnHideConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridStatusColumnConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridGroupColumnConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {
  ApDynGridEditColumnConfig
}                                                                 from '../../../ap-dyngrids/config/ap-dyn-grid-edit-column-config';
import {
  ApElementOxydTypeEnum,
  ApStatsType,
  MapViewMode
}                                                                 from '../../../ap-interface';
import {
  ApElementType
}                                                                 from '../../../ap-interface/enums/ap-elements-type.enum';
import {
  MapViewStore
}                                                                 from '../../../stores/layout/mapview.store';
import {
  ApGuidUtil,
  GetRoundNumericPipe
}                                                                 from '../../../ap-utils';
import {
  GetElementService
}                                                                 from '../../../ap-utils/service/ap-get-element.service';
import {
  FieldStore
}                                                                 from '../../../stores/farm/field.store';
import {
  NutrientStore
}                                                                 from '../../../stores/nutrients/nutrient.store';
import {
  GetRoundNumericService
}                                                                 from '../../../ap-utils/service/get-round-numeric.service';
import {
  GetApplicationModeService
}                                                                 from '../../../ap-utils/service/ap-get-applicationmode.service';
import {
  LoginStore
}                                                                 from '../../../stores/login/login.store';
import {
  FertilizerStore
}                                                                 from '../../../stores/base-data/fertilizer.store';
import {
  CropTypeStore
}                                                                 from '../../../stores/base-data/crop.types.store';
import {
  ApDynformsValidator
}                                                                 from '../../../ap-dynforms/ap-dynforms-validator';
import {
  ApNumberRangeValidator
}                                                                 from '../../../ap-dynforms/validators/ap-number-range-validator';
import {
  ApDynformsConfigFieldset
}                                                                 from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigNumerictextbox
}                                                                 from '../../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApNutrientService
}                                                                 from '../../../ap-utils/service/ap-nutrient.service';
import {
  ApApplicationMode
}                                                                 from '../../../ap-interface/enums/ap-application-mode.enums';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                                                 from '../../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {
  ModalDialogStore
}                                                                 from '../../../stores/dialog/modal.dialog.store';
import {
  TranslationStore
}                                                                 from '../../../stores/translation/translation.store';
import {
  ApUtilService
}                                                                 from '../../../ap-utils/service/ap-util.service';
import {
  NutrientPlanningEntryService
}                                                                 from './nutrient-planning-entry.service';
import {
  CalculationValuesInput,
  CalculationValuesOutput,
  EditPlanningDataFormType,
  FieldGeomAndPlanId,
  INutrientPlaningData,
  INutrientPlanningEntryStepsConfig,
  PlanningEntryDataToEdit,
  UpdatedPlanningEntry
}                                                                 from './nutrient-planning-types';
import {
  MapStore
}                                                                 from '../../../stores/map/map.store';
import {
  FertilizerService
}                                                                 from '../../../services/data/fertilizer.service';
import {
  ApOperationMode
}                                                                 from '../../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApDynComponentComponent
}                                                                 from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {
  RouterStore
}                                                                 from '../../../stores/router/router.store';
import {
  ApNutrientWizardStep
}                                                                 from '../../../ap-interface/enums/ap-nutrient-wizard-setup.enums';
import {
  IPlanningWizardOverviewStatsItem,
  PlanningWizardOverviewModuleStats
}                                                                 from '../../../statistics/components/charts/planning-wizard-overview-stats/ap-planning-wizard-overview-stats-types';
import {
  ApTranslationService
}                                                                 from '../../../ap-utils/service/ap-translation.service';
import {
  OperationModesStore
}                                                                 from '../../../stores/common/operation-modes.store';
import {
  BaseFertilizationService
}                                                                 from '../../../services/data/base-fertilization.service';
import {
  StatisticStore
}                                                                 from '../../../stores/statistic/statistic.store';
import {
  NutrientManagementService
}                                                                 from '../../../nutrient-management/service/nutrient-management.service';
import {
  ApNutrientStatisticService
}                                                                 from '../../../nutrient-management/service/ap-nutrient-statistic.service';
import {
  RbStore
}                                                                 from '../../../stores/nutrients/rb.store';
import {
  SettingsStore
}                                                                 from '../../../stores/base-data/settings.store';
import {
  LanguageStore
}                                                                 from '../../../stores/translation/language.store';
import {
  MapFactoryStyler
}                                                                 from '../../../map-factory/style';
import {
  ApGetCropService
}                                                                 from '../../../stores/services/ap-get-crop.service';
import {
  CampaignYearService
}                                                                 from '../../../services/data/campaign-year.service';
import {
  CropRotationYield
}                                                                 from '../../../stores/farm/crop.rotation.store';
import {
  UnitService
}                                                                 from '../../../services/data/unit.service';
import {
  StatisticService
}                                                                 from '../../../services/data/statistic.service';
import IGuid = System.IGuid;
import ISettings = Data.BaseData.ISettings;
import IField = Data.FieldManagement.IField;
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;

@Injectable()
export class NutrientPlanningEntryOverviewStepService implements INutrientPlanningEntryStepsConfig {
  private _dynComponent: ApDynComponentComponent;
  private _isLocked: boolean;
  private _settings: ISettings;
  private _fieldGeomIdPlanId: FieldGeomAndPlanId[] = [];
  private _updatedPlanningEntries: UpdatedPlanningEntry[] = [];
  private _subscriptions: Subscription[] = [];
  private _selectedGridFields = new BehaviorSubject<IField[]>([]);
  private _onSave = new EventEmitter();
  private _goBackToDetails = new EventEmitter();
  private _goBackToSelection = new EventEmitter();
  private _canSave = new BehaviorSubject<boolean>(false);
  private _updateFormValuesBehavior = new BehaviorSubject<EditPlanningDataFormType>(null);

  constructor(private rbStore: RbStore,
              private mapStore: MapStore,
              private fieldStore: FieldStore,
              private loginStore: LoginStore,
              private routerStore: RouterStore,
              private mapViewStore: MapViewStore,
              private languageStore: LanguageStore,
              private settingsStore: SettingsStore,
              private cropTypeStore: CropTypeStore,
              private statisticStore: StatisticStore,
              private fertilizerStore: FertilizerStore,
              private modalDialogStore: ModalDialogStore,
              private translationStore: TranslationStore,
              private nutrientPlanningStore: NutrientStore,
              private operationModesStore: OperationModesStore,
              private unitService: UnitService,
              private cropService: ApGetCropService,
              private elementService: GetElementService,
              private nutrientService: ApNutrientService,
              private statisticService: StatisticService,
              private fertilizerService: FertilizerService,
              private translationService: ApTranslationService,
              private campaignYearService: CampaignYearService,
              private roundNumericService: GetRoundNumericService,
              private applicationModeService: GetApplicationModeService,
              private planningEntryService: NutrientPlanningEntryService,
              private baseFertilizationService: BaseFertilizationService,
              private nutrientManagementService: NutrientManagementService,
              private nutrientStatisticService: ApNutrientStatisticService,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  public initData(): void {
    this._isLocked = false;
    this._settings = this.settingsStore.FirstSetting;
  }

  public setSubscriptions(dynComponent: ApDynComponentComponent): void {
    this._dynComponent = dynComponent;
    this._subscriptions.push(this._dynComponent.SelectedItems$.subscribe(selected => {
      this._onSelectionChange(selected);
    }));
    this._subscriptions.push(this._goBackToDetails.subscribe(_ => {
      this._clearValues();
      this.planningEntryService.activeWizardStep.next(ApNutrientWizardStep.Details);
    }));
    this._subscriptions.push(this._goBackToSelection.subscribe(_ => {
      this._clearValues();
      this.planningEntryService.activeWizardStep.next(ApNutrientWizardStep.Selection);
    }));
    this._subscriptions.push(this.nutrientPlanningStore.FieldNutrientPlanningStatistic$.subscribe(statistic => {
      if (this.planningEntryService.activeWizardStep.getValue() === ApNutrientWizardStep.Overview) {
        this._listenNutrientPlanningStatistics(statistic);
      }
    }));
    this._subscriptions.push(this._onSave.subscribe(() => {
      if (this.planningEntryService.activeWizardStep.getValue() === ApNutrientWizardStep.Overview) {
        this.planningEntryService.savePlannings();
        this.planningEntryService.closeForm.emit();
      }
    }));
  }

  public clearData(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
    this._updateFormValuesBehavior?.unsubscribe();
    this._clearValues();
  }

  public getPager(): ApDynGridPagerWizardConfig {
    return new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardButton('Global_Back_With_Arrow', this._goBackToDetails, {id: 'button_prev'}),
      new ApDynGridPagerWizardBreadcrumbsStepper(['Global__Selection', 'Global__Details', 'Admin_Pages__Overview'], 2),
      new ApDynGridPagerWizardButton('Global__Save', this._onSave, {
        active: this._canSave,
        id: 'button_save'
      }),
    );
  }

  public getColumns(): Observable<ApDynGridColumnConfigBase[]> {
    return combineLatest([
      this.settingsStore.FirstSetting$,
      this.languageStore.SelectedLanguage$
    ]).pipe(
      filter(([settings, language]) => !!settings && !!language),
      map(([settings, language]) => ({settings, language})),
      map((p) => {
        return [
          new ApDynGridStatusColumnConfig({
            field: 'Status',
            class: 'StatusClass',
            description: 'StatusDesc',
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Nr',
            title: 'Docu_Ini__Number',
            filterable: true,
            filterType: FilterType.NUMBER,
            cssClass: 'right',
            headerFilterable: true,
            width: 60,
            hide: true
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'TNr',
            title: 'Docu_Ini_Subnumber',
            filterable: true,
            filterType: FilterType.NUMBER,
            cssClass: 'right',
            headerFilterable: true,
            width: 60,
            hide: true,
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'NrTNr',
            title: 'Docu_Ini__Number',
            headerFilterable: true,
            filterable: true,
            width: 70,
            hide: new ApDynGridColumnHideConfig({
              selfHide: this.mapViewStore.Listen(s => s.mode).pipe(map((mode) => mode === MapViewMode.HIDE))
            })
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Name',
            title: 'Docu_Ini__Name',
            filterable: true,
            headerFilterable: true,
            width: 180,
            sortIndex: 0
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Area',
            title: 'Docu_Ini__Area',
            filterable: true,
            headerFilterable: true,
            filterType: FilterType.NUMBER,
            hide: true,
            cssClass: 'right',
            width: 60,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [p.settings.DigitsAfterDecimalPoint]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            field: 'Distribution',
            title: 'Nutrients_Pages_Popups__Distribution',
            width: 80,
            filterable: true
          }),
          new ApDynGridGroupColumnConfig({
            title: this._getUnitWithLabelKey(this.planningEntryService.summary.Product_Id, 'Global__Product'),
            groupColumns: [
              new ApDynGridPropertyColumnConfig({
                field: 'Product',
                filterable: true,
                title: 'Global__Product',
                width: 100
              }),
              new ApDynGridPropertyColumnConfig({
                field: 'Amount',
                filterable: true,
                filterType: FilterType.NUMBER,
                title: 'Global__Average',
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                field: 'Min',
                filterable: true,
                filterType: FilterType.NUMBER,
                title: 'Global__MinShort',
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }],
                tooltip: (dataItem) => dataItem['ErrorMessage'],
              }),
              new ApDynGridPropertyColumnConfig({
                field: 'Max',
                filterable: true,
                filterType: FilterType.NUMBER,
                title: 'Global__MaxShort',
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
            ]
          }),
          new ApDynGridGroupColumnConfig({
            title: 'Global__Average_Symbol_Nutrient_kg_ha',
            groupColumns: [
              new ApDynGridPropertyColumnConfig({
                title: this.elementService.GetElementStringByString(ApElementType.P),
                field: 'P',
                filterable: true,
                filterType: FilterType.NUMBER,
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: this.elementService.GetElementStringByString(ApElementType.K),
                field: 'K',
                filterable: true,
                filterType: FilterType.NUMBER,
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: this.elementService.GetElementStringByString(ApElementType.Mg),
                field: 'Mg',
                filterable: true,
                filterType: FilterType.NUMBER,
                cssClass: 'right',
                width: 60,
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
              new ApDynGridPropertyColumnConfig({
                title: 'Nutrients__CaO',
                field: 'CaO',
                filterable: true,
                filterType: FilterType.NUMBER,
                width: 60,
                cssClass: 'right',
                columnLoading: 'ColumnLoading',
                pipes: [{
                  pipe: this.roundNumericPipe,
                  args: [p.settings.DigitsAfterDecimalPoint]
                }]
              }),
            ],
            hide: true
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Nutrients__FertilizationDate',
            field: 'Appl_Date',
            format: p.language.DateFormat,
            filterable: true,
            filterType: FilterType.DATE,
            width: 80
          }),
          new ApDynGridGroupColumnConfig({
            title: 'agrioptions_resources',
            hide: true,
            groupColumns: [
              new ApDynGridPropertyColumnConfig({
                field: 'Machine.NameWithSign',
                title: 'Base__Engine',
                width: 100,
                hide: true,
              }),
              new ApDynGridPropertyColumnConfig({
                field: 'Instrument.Name',
                title: 'AgriConnect_Ini__Tool',
                width: 100,
                hide: true
              }),
              new ApDynGridPropertyColumnConfig({
                field: 'Driver.ShortFullName',
                title: 'Docu_Ini__Motorist',
                width: 100,
                hide: true
              }),
            ]
          }),
          new ApDynGridEditColumnConfig({
            data: [
              {
                text: 'Global__Edit',
                callback: ApUtilService.createEventEmitter(this._onEdit.bind(this), this._subscriptions),
                disabled: (dataItem: any) => dataItem.ColumnLoading
              },
              {
                text: 'Global__Delete',
                callback: ApUtilService.createEventEmitter(this._onDelete.bind(this), this._subscriptions),
                disabled: (dataItem: any) => dataItem.ColumnLoading
              }
            ]
          })
        ];
      })
    );
  }

  public getItems(): Observable<any> {
    this._updatedPlanningEntries = [];
    const selectedFieldsId = this.planningEntryService.summary.Selected_Fields.map(x => x.Id as IGuid);
    this._selectedGridFields.next([...this.fieldStore.getFieldsById(selectedFieldsId)]);
    this._calculatePlanningRaster();
    this._generateOverviewStatistic([]);
    return combineLatest([
      this._selectedGridFields,
      this.nutrientPlanningStore.Listen(s => s.fieldNutrientPlanningStatistic),
      this.settingsStore.FirstSetting$
    ]).pipe(
      map(([selectedGridFields, nutrientPlanningStatistics, settings]) => {
        const ids = selectedGridFields.map(x => x.Id as IGuid);
        const fields = this.fieldStore.getFieldsById(ids);
        return fields.map(field => this._generateGridItem(field, nutrientPlanningStatistics, settings));
      })
    );
  }

  //#region "Burger menu logic"

  private _onDelete(dataItem: any): void {
    if (this.planningEntryService.summary.Nutrient_Planing_Data.some(x => x.Plan_Id === dataItem.Plan_Id)) {
      this.planningEntryService.summary.Nutrient_Planing_Data =
        this.planningEntryService.summary.Nutrient_Planing_Data.filter(x => x.Plan_Id !== dataItem.Plan_Id);
    }
    if (this._updatedPlanningEntries.some(x => x.PlanId === dataItem.Plan_Id)) {
      this._updatedPlanningEntries = this._updatedPlanningEntries.filter(x => x.PlanId !== dataItem.Plan_Id);
    }
    if (this.planningEntryService.summary.Selected_Fields.some(x => x.Id === dataItem.Field_Id)) {
      const filteredFields = this.planningEntryService.summary.Selected_Fields.filter(x => x.Id !== dataItem.Field_Id);
      this.planningEntryService.summary.Selected_Fields = filteredFields;
      const filteredSelectedGridFields = this._selectedGridFields.getValue().filter(x => filteredFields.some(y => y.Id === x.Id));
      this._selectedGridFields.next(filteredSelectedGridFields);
    }
    this.nutrientPlanningStore.removeNutrientPlanningStatisticFromStore(dataItem.Plan_Id);
    if (this.planningEntryService.summary.Nutrient_Planing_Data.length === 0) {
      this._goBackToSelection.emit();
    }
  }

  private _onEdit(dataItem: any): void {
    const nutrientAmount = dataItem[ApElementType[dataItem.Element]] ?? 0;
    const remainDemands = this.planningEntryService.getRemainDemands(dataItem.RbStatistic);
    const leadNutrientRemainDemandType = remainDemands.find(x => x.ElementType === dataItem.Element);
    const remainDemandTypeAmount = this.elementService.CalculateElementOxidValueByElementType(
      leadNutrientRemainDemandType.Amount === '-' ? 0 : leadNutrientRemainDemandType.Amount,
      leadNutrientRemainDemandType.ElementType
    );
    const recentFormData: EditPlanningDataFormType = {
      GoodsAmount: null,
      NutrientAmount: null,
      PercentAmount: null,
      ApplMin: this.planningEntryService.summary?.User_Appl_Min ?? null,
      ApplMax: this.planningEntryService.summary?.User_Appl_Max ?? null,
      ApplConst: this.planningEntryService.summary?.User_Appl_Const ?? null
    };
    if (this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(dataItem.Unit)) {
      recentFormData.NutrientAmount = nutrientAmount;
      recentFormData.GoodsAmount = dataItem.Amount;
    } else {
      let percentAmount = this.planningEntryService.summary.Amount_Percent;
      if (remainDemandTypeAmount > 0) {
        percentAmount = ((remainDemandTypeAmount - nutrientAmount) / remainDemandTypeAmount) * 100;
        percentAmount = 100 - percentAmount;
        percentAmount = percentAmount <= 0 ? 100 : percentAmount;
      }
      recentFormData.PercentAmount = percentAmount;
    }
    const historyUpdateValues = this._updatedPlanningEntries.find(x => x.PlanId === dataItem.Plan_Id);
    if (historyUpdateValues) {
      recentFormData.ApplMin = historyUpdateValues.ApplMin;
      recentFormData.ApplMax = historyUpdateValues.ApplMax;
      recentFormData.ApplConst = historyUpdateValues.ApplConst;
    }
    this.modalDialogStore.setModalDialogData({
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: 'apply',
          text: 'Global__Ok',
          primary: true,
          disable: ModalDialogButtonDisable.FormInvalid,
        }
      ],
      show: true,
      title: this.translationStore.FindTranslationForSelectedLanguage('Docu_Ini__Edit'),
      formConfig: {
        fieldSets: this._getFieldSetForEdit(dataItem, this._updateFormValuesBehavior),
        validators: this._getValidatorsForFieldSetForEdit(dataItem),
        width: 500,
        values: ObjectFactory.Copy(recentFormData)
      }
    } as IModalDialogData);
    const listenFormValuesSubscription = this.modalDialogStore.listenChanges()
      .subscribe(values => this._onFormChanges(dataItem, values, recentFormData));
    const resultSubscription = this.modalDialogStore.Listen(s => s.result)
      .subscribe((result) => {
        const resultKey = result?.key;
        if (resultKey === 'apply' || resultKey === 'cancel' || resultKey === '') {
          if (resultKey === 'apply') {
            const formValues = result?.formValues as EditPlanningDataFormType;
            if (!formValues) {
              return;
            }
            this._onFormConfirmed(dataItem, formValues, recentFormData);
          }
          this._updateFormValuesBehavior.next(null);
          this.modalDialogStore.setFormValues(null);
          listenFormValuesSubscription.unsubscribe();
          resultSubscription.unsubscribe();
        }
      });
  }

  private _onFormChanges(dataItem: any, values: any, recentFormData: EditPlanningDataFormType): void {
    if (this._isLocked || !values || Object.keys(values).length === 0) {
      return;
    }
    const formValues = values as EditPlanningDataFormType;
    if (!formValues || ObjectFactory.Equal(recentFormData, formValues)) {
      return;
    }
    if (formValues.PercentAmount !== undefined && recentFormData.PercentAmount !== formValues.PercentAmount) {
      recentFormData.NutrientAmount = formValues.NutrientAmount = null;
      recentFormData.GoodsAmount = formValues.GoodsAmount = null;
      recentFormData.PercentAmount = formValues.PercentAmount;
    }
    if (formValues.NutrientAmount !== undefined && recentFormData.NutrientAmount !== formValues.NutrientAmount) {
      recentFormData.NutrientAmount = formValues.NutrientAmount;
      recentFormData.PercentAmount = formValues.PercentAmount = null;
      const elementNutrientAmount = this.elementService.CalculateToElementByGivenValue(
        formValues.NutrientAmount, dataItem.Element, this._settings.IsOxidFarm, this._settings.IsOxidFarm);
      recentFormData.GoodsAmount = formValues.GoodsAmount = this.nutrientService.convertNutrientToGoods(
        dataItem.Product_Id,
        dataItem.Element,
        elementNutrientAmount);
    }
    if (formValues.GoodsAmount !== undefined && recentFormData.GoodsAmount !== formValues.GoodsAmount) {
      recentFormData.GoodsAmount = formValues.GoodsAmount;
      recentFormData.PercentAmount = formValues.PercentAmount = null;
      recentFormData.NutrientAmount = formValues.NutrientAmount = this.nutrientService.convertGoodsToNutrient(
        dataItem.Product_Id, dataItem.Element, formValues.GoodsAmount, this._settings.IsOxidFarm);
    }
    if (formValues.ApplConst !== undefined
      && recentFormData.ApplConst !== formValues.ApplConst
      && dataItem.Operation_Mode === ApOperationMode.Const) {
      recentFormData.ApplConst = formValues.ApplConst;
      recentFormData.ApplMax = formValues.ApplMax = formValues.ApplConst;
      recentFormData.ApplMin = formValues.ApplMin = formValues.ApplConst;
    }
    this._isLocked = true;
    this._updateFormValuesBehavior.next(formValues);
    setTimeout(() => this._isLocked = false, 10);
  }

  private _onFormConfirmed(dataItem: any, formValues: EditPlanningDataFormType, recentFormData: EditPlanningDataFormType): void {
    let nutrientAmountInElement: number | null = null;
    if (formValues.NutrientAmount) {
      nutrientAmountInElement = this.elementService.CalculateToElementByGivenValue(
        formValues.NutrientAmount, dataItem.Element, this._settings.IsOxidFarm, this._settings.IsOxidFarm);
    }
    const applConst = formValues.ApplConst ?? recentFormData.ApplConst;
    const singlePlanData: PlanningEntryDataToEdit = {
      PlanId: dataItem.Plan_Id,
      NutrientAmount: nutrientAmountInElement ?? applConst,
      PercentAmount: formValues.PercentAmount,
      ApplMin: formValues.ApplMin,
      ApplMax: formValues.ApplMax,
      ApplConst: applConst
    };
    this._recalculateRasterSinglePlan(singlePlanData);
    const existingUpdatedPlanningEntry = this._updatedPlanningEntries.find(x => x.PlanId === singlePlanData.PlanId);
    if (existingUpdatedPlanningEntry) {
      existingUpdatedPlanningEntry.ApplMin = singlePlanData.ApplMin;
      existingUpdatedPlanningEntry.ApplMax = singlePlanData.ApplMax;
      existingUpdatedPlanningEntry.ApplConst = singlePlanData.ApplConst;
    } else {
      this._updatedPlanningEntries.push({
        PlanId: singlePlanData.PlanId,
        ApplMin: singlePlanData.ApplMin,
        ApplMax: singlePlanData.ApplMax,
        ApplConst: singlePlanData.ApplConst
      });
    }
  }

  private _recalculateRasterSinglePlan(singlePlanToEdit: PlanningEntryDataToEdit): void {
    if (!singlePlanToEdit) {
      return;
    }
    const singlePlan = this.planningEntryService.summary.Nutrient_Planing_Data.find(x => x.Plan_Id === singlePlanToEdit.PlanId);
    if (!singlePlan) {
      return;
    }
    const calculationValues = this._getValuesForCalculation({
      ProductId: this.planningEntryService.summary.Product_Id,
      ApplConst: singlePlanToEdit.ApplConst,
      UnitId: this._getUnitIdAccordingToPercentAmount(singlePlanToEdit.PercentAmount),
      ElementId: singlePlan.Element,
      Amount: singlePlanToEdit.PercentAmount ?? singlePlanToEdit.NutrientAmount,
      ApplMin: singlePlanToEdit.ApplMin,
      ApplMax: singlePlanToEdit.ApplMax
    });
    singlePlan.Ready_Calculated = false;
    singlePlan.Unit = calculationValues.UnitId;
    singlePlan.Appl_Rate = calculationValues.ApplRate;
    singlePlan.Appl_Min = calculationValues.ApplMin;
    singlePlan.Appl_Max = calculationValues.ApplMax;
    singlePlan.Appl_Const = calculationValues.ApplConst;
    singlePlan.Statistic = null;
    singlePlan.RbStatistic = null;
    const nutrientAmount = this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(calculationValues.UnitId)
      ? calculationValues.ApplRate
      : 0;
    const percentAmount = !this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(calculationValues.UnitId)
      ? calculationValues.ApplRate
      : 0;
    this._canSave.next(false);
    this.nutrientPlanningStore.startNutrientPlanning(
      singlePlan.Plan_Id,
      singlePlan.Field_Geom_Id,
      singlePlan.Element,
      this.planningEntryService.summary.Product_Id,
      singlePlan.Operation_Mode,
      nutrientAmount,
      percentAmount,
      calculationValues.ApplMin,
      calculationValues.HasApplMin,
      calculationValues.ApplMax,
      calculationValues.HasApplMax,
      singlePlan.Appl_Mode === ApApplicationMode.Variable ? calculationValues.ApplConst : 0,
      singlePlan.Appl_Mode === ApApplicationMode.Constant ? calculationValues.ApplConst : 0
    );
  }

  private _getValuesForCalculation(values: CalculationValuesInput, withOxide: boolean = false): CalculationValuesOutput {
    let applConst = 0;
    if (!!values.ApplConst) {
      applConst = this.nutrientService.convertGoodsToNutrient(
        values.ProductId,
        values.ElementId,
        values.ApplConst,
        withOxide);
    }
    let applMin = 0;
    let applMinHasValue = false;
    if (!!values.ApplMin) {
      applMin = this.nutrientService.convertGoodsToNutrient(
        values.ProductId,
        values.ElementId,
        values.ApplMin,
        withOxide);
      applMinHasValue = true;
    }
    let applMax = 0;
    let applMaxHasValue = false;
    if (!!values.ApplMax) {
      applMax = this.nutrientService.convertGoodsToNutrient(
        values.ProductId,
        values.ElementId,
        values.ApplMax,
        withOxide);
      applMaxHasValue = true;
    }
    const applUnitId: number = this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(values.UnitId)
      ? this.planningEntryService.globalUnitKgPerHa.Id
      : this.planningEntryService.globalUnitPercent.Id;
    let applRate: number = values.Amount;
    if (this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(values.UnitId)) {
      applRate = this.elementService.CalculateToElementByGivenValue(
        applRate,
        values.ElementId,
        withOxide,
        withOxide);
    }
    return {
      UnitId: applUnitId,
      ApplRate: applRate,
      ApplConst: applConst,
      ApplMin: applMin,
      ApplMax: applMax,
      HasApplMin: applMinHasValue,
      HasApplMax: applMaxHasValue
    };
  }

  private _getFieldSetForEdit(dataItem: any, values$: Observable<EditPlanningDataFormType>): ApDynformsConfigFieldset[] {
    const fieldSets = [];
    if (dataItem.Operation_Mode !== ApOperationMode.Const) {
      // Nutrient, Goods, Percent
      fieldSets.push(new ApDynformsConfigFieldset({
        key: 'Global__Amount',
        legend: 'Global__Agronomy',
        useMaxWidth: true,
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'NutrientAmount',
            label: this.planningEntryService.getElementLabelTranslation(),
            value$: values$.pipe(
              filter(x => this._isNotNullOrUndefined(x?.NutrientAmount)),
              map(x => x.NutrientAmount)
            ),
            infoText: 'Global_Average_Amount_Nutrients_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            decimals: this._settings?.DigitsAfterDecimalPoint ?? 0,
            disabled: dataItem.Appl_Mode === ApApplicationMode.Constant
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'GoodsAmount',
            label: this._getUnitWithLabelKey(dataItem.Product_Id, 'Nutrients__Average_Amount_Goods'),
            value$: values$.pipe(
              filter(x => this._isNotNullOrUndefined(x?.GoodsAmount)),
              map(x => x.GoodsAmount)
            ),
            infoText: 'Global_Average_Amount_Quantity_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required', 'Text_ValueInvalid'],
            min: 0,
            decimals: this._settings?.DigitsAfterDecimalPoint ?? 0,
            disabled: dataItem.Appl_Mode === ApApplicationMode.Constant
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'PercentAmount',
            label: 'Nutrients__Percentage_Remaining_Needs_2_%',
            value$: values$.pipe(
              filter(x => this._isNotNullOrUndefined(x?.PercentAmount)),
              map(x => x.PercentAmount)
            ),
            infoText: 'Global_Percentage_Amount_Remaining_Needs_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            max: 100,
            decimals: 1,
            disabled: dataItem.Appl_Mode === ApApplicationMode.Constant
          })
        ]
      }));
    }
    fieldSets.push(new ApDynformsConfigFieldset({
      key: 'Min_Max_Const',
      legend: 'Global__Planning_Min_Max_Const',
      useMaxWidth: true,
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'ApplMin',
          label: this._getUnitWithLabelKey(dataItem.Product_Id, 'Global__Minimum_Goods'),
          value$: values$.pipe(
            filter(x => this._isNotNullOrUndefined(x?.ApplMin)),
            map(x => x.ApplMin)
          ),
          formErrors: ['Text_ValueInvalid'],
          infoText: 'Global_Minimum_Goods_Not_Fallen_Short_All_Fields',
          min: 0,
          step: 1,
          decimals: 1,
          disabled: dataItem.Appl_Mode === ApApplicationMode.Constant
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ApplMax',
          label: this._getUnitWithLabelKey(dataItem.Product_Id, 'Global__Maximum_Goods'),
          value$: values$.pipe(
            filter(x => this._isNotNullOrUndefined(x?.ApplMax)),
            map(x => x.ApplMax)
          ),
          formErrors: ['Text_ValueInvalid'],
          infoText: 'Global_Maximum_Goods_Not_Exceeded_All_Fields',
          min: 0,
          step: 1,
          decimals: 1,
          disabled: dataItem.Appl_Mode === ApApplicationMode.Constant
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ApplConst',
          label: this._getUnitWithLabelKey(dataItem.Product_Id, 'Global__Const_Goods'),
          value$: values$.pipe(
            filter(x => this._isNotNullOrUndefined(x?.ApplConst)),
            map(x => x.ApplConst)
          ),
          infoText: 'Global_Constant_Amount_Applied_Field_Absence_Gps',
          formErrors: ['Text_ValueInvalid'],
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required',
              always: true
            })
          ],
          min: 0,
          decimals: 1,
          disabled: dataItem.Appl_Mode !== ApApplicationMode.Constant
        })
      ],
    }));
    return fieldSets;
  }

  private _getValidatorsForFieldSetForEdit(dataItem: any): ApDynformsValidator<ValidatorFn>[] {
    if (dataItem.Appl_Mode === ApApplicationMode.Constant) {
      return [
        new ApDynformsValidator({
          validator: ApNumberRangeValidator.ValidateNumberRange('ApplMin', 'ApplConst'),
          errorKey: 'Text_ValueInvalid'
        }),
        new ApDynformsValidator({
          validator: ApNumberRangeValidator.ValidateNumberRange('ApplConst', 'ApplMax'),
          errorKey: 'Text_ValueInvalid'
        })
      ];
    } else {
      return [
        new ApDynformsValidator({
          validator: (frm: FormGroup): { mismatch: boolean } | null => {
            const userApplRate = frm.get('NutrientAmount')?.value;
            const amountNutrients = frm.get('GoodsAmount')?.value;
            const amountPercent = frm.get('PercentAmount')?.value;
            if (!userApplRate && !amountNutrients && !amountPercent) {
              return {mismatch: true};
            }
            return null;
          },
          errorKey: 'Settings__Msg_Vali_Value_Required'
        }),
        new ApDynformsValidator({
          validator: ApNumberRangeValidator.ValidateNumberRange('ApplMin', 'GoodsAmount'),
          errorKey: 'Text_ValueInvalid'
        }),
        new ApDynformsValidator({
          validator: ApNumberRangeValidator.ValidateNumberRange('GoodsAmount', 'ApplMax'),
          errorKey: 'Text_ValueInvalid'
        }),
        new ApDynformsValidator({
          validator: ApNumberRangeValidator.ValidateNumberRange('ApplMin', 'ApplMax'),
          errorKey: 'Text_ValueInvalid'
        })
      ];
    }
  }

  //#endregion "Burger menu logic"

  //#region "private Methods"

  private _generateGridItem(field: IField, nutrientPlanningStatistics: IFieldNutrientPlanningStatistic[], settings: ISettings): any {
    const geom = this.fieldStore.getCurrentFieldGeom(field);
    const fieldGeomId = geom?.Id;
    const planning = this.planningEntryService.summary.Nutrient_Planing_Data.Find(x => x.Field_Geom_Id === fieldGeomId);
    const applRate = this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(this.planningEntryService.summary.User_Appl_Unit)
      ? this.planningEntryService.summary.Amount_Nutrient
      : this.planningEntryService.summary.Amount_Percent;
    const rbStatistic = this.planningEntryService.summary.Selected_Fields.find(x => x.Id === field.Id)?.RbStatistic;
    const summaryElement = this.planningEntryService.summary.Element;
    const fertilizer = this.fertilizerStore.getFertilizer(this.planningEntryService.summary.Product_Id);
    let minValue = 0;
    let maxValue = 0;
    let amountValue = 0;
    let p = 0;
    let k = 0;
    let mg = 0;
    let cao = 0;
    let columnLoading = true;
    let statistic = planning?.Statistic ?? nutrientPlanningStatistics?.FindAll(x => x.FieldGeomId === fieldGeomId);
    let errorMessage = null;
    let status = planning?.Status;
    if (statistic.length === 5) {
      const elementIndex = summaryElement - 1;
      const elementStatistic = statistic[elementIndex];
      minValue = this.nutrientService.convertNutrientToGoods(this.planningEntryService.summary.Product_Id, summaryElement, elementStatistic.Min);
      maxValue = this.nutrientService.convertNutrientToGoods(this.planningEntryService.summary.Product_Id, summaryElement, elementStatistic.Max);
      amountValue = this.nutrientService.convertNutrientToGoods(this.planningEntryService.summary.Product_Id, summaryElement, elementStatistic.Mean);
      planning.Appl_Min = this.roundNumericService.roundAsNumber(elementStatistic.Min, settings.DigitsAfterDecimalPoint);
      planning.Appl_Max = this.roundNumericService.roundAsNumber(elementStatistic.Max, settings.DigitsAfterDecimalPoint);
      planning.Appl_Rate = this.roundNumericService.roundAsNumber(elementStatistic.Mean, settings.DigitsAfterDecimalPoint);
      status = planning.Status;
      if (this.planningEntryService.summary.Product_Id === 0) {
        const st = nutrientPlanningStatistics.Find(x => x?.FieldGeomId === fieldGeomId && x?.Band === elementIndex) as IFieldNutrientPlanningStatistic;
        switch (elementIndex) {
          case 0:
            p = statistic[0].Mean;
            break;
          case 1:
            k = statistic[1].Mean;
            break;
          case 2:
            mg = statistic[2].Mean;
            break;
          case 3:
            cao = statistic[3].Mean;
            break;
        }
        statistic = [st];
      } else {
        p = statistic[0].Mean;
        k = statistic[1].Mean;
        mg = statistic[2].Mean;
        cao = statistic[3].Mean;
      }
      planning.Statistic = statistic;
      columnLoading = false;
    } else {
      if (statistic.length > 0 && statistic[0].ErrorText !== null) {
        columnLoading = false;
        errorMessage = statistic[0].ErrorText;
        status = 2;
      }
    }
    const applicationMode = this.applicationModeService.GetApplicationModeById(planning.Appl_Mode);
    const distribution = this.applicationModeService.GetApplicationModeTranslation(applicationMode);
    return {
      Status: status,
      StatusClass: status === 1 ? 'ap-status-green' : status === 2 ? 'ap-status-yellow' : 'ap-status-red',
      StatusDesc: status === 1 ? 'Global__Active' : status === 2 ? 'Global__Only_Constant_Planning_Possible' : errorMessage,
      ErrorMessage: errorMessage,
      Id: field.Id,
      Plan_Id: planning.Plan_Id,
      Appl_Min: this.planningEntryService.summary?.User_Appl_Min ?? minValue,
      Appl_Max: this.planningEntryService.summary?.User_Appl_Max ?? maxValue,
      Note: this.planningEntryService.summary.Title,
      Appl_Rate: applRate,
      Appl_Const: planning?.Appl_Const,
      Appl_Mode: planning?.Appl_Mode,
      Appl_Date: this.planningEntryService.summary.User_Appl_Date,
      Product_Id: this.planningEntryService.summary.Product_Id,
      Element: planning?.Element,
      Operation_Mode: planning?.Operation_Mode,
      Unit: planning?.Unit,
      Driver: planning?.Driver,
      Machine: planning?.Machine,
      Instrument: planning?.Instrument,
      GeomSVG: geom,
      Geom: geom?.Geom,
      Geom_Id: geom?.Id,
      Area: geom?.AreaHa,
      Field_Geom_Id: geom?.Id,
      Statistic: statistic,
      RbStatistic: rbStatistic,
      ColumnLoading: columnLoading,
      Distribution: distribution,
      FarmId: this.loginStore.SelectedFarmId,
      Field_Id: field.Id,
      Nr: field.FieldNumber,
      TNr: field.FieldSubnumber,
      Name: field.FieldName,
      NrTNr: this.fieldStore.getFieldNumberAndSubNumber(field),
      Amount: amountValue,
      Min: minValue,
      Max: maxValue,
      P: this.nutrientService.isOxidFarm()
        ? this.elementService.CalculateElementOxidValueByGivenElementValue(p, ApElementOxydTypeEnum.P)
        : p,
      K: this.nutrientService.isOxidFarm()
        ? this.elementService.CalculateElementOxidValueByGivenElementValue(k, ApElementOxydTypeEnum.K)
        : k,
      Mg: this.nutrientService.isOxidFarm()
        ? this.elementService.CalculateElementOxidValueByGivenElementValue(mg, ApElementOxydTypeEnum.Mg)
        : mg,
      CaO: cao,
      Crop: field.FieldCrops && field.FieldCrops[0]
        ? this.cropTypeStore.GetCropTypeName(field.FieldCrops[0].CroptypeId)
        : '-',
      CropTypeId: field.FieldCrops && field.FieldCrops[0]
        ? field.FieldCrops[0].CroptypeId
        : null,
      SecondCrop: field.FieldCrops && field.FieldCrops[0]
        ? this.cropTypeStore.GetCropTypeName(field.FieldCrops[0].CroptypeSecondId)
        : '-',
      SecondCropTypeId: field.FieldCrops && field.FieldCrops[0]
        ? field.FieldCrops[0].CroptypeSecondId
        : null,
      Product: this.fertilizerService.getFertilizerProductName(fertilizer),
      Version: 1,
      Factor: 1,
      Valid_From: null,
      Valid_To: null,
      BookedAt: null,
      BookedBy: null,
      Croptype_Id: null,
      Exported_At: null,
      Exported_By: null,
      Exported_Format: null,
      Croptype_Second_Id: null,
      CreatedAt: null,
      CreatedBy: null,
      ChangedAt: null,
      ChangedBy: null,
      DeletedAt: null,
      DeletedBy: null
    };
  }

  private _onSelectionChange(currentSelected: any[]): void {
    const currentStep = this.planningEntryService.activeWizardStep.getValue();
    if (currentStep === ApNutrientWizardStep.Overview) {
      let fieldIds: string[];
      if (!currentSelected || currentSelected.length <= 0) {
        fieldIds = this._dynComponent.dynGrid.items.map(x => x.Field_Id?.toString());
      } else {
        fieldIds = currentSelected.map(x => x.Field_Id?.toString());
      }
      this.fieldStore.changeSelectedField(fieldIds);
      this._generateOverviewStatistic(currentSelected);
    }
  }

  private _calculatePlanningRaster(): void {
    this._canSave.next(false);
    this.nutrientPlanningStore.clearNutrientPlanningStatistic();
    this._createNutrientPlaningData();
    this.planningEntryService.summary.Nutrient_Planing_Data.forEach(nutrientPlaning => {
      this.nutrientPlanningStore.startNutrientPlanning(
        nutrientPlaning.Plan_Id,
        nutrientPlaning.Field_Geom_Id,
        nutrientPlaning.Element,
        this.planningEntryService.summary.Product_Id,
        nutrientPlaning?.Appl_Mode === ApApplicationMode.Constant ? ApOperationMode.Const : nutrientPlaning.Operation_Mode,
        this.planningEntryService.isKgPerHOrM3PerHOrTPerHUnit(nutrientPlaning?.Unit) ? nutrientPlaning.Appl_Rate : 0,
        this.planningEntryService.summary.User_Appl_Unit === this.planningEntryService.globalUnitPercent.Id ? nutrientPlaning.Appl_Rate : 0,
        this.planningEntryService.summary.User_Appl_Min !== null ? nutrientPlaning.Appl_Min : 0,
        this.planningEntryService.summary.User_Appl_Min !== null,
        this.planningEntryService.summary.User_Appl_Max !== null ? nutrientPlaning.Appl_Max : 0,
        this.planningEntryService.summary.User_Appl_Max !== null,
        nutrientPlaning.Appl_Mode === ApApplicationMode.Variable ? nutrientPlaning.Appl_Const : 0,
        nutrientPlaning.Appl_Mode === ApApplicationMode.Constant ? nutrientPlaning.Appl_Const : 0
      );
    });
  }

  private _createNutrientPlaningData(): void {
    this.planningEntryService.summary.Nutrient_Planing_Data = [];
    const calculationValues = this._getValuesForCalculation({
      UnitId: this.planningEntryService.summary.User_Appl_Unit,
      ElementId: this.planningEntryService.summary.Element,
      ProductId: this.planningEntryService.summary.Product_Id,
      Amount: this.planningEntryService.summary.Amount_Percent ?? this.planningEntryService.summary.Amount_Nutrient,
      ApplConst: this.planningEntryService.summary.User_Appl_Const,
      ApplMax: this.planningEntryService.summary.User_Appl_Max,
      ApplMin: this.planningEntryService.summary.User_Appl_Min
    });
    if (calculationValues.ApplRate === null) {
      console.warn('Calculation result of an appl rate is wrong.', this.planningEntryService.summary);
      calculationValues.ApplRate = 0;
    }
    // Für konstante Planungen (organisch und mineralisch) wird const und applRate gleich gesetzt
    // APV49-1515: user_appl_rate = user_appl_min = user_appl_max=user_appl_const = Menge * Gehalt(Leitnährstoff)
    if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.Const) {
      // Einzelplanung: Bei konstant alle appl Werte gleichsetzen (Nährstoff)
      calculationValues.ApplConst = calculationValues.ApplMax = calculationValues.ApplMin = calculationValues.ApplRate;
      // Summary: Bei konstant alle user_appl Werte gleichsetzen (Ware)
      this.planningEntryService.summary.User_Appl_Const = this.planningEntryService.summary.User_Appl_Min =
        this.planningEntryService.summary.User_Appl_Max = this.planningEntryService.summary.Amount_Goods;
    }
    this.planningEntryService.summary.Selected_Fields.forEach((selectedField: any): void => {
      const geom = this.fieldStore.getFieldGeomById(selectedField.FieldGeomId.toString());
      const field = this.fieldStore.getFieldById(geom.FieldId.toString());
      const campaignYearRange = this.campaignYearService.getCampaignYearByDate(new Date(this.planningEntryService.summary.User_Appl_Date));
      const cropRotation = this.cropService.getCropRotationByYear(field.Id, campaignYearRange.CampaignYear);
      let operationMode = ApOperationMode.Const;
      let applicationMode = ApApplicationMode.Constant;
      let status = 2; // default: Gelb
      if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.AppMap && selectedField?.Status === 1) {
        const rb = this.rbStore.getRbStatisticByFieldGeomId(selectedField.FieldGeomId.toString());
        if (rb !== null) {
          const avgRB2 = this.nutrientStatisticService.getAverageRb(this.planningEntryService.summary.Element + 3, rb);
          if (avgRB2 > this.roundNumericPipe.transform(0, 0)) {
            operationMode = ApOperationMode.AppMap;
            applicationMode = ApApplicationMode.Variable;
            status = 1;
          }
        }
      }
      const fieldGeomIdPlanId = this._fieldGeomIdPlanId.Find(x => x.FieldGeomId === selectedField.FieldGeomId);
      const nutrientPlaning: INutrientPlaningData = {
        Id: fieldGeomIdPlanId !== null ? fieldGeomIdPlanId.PlanId : ApGuidUtil.generateNewGuid(),
        Summary_Id: this.planningEntryService.summary.Id,
        Unit: calculationValues.UnitId,
        Appl_Max: calculationValues.ApplMax,
        Appl_Min: calculationValues.ApplMin,
        Appl_Const: calculationValues.ApplConst,
        Appl_Rate: calculationValues.ApplRate,
        Appl_Mode: applicationMode,
        Element: this.planningEntryService.summary.Element,
        Appl_Date: this.planningEntryService.summary.User_Appl_Date,
        Field_Geom_Id: selectedField.FieldGeomId,
        Field_Id: field.Id,
        Geom: geom.Geom,
        Operation_Mode: operationMode,
        Field: field,
        Status: status,
        Driver: this.planningEntryService.summary.Driver,
        Machine: this.planningEntryService.summary.Machine,
        Instrument: this.planningEntryService.summary.Instrument,
        Factor: 1,
        Max: 0,
        Ready: true,
        Ready_Calculated: false,
        Note: '',
        Plan_Id: null,
        Croptype_Id: this._getCropId(cropRotation, 'MainCrop'),
        Croptype_Second_Id: this._getCropId(cropRotation, 'SecondCrop'),
        Statistic: null,
        T_End: null,
        T_Start: null,
        Valid_From: null,
        Valid_To: null,
        RbStatistic: null
      };
      nutrientPlaning.Plan_Id = nutrientPlaning.Id;
      const fieldGeomAndPlanId: FieldGeomAndPlanId = {
        FieldGeomId: nutrientPlaning.Field_Geom_Id,
        PlanId: nutrientPlaning.Plan_Id
      };
      if (!this._fieldGeomIdPlanId.Contains(fieldGeomAndPlanId)) {
        this._fieldGeomIdPlanId.Add(fieldGeomAndPlanId);
      }
      this.planningEntryService.summary.Nutrient_Planing_Data.Add(nutrientPlaning);
    });
  }

  private _getUnitIdAccordingToPercentAmount(percentAmount: number | null): number {
    let unitId: number;
    if (percentAmount) {
      unitId = this.planningEntryService.globalUnitPercent.Id;
    } else {
      const groupId = this.planningEntryService.summary.Group_Id;
      const productId = this.planningEntryService.summary.Product_Id;
      unitId = this.planningEntryService.getM3HaOrTHaOrKgHaUnitIdForOrganic(groupId, productId);
    }
    return unitId;
  }

  private _listenNutrientPlanningStatistics(statistic: IFieldNutrientPlanningStatistic[]): void {
    if (!statistic || statistic.length <= 0) {
      return;
    }
    const summary = this.planningEntryService.summary;
    const elementAsIndex = summary.Element - 1;
    statistic.forEach(statisticItem => {
      if (statisticItem.ErrorText === null) {
        if (summary.Nutrient_Planing_Data.Find(x => x.Plan_Id === statisticItem.PlanId) !== null) {
          const pl = summary.Nutrient_Planing_Data.Find(x => x.Plan_Id === statisticItem.PlanId);
          pl.Ready_Calculated = true;
          if (elementAsIndex === statisticItem.Band) {
            summary.Nutrient_Planing_Data.Find(x => x.Plan_Id === statisticItem.PlanId).Max = statisticItem.Max;
          }
        }
      } else {
        const planningData = summary.Nutrient_Planing_Data.Find(x => !!x && !!statisticItem && x.Field_Geom_Id === statisticItem.FieldGeomId);
        if (!!planningData) {
          planningData.Ready_Calculated = true;
        }
        const readySinglePlans = summary.Nutrient_Planing_Data.FindAll(x => !!x && x.Ready);
        if (readySinglePlans.TrueForAll(x => x.Ready_Calculated)) {
          this._canSave.next(true);
        }
      }
    });
    const ready = summary.Nutrient_Planing_Data.FindAll(x => x.Ready);
    if (ready.TrueForAll(x => x.Ready_Calculated)) {
      let maxValue = 0;
      let minValue: number | undefined;
      let meanValue = 0;
      const statisticList = [];
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      const nutrient = this.nutrientManagementService.getNutrientName(elementAsIndex);
      const planIds = ready.FindAll(x => x.Ready_Calculated);
      const ids = planIds.map(x => x.Plan_Id);
      const elementStatistic = statistic.filter(x => x.Band === elementAsIndex);
      elementStatistic.forEach(object => {
        if (minValue === undefined) {
          minValue = object.Min;
        }
        maxValue = Math.max(maxValue, object.Max);
        minValue = Math.min(minValue, object.Min);
        meanValue = Math.max(meanValue, object.Mean);
      });
      for (let elementId = 1; elementId < 5; elementId++) {
        if (elementId === summary.Element) {
          statisticList.Add({
            ElementId: elementId,
            Max: maxValue,
            Min: minValue,
            Average: meanValue
          });
        } else if (summary.Product_Id > 0) {
          statisticList.Add({
            ElementId: elementId,
            Max: 0,
            Min: 0,
            Average: 0
          });
        }
      }
      this._clearMapAndLegendCompletely();
      this._generateOverviewStatistic(this._dynComponent.getSelectedItems());
      summary.Statistic = statisticList;
      this.mapStore.Legends.onNutrientPlanningChanged.emit(summary);
      this.mapStore.Layers.NutrientPlanningLayerSourceInit(currentUrl, nutrient, ids);
      this._canSave.next(true);
    }
  }

  private _generateOverviewStatistic(selectedItems: any[]): void {
    // put it on top of callstack to avoid lagging when user changes the selection
    setTimeout(() => {
      const emptyTitle = this.translationService.translate('Nutrients__StatisticsTitle');
      let items = selectedItems;
      if (!items || items.length <= 0) {
        items = this._dynComponent.items as any[];
      }
      if (!items || items.length <= 0 || items.some(x => x.ColumnLoading)) {
        this.statisticStore.setStatisticData<IPlanningWizardOverviewStatsItem>(
          [], ApStatsType.PlanningWizardOverviewStats, '', emptyTitle
        );
        return;
      }
      const statItems: IPlanningWizardOverviewStatsItem[] = items.filter(x => x?.Operation_Mode).map(item => {
        const operationMode = this.operationModesStore.Listen(s => s.data)
          .getValue()
          .find((o) => o.Id === item.Operation_Mode);
        const statistic = item.Statistic as IFieldNutrientPlanningStatistic[];
        return {
          Module: PlanningWizardOverviewModuleStats.BaseFertilization,
          OperationModeKey: operationMode?.Key ?? '',
          NContent: this.baseFertilizationService.calculateNElementByLeadElement(item?.Product_Id, statistic)?.Average ?? 0,
          Area: item.Area,
          Min: item.Min,
          Max: item.Max,
          Average: item.Amount,
          ProductId: item?.Product_Id
        };
      });
      const title = this._getTitleForStatistic(items);
      this.statisticStore.setStatisticData<IPlanningWizardOverviewStatsItem>(
        statItems, ApStatsType.PlanningWizardOverviewStats, title, emptyTitle
      );
    }, 10);
  }

  private _getTitleForStatistic(selectedItems: any[]): string {
    const totalItems = this._dynComponent.items as any[];
    const dataForTitles = totalItems.map(x => ({
      AreaHa: x.Area,
      Selected: selectedItems.some(z => z.Id === x.Id)
    }));
    return this.statisticService.generateFieldsAreaTitle(dataForTitles);
  }

  private _isNotNullOrUndefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  private _clearValues(): void {
    this._canSave.next(false);
    this._isLocked = false;
    this._selectedGridFields.next([]);
    this._updatedPlanningEntries = [];
    this._fieldGeomIdPlanId = [];
    this._clearMapAndLegendCompletely();
  }

  private _clearMapAndLegendCompletely(): void {
    this.planningEntryService.clearMapLayerAndLegend();
  }

  private _getCropId(cropRotation: CropRotationYield, crop: string): number | null {
    if (!cropRotation) {
      return null;
    }
    if (!cropRotation || !cropRotation[crop] || cropRotation[crop] === 0) {
      return null;
    }
    return cropRotation[crop];
  }

  private _getUnitWithLabelKey(productId: number, key: string): string {
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    return this.unitService.getUnitWithLabelKey(fertilizer?.Unit, key);
  }

  //#endregion "private Methods"
}
