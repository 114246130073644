// Enumeration of fixed layer z-index values.
// Usually layers can be re-ordered by drag&drop in layer menu.
// Nevertheless, some layers need a fixed z-Index to appear always on top.
export enum LayerZIndex {
  SAMPLE_FIELDS = 200,
  CROPTYPES = 201,
  SOIL_GROUPS = 202,
  N_INDEX = 203,
  N_UPTAKE = 204,
  FIELDS = 9000,
  FIELDS_DESCRIPTION = 9001,
  TASK_MANAGEMENT = 9002,
  USER_LOCATION = 9003
}
