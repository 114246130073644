import {Injectable}           from '@angular/core';
import {ApTranslationService} from '../../ap-utils/service/ap-translation.service';
import IUnit = Data.Common.IUnit;

@Injectable({providedIn: 'root'})
export class UnitService {
  constructor(private translationService: ApTranslationService) {
  }

  public getUnitWithLabelKey(unit: IUnit | null, labelKey: string): string {
    const fertilizerUnitName = this._getUnitTranslation(unit);
    const unitHa = this.translationService.translate('Global__Unit_ha');
    const translatedLableKey = this.translationService.translate(labelKey);
    return translatedLableKey + ' [' + fertilizerUnitName + '/' + unitHa + ']';
  }

  private _getUnitTranslation(unit: IUnit | null, perHa = false): string {
    let unitValue: string;
    if (unit) {
      const unitKey = unit.BaseUnit ? unit.BaseUnit.Key : unit.Key;
      unitValue = this.translationService.translate(unitKey);
    } else {
      unitValue = this.translationService.translate('Global__Unit_kg');
    }
    if (perHa) {
      return `${unitValue}/${this.translationService.translate('Global__Unit_ha')}`;
    } else {
      return unitValue;
    }
  }
}
