import {IStateStore}                        from '../../ap-interface';
import {
  ApCustomTypes,
  NetTypes,
  NFertilizationBookingsLoad,
  NFertilizationBookingsLoadSuccess,
  NFertilizationBookingsSave,
  NFertilizationBookingsUpdate,
  NFertilizationBookingsUpdateSuccess,
  NFertilizationBookPlanBook,
  NFertilizationDetailsLoadSuccess,
  NFertilizationLoadSuccess,
  NFertilizationLogfilesLoad,
  NFertilizationLogfilesLoadSuccess,
  NFertilizationLogfilesRemap,
  NFertilizationLogfilesRemapComplete,
  NFertilizationLogfilesSave,
  NFertilizationLogfilesUpdate,
  NFertilizationLogfilesUpdateSuccess,
  NFertilizationNeedValueGet,
  NFertilizationNeedValueGetSuccess,
  NFertilizationNeedValueSet,
  NFertilizationNRestrictionReportDownload,
  NFertilizationNRestrictionReportDownloadFail,
  NFertilizationNRestrictionReportDownloadSuccess,
  NFertilizationPlanBooksDelete,
  NFertilizationSummaryDelete,
  NFertilizationUnBookPlanBook,
  NPlanningCropEcN,
  NPlanningCropEcNSuccess,
  NPlanningCropEcSn,
  NPlanningCropEcSnSuccess,
  NPlanningRasterCreateJob,
  NPlanningRasterStatisticFail,
  NPlanningRasterStatisticRefresh,
  NSensorFieldDataLoad,
  NSensorFieldDataLoadSuccess,
  NSensorRasterValuesLoad,
  NSensorRasterValuesLoadSuccess
}                                           from 'invoker-transport';
import {Injectable}                         from '@angular/core';
import {Store}                              from '../index';
import {BehaviorSubject, Observable}        from 'rxjs';
import {map}                                from 'rxjs/operators';
import {ObjectFactory, SafeBehaviorSubject} from 'ts-tooling';
import * as download                        from 'downloadjs';
import INFertilizationDetail = Data.NFertilization.INFertilizationDetail;
import INFertilizationNeedValue = Data.NFertilization.INFertilizationNeedValue;
import INFertilizationPlanBook = Data.TaskManagement.INFertilizationPlanBook;
import INFertilizationSummary = Data.TaskManagement.INFertilizationSummary;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import IGuid = System.IGuid;
import ICropEcN = Data.NFertilization.ICropEcN;
import ICropEcSn = Data.NFertilization.ICropEcSn;
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;
import IRasterCreationResult = Data.Nutrients.IRasterCreationResult;
import ISensorFieldData = Data.Sensor.ISensorFieldData;
import IReportData = Agriport.Excel.Factory.InputData.NRestriction.IReportData;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface INFertilizationStore extends IStateStore<IGuid> {
  details: INFertilizationDetail[];
  planBooks: INFertilizationPlanBook[];
  fertilizationNeedValue: INFertilizationNeedValue[];
  logfiles: IDatasetFieldMap[];
  logfilesLoading: boolean;
  planBooksLoading: boolean;
  cropEcSn: ICropEcSn[];
  cropEcN: ICropEcN[];
  fieldNPlanningStatistic: IFieldNutrientPlanningStatistic[];
  sensorFieldData: ISensorFieldData[];
  sensorFieldDataLoading: boolean;
  sensorRasterValues: number[][];
  nRestrictionsReportDownloadSuccess: boolean | undefined;
}

@Injectable({providedIn: 'root'})
export class NFertilizationStore extends Store<INFertilizationStore> {
  static CropEcNeedLegend = new BehaviorSubject<{
    [key: number]: { min: number, max: number, values: number[] }[]
  }>({});
  static CropEcUptakeLegend = new BehaviorSubject<{
    [key: number]: { min: number, max: number, values: number[] }[]
  }>({});

  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      details: [],
      fertilizationNeedValue: [],
      planBooks: [],
      logfiles: [],
      logfilesLoading: false,
      planBooksLoading: false,
      cropEcSn: [],
      cropEcN: [],
      fieldNPlanningStatistic: [],
      sensorFieldData: [],
      sensorFieldDataLoading: false,
      sensorRasterValues: [],
      nRestrictionsReportDownloadSuccess: undefined
    });

    backend.registerObservable(NFertilizationLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(NFertilizationDetailsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.details, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(NFertilizationNeedValueGetSuccess).subscribe(d => {
      super.Mutate(s => s.fertilizationNeedValue, () => d.Data);
    });

    backend.registerObservable(NFertilizationBookingsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.planBooks, () => d.Data);
      super.Mutate(s => s.planBooksLoading, () => false);
    });

    backend.registerObservable(NFertilizationLogfilesLoadSuccess).subscribe((d) => {
      super.Mutate(s => s.logfiles, () => d.Data);
      super.Mutate(s => s.logfilesLoading, () => false);
    });

    backend.registerObservable(NFertilizationLogfilesUpdateSuccess).subscribe((d) => {
      if (!d.Data || d.Data.length <= 0) {
        return;
      }
      this.Mutate(s => s['logfiles'], o => {
        for (const updatedLogfile of d.Data) {
          const position = o?.FindIndex(i => !!i && !!updatedLogfile && i.Id === updatedLogfile.Id);
          if (position >= 0) {
            o[position] = updatedLogfile;
          } else {
            o.Add(updatedLogfile);
          }
        }
        return o.filter((logfile) => logfile != null);
      });
    });

    backend.registerObservable(NFertilizationBookingsUpdateSuccess).subscribe((d) => {
      if (!d.Data || d.Data.length <= 0) {
        return;
      }
      this.Mutate(s => s['planBooks'], o => {
        for (const updatedPlanBook of d.Data) {
          if (updatedPlanBook == null) {
            continue;
          }
          const position = o?.FindIndex(i => !!i && !!updatedPlanBook && i.Id === updatedPlanBook.Id);
          if (position >= 0) {
            o[position] = updatedPlanBook;
          } else {
            o.Add(updatedPlanBook);
          }
        }
        return o;
      });
    });

    backend.registerObservable(NPlanningCropEcNSuccess).subscribe((d) => {
      super.Mutate(s => s.cropEcN, () => d.Data);
    });

    backend.registerObservable(NPlanningCropEcSnSuccess).subscribe((d) => {
      super.Mutate(s => s.cropEcSn, () => d.Data);
    });

    backend.registerObservable(NSensorFieldDataLoadSuccess).subscribe(d => {
      super.Mutate(s => s.sensorFieldDataLoading, () => false);
      super.Mutate(s => s.sensorFieldData, () => d.Data);
    });

    super.Listen(s => s.cropEcN).subscribe((n) => {
      const legend = this.getCropEcNeedLegend(n);
      NFertilizationStore.CropEcNeedLegend.next(legend);
    });

    super.Listen(s => s.cropEcSn).subscribe((sn) => {
      const legend = this.getCropEcUptakeLegend(sn);
      NFertilizationStore.CropEcUptakeLegend.next(legend);
    });

    backend.registerObservable(NPlanningRasterStatisticRefresh).subscribe(d => {
      const statisticsToAdd = JSON.parse(d.Data) as IFieldNutrientPlanningStatistic[];
      super.Mutate(s => s.fieldNPlanningStatistic, o => {
        if (o.Find(i => i?.FieldGeomId === statisticsToAdd[0]?.FieldGeomId)) {
          o = o.RemoveAll(i => i.FieldGeomId === statisticsToAdd[0].FieldGeomId);
          o = o.AddRange(statisticsToAdd);
        } else {
          o = o.AddRange(statisticsToAdd);
        }
        return o;
      });
    });

    backend.registerObservable(NPlanningRasterStatisticFail).subscribe(d => {
      const result = JSON.parse(d.Data) as IRasterCreationResult;
      super.Mutate(s => s.fieldNPlanningStatistic, o => {
        if (o.Find(i => i?.FieldGeomId === result?.Id)) {
          const stat = o.Find(i => i?.FieldGeomId === result?.Id)[0];
          stat.ErrorText = result.Message;
          o = o.Replace(i => i.FieldGeomId === result.Id, stat);
        } else {
          const err = {FieldGeomId: result.Id, ErrorText: result.Message} as IFieldNutrientPlanningStatistic;
          o = o.Add(err);
        }
        return o;
      });
    });

    backend.registerObservable(NFertilizationLogfilesRemapComplete).subscribe(() => {
      this.LoadLogfiles();
    });

    backend.registerObservable(NFertilizationNRestrictionReportDownloadSuccess).subscribe(d => {
      super.Mutate(s => s.nRestrictionsReportDownloadSuccess, () => true);
      super.Mutate(s => s.nRestrictionsReportDownloadSuccess, () => undefined);
      download(d.Data[1], d.Data[0], 'application/excel');
    });

    backend.registerObservable(NFertilizationNRestrictionReportDownloadFail).subscribe(() => {
      super.Mutate(s => s.nRestrictionsReportDownloadSuccess, () => false);
      super.Mutate(s => s.nRestrictionsReportDownloadSuccess, () => undefined);
    });

    backend.registerObservable(NSensorRasterValuesLoadSuccess).subscribe(d => {
      let result: number[][] = [];
      if (d.Data) {
        result = JSON.parse(d.Data);
      }
      super.Mutate(s => s.sensorRasterValues, () => result);
    });

    this.LoadCropEcUptake();
    this.LoadCropEcNeed();
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get LogfilesLoading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.logfilesLoading);
  }

  public get PlanBooks$(): SafeBehaviorSubject<INFertilizationPlanBook[]> {
    return this.Listen((s) => s.planBooks);
  }

  public get PlanBooks(): INFertilizationPlanBook[] {
    return this.Listen((s) => s.planBooks).getValue();
  }

  public get Logfiles$(): SafeBehaviorSubject<IDatasetFieldMap[]> {
    return this.Listen((s) => s.logfiles);
  }

  public get Logfiles(): IDatasetFieldMap[] {
    return this.Listen((s) => s.logfiles).getValue();
  }

  public LoadCropEcUptake(): void {
    this.DispatchBackend(new NPlanningCropEcSn([]));
  }

  public LoadCropEcNeed(): void {
    this.DispatchBackend(new NPlanningCropEcN([]));
  }

  public LoadNFertilizationPlanningSummaries(): void {
    if (super.Listen(s => s.planBooksLoading).getValue()) {
      return;
    }
    super.Mutate(s => s.planBooksLoading, () => true);
    super.Mutate(s => s.planBooks, () => []);
    this.DispatchBackend(new NFertilizationBookingsLoad([]));
  }

  public LoadLogfiles(): void {
    if (super.Listen(s => s.logfilesLoading).getValue()) {
      return;
    }
    super.Mutate(s => s.logfilesLoading, () => true);
    super.Mutate(s => s.logfiles, () => []);
    this.DispatchBackend(new NFertilizationLogfilesLoad([]));
  }

  public getSensorFieldData(fieldGeomId: IGuid): ISensorFieldData {
    return super.Listen(s => s.sensorFieldData).getValue()?.Find(_ => _?.FieldGeomId === fieldGeomId) as ISensorFieldData;
  }

  public GetPlanningById(id: string): INFertilizationPlanBook {
    return ObjectFactory.Copy(
      this.Listen(s => s.planBooks).getValue().Find((pB) => pB?.Id === id));
  }

  public GetPlanningBySummaryId(id: string): INFertilizationPlanBook[] {
    return ObjectFactory.Copy(
      this.Listen(s => s.planBooks).getValue().FindAll((pB) => pB.Summary?.Id === id));
  }

  public GetNeeds$(campaignYear: number): Observable<INFertilizationNeedValue[]> {
    return super.Listen(s => s.fertilizationNeedValue).pipe(
      map((needs) =>
        needs.filter((need) =>
          need.CampaignYear === campaignYear)
      )
    );
  }

  public GetNeeds(campaignYear: number): INFertilizationNeedValue[] {
    return super.Listen(s => s.fertilizationNeedValue).getValue().filter(
      (need) => need.CampaignYear === campaignYear);
  }

  public Planning$(): Observable<INFertilizationPlanBook[]> {
    return super.Listen(s => s.planBooks).pipe(
      map((planBooks) =>
        planBooks.filter((planBook) => {
          return !planBook.DeletedBy && !planBook.BookedBy;
        })
      )
    );
  }

  public Bookings$(): Observable<INFertilizationPlanBook[]> {
    return super.Listen(s => s.planBooks).pipe(
      map((planBooks) =>
        planBooks.filter((planBook) => planBook && !planBook.DeletedBy && !!planBook.BookedBy)
      )
    );
  }

  public getFertilizationNeedValue(): void {
    this.DispatchBackend(new NFertilizationNeedValueGet([]));
  }

  public setFertilizationNeedValue(values: INFertilizationNeedValue[]): void {
    this.DispatchBackend(new NFertilizationNeedValueSet([
      {Name: 'values', Type: ApCustomTypes.Data_NFertilization_NFertilizationNeedValue + '[]', Value: values}
    ]));
  }

  public SaveNFertilizationPlanning(planBooks: INFertilizationPlanBook[]): void {
    this.DispatchBackend(new NFertilizationBookingsSave([
      {Name: 'planBooks', Type: ApCustomTypes.Data_TaskManagement_NFertilizationPlanBook + '[]', Value: planBooks}
    ]));
  }

  public UpdateNFertilizationPlanning(planBooks: INFertilizationPlanBook[]): void {
    this.DispatchBackend(new NFertilizationBookingsUpdate([
      {Name: 'planBooks', Type: ApCustomTypes.Data_TaskManagement_NFertilizationPlanBook + '[]', Value: planBooks}
    ]));
  }

  public DeleteNFertilizationSummaries(summaries: INFertilizationSummary[]): void {
    this.DispatchBackend(new NFertilizationSummaryDelete([
      {Name: 'summaries', Type: ApCustomTypes.Data_TaskManagement_NFertilizationSummary + '[]', Value: summaries}
    ]));
  }

  public DeleteNFertilizationPlanBooks(planBooks: INFertilizationPlanBook[]): void {
    this.DispatchBackend(new NFertilizationPlanBooksDelete([
      {Name: 'planBooks', Type: ApCustomTypes.Data_TaskManagement_NFertilizationPlanBook + '[]', Value: planBooks}
    ]));
  }

  public SaveLogfiles(datasetFieldMaps: IDatasetFieldMap[]): void {
    super.Mutate(s => s.logfilesLoading, () => true);
    this.DispatchBackend(new NFertilizationLogfilesSave([
      {Name: 'datasetFieldMaps', Type: ApCustomTypes.Data_Sensor_DatasetFieldMap + '[]', Value: datasetFieldMaps}
    ]));
  }

  public UpdateLogfiles(datasetFieldMaps: IDatasetFieldMap[]): void {
    this.DispatchBackend(new NFertilizationLogfilesUpdate([
      {Name: 'datasetFieldMaps', Type: ApCustomTypes.Data_Sensor_DatasetFieldMap + '[]', Value: datasetFieldMaps}
    ]));
  }

  public GetLogfilesByPlanBookId(id: string): IDatasetFieldMap[] {
    return super.Listen(s => s.logfiles).getValue().filter(l => l.NPlanBook?.Id === id);
  }

  public BookPlanBooks(data: { planBook: INFertilizationPlanBook, logfile: IDatasetFieldMap }[]): void {
    const planBooks = data.map((d) => d.planBook);
    const logfiles = data.map((d) => d.logfile);
    this.DispatchBackend(new NFertilizationBookPlanBook([
      {Name: 'planBooks', Type: ApCustomTypes.Data_TaskManagement_NFertilizationPlanBook + '[]', Value: planBooks},
      {Name: 'logfiles', Type: ApCustomTypes.Data_Sensor_DatasetFieldMap + '[]', Value: logfiles}
    ]));
  }

  public UnBookPlanBooks(data: { planBook: INFertilizationPlanBook, logfile: IDatasetFieldMap }[]): void {
    const planBooks = data.map((d) => d.planBook);
    const logfiles = data.map((d) => d.logfile);
    this.DispatchBackend(new NFertilizationUnBookPlanBook([
      {Name: 'planBooks', Type: ApCustomTypes.Data_TaskManagement_NFertilizationPlanBook + '[]', Value: planBooks},
      {Name: 'logfiles', Type: ApCustomTypes.Data_Sensor_DatasetFieldMap + '[]', Value: logfiles}
    ]));
  }

  public getCropEcUptakeLegend(sn: ICropEcSn[]): { [key: number]: { min: number, max: number, values: number[] }[] } {
    const result: { [key: number]: ({ min: number, max: number, values: number[] })[] } = {};
    for (const cropEcSn of sn) {
      for (const key of cropEcSn.CropKeys) {
        if (result[key] === undefined) {
          result[key] = [];
        }
        result[key].push(({
          min: cropEcSn.EcMin,
          max: cropEcSn.EcMax,
          values: [
            cropEcSn.Sn1, cropEcSn.Sn2, cropEcSn.Sn3,
            cropEcSn.Sn4, cropEcSn.Sn5, cropEcSn.Sn6,
            cropEcSn.Sn7, cropEcSn.Sn8, cropEcSn.Sn9
          ]
        }));
        result[key] = result[key].sort((p, n) => p.min - n.min);
      }
    }
    return result;
  }

  public getPlanningsBySummaryId(summaryId: string | IGuid): INFertilizationPlanBook[] {
    return this.Listen(s => s.planBooks).getValue().filter((pB) => pB.Summary?.Id === summaryId);
  }

  public getCropEcNeedLegend(n: ICropEcN[]): { [key: number]: { min: number, max: number, values: number[] }[] } {
    const result: { [key: number]: ({ min: number, max: number, values: number[] })[] } = {};
    for (const cropEcN of n) {
      for (const key of cropEcN.CropKeys) {
        if (result[key] === undefined) {
          result[key] = [];
        }
        result[key].push(({
          min: cropEcN.EcMin,
          max: cropEcN.EcMax,
          values: [
            cropEcN.N1, cropEcN.N2, cropEcN.N3,
            cropEcN.N4, cropEcN.N5, cropEcN.N6,
            cropEcN.N7, cropEcN.N8, cropEcN.N9
          ]
        }));
        result[key] = result[key].sort((prev, next) => prev.min - next.min);
      }
    }
    return result;
  }

  public CreateNPlanningRasterJob(planId: IGuid, ndPlanId: IGuid, fieldGeomId: IGuid, fertilizerId: number, applicationMode: number, cropTypeMapId: number, minimum: number, maximum: number, threshold: number, slope: number, appleRate: number, constant: number, deadBioMas: number): void {
    super.Mutate(s => s.fieldNPlanningStatistic, o => {
      return o.RemoveAll(i => i.FieldGeomId === fieldGeomId);
    });
    this.DispatchBackend(new NPlanningRasterCreateJob([
      {Name: 'planId', Type: NetTypes.GUID, Value: planId},
      {Name: 'ndPlanId', Type: NetTypes.GUID, Value: ndPlanId},
      {Name: 'fieldGeomId', Type: NetTypes.GUID, Value: fieldGeomId},
      {Name: 'fertilizerId', Type: NetTypes.INT, Value: fertilizerId},
      {Name: 'applicationMode', Type: NetTypes.INT, Value: applicationMode},
      {Name: 'cropTypeMapId', Type: NetTypes.INT, Value: cropTypeMapId},
      {Name: 'minimum', Type: NetTypes.FLOAT, Value: minimum},
      {Name: 'maximum', Type: NetTypes.FLOAT, Value: maximum},
      {Name: 'threshold', Type: NetTypes.FLOAT, Value: threshold},
      {Name: 'slope', Type: NetTypes.FLOAT, Value: slope},
      {Name: 'appleRate', Type: NetTypes.FLOAT, Value: appleRate},
      {Name: 'constant', Type: NetTypes.FLOAT, Value: constant},
      {Name: 'deadBioMas', Type: NetTypes.FLOAT, Value: deadBioMas},
    ]));
  }

  public clearNPlanningStatistic(): void {
    super.Mutate(s => s.fieldNPlanningStatistic, () => []);
  }

  public loadSensorFieldData(): void {
    super.Mutate(s => s.sensorFieldDataLoading, () => true);
    this.DispatchBackend(new NSensorFieldDataLoad([]));
  }

  public remapLogfile(datasetIds: any | any[]): void {
    if (!Array.isArray(datasetIds)) {
      datasetIds = [datasetIds];
    }
    this.DispatchBackend(new NFertilizationLogfilesRemap([
      {
        Name: 'datasetIds',
        Type: NetTypes.GUID + '[]',
        Value: datasetIds.filter((v: any, i: any, a: any) => a.indexOf(v) === i)
      }
    ]));
  }

  public downloadNRestrictionReport(headline: string, bookName: string, reportData: IReportData): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new NFertilizationNRestrictionReportDownload([
      {Name: 'headline', Type: NetTypes.STRING, Value: headline},
      {Name: 'bookName', Type: NetTypes.STRING, Value: bookName},
      {
        Name: 'reportData',
        Type: ApCustomTypes.Agriport_Excel_Factory_InputData_NRestriction_ReportData,
        Value: reportData
      }
    ]));
  }

  public loadSensorRasterValues(planBookIds: IGuid[]): void {
    super.Mutate(s => s.sensorRasterValues, () => []);
    this.DispatchBackend(new NSensorRasterValuesLoad([
      {Name: 'planBookIds', Type: NetTypes.GUID + '[]', Value: planBookIds}
    ]));
  }
}
